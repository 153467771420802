/* eslint-disable no-debugger */
import FileSaver from 'file-saver';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as XLSX from 'xlsx';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import {
  Button,
  Card,
  DropdownIcons,
  FilterDateSelect,
  Input,
  Modal,
  SelectComponent,
} from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { ToastNotify } from '../../../components/Toast/toast';
import maskHelper from '../../../helpers/mask.helper';
import { getItem } from '../../../helpers/storage.helper';
import {
  decodeToken,
  getTokenAccountInformation,
} from '../../../helpers/token.helper';
import { CopyLink, dictionaryError } from '../../../helpers/utils.helper';
import { alertGalaxAccount } from '../../../hooks/globalAtom';
import { tokenModel } from '../../../types';
import { useFetchPlansAccount } from '../../chartAccounts/hooks';
import MainPagePagination from '../../main/components/mainPage/mainPageWithPagination';
import { useFetchProvider } from '../../providers/hooks';
import { ApiExpenses } from '../api';
import DownloadFinancialsModal from '../components/downloadExpenses';
import SearchFinancial from '../components/filterFinancials';
import ModalDeleteUnpaid from '../components/modal/modalDeleteUnpaid';
import ReceiptPrint from '../components/receiptPrint';
import RegisterFinancial from '../components/register';
import RevertPayedModal from '../components/revertPayed';
import { useFetchFinancials } from '../hooks';
import { FinancialProps, filterFormProps } from '../types';
import { statusColorFinancial } from '../utils';
import RequestServiceInvoiceModal from '../components/requestServiceInvoiceModal';

const InvoiceStatusColors = {
  Issued: 'rgb(51 167 10 / var(--tw-bg-opacity))',
  Created: 'rgb(51 167 10 / var(--tw-bg-opacity))',
  None: 'black',
  Cancelled: 'rgb(218 60 60 / var(--tw-bg-opacity))',
};
const Financials: React.FC = () => {
  const [invoiceCreditBalance, setInvoiceCreditBalance] = useState(0);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalRevertPayed, setShowModalRevertPayed] = useState(false);
  const [modalProviders, setModalProviders] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const [modalDownload, setModalDownload] = useState(false);
  const [input, setInput] = useState('');
  const [financialData, setFinancialData] = useState<FinancialProps | null>(
    null
  );
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isLoadingDelete, setisLoadingDelete] = useState(false);
  const [key, setKey] = useState('key');
  const [keyProviders, setKeyProviders] = useState('key');
  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
  });

  const [showModalReceipt, setShowModalReceipt] = useState(false);
  const [dataRow, setDataRow] = useState({} as any);
  const [way, setWay] = useState(1);

  const [startData, setStartData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showRequestServiceInvoiceModal, setShowRequestServiceInvoiceModal] =
    useState(false);
  const received = [
    { value: null, label: 'Todos' },
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ];

  const dados = getItem('bearer_token');
  const dataToken: any = decodeToken(dados!.toString());

  useEffect(() => {
    ApiExpenses.getInvoiceCreditBalance().then((result) =>
      setInvoiceCreditBalance(result?.invoice_credit_balance ?? 0)
    );
  }, []);
  const [filterModal, setFilterModal] = useState<filterFormProps>({
    client_id: [],
    contract_id: [],
    name: '',
    type: 'Todos',
    form_of_payment: [],
    status: [],
    received: received[0].value,
    order: 'due_date_asc',
    pla_account_id: [],
    provider_id: [],
    status_contract: [],
    skip: startData,
    take: 10,
  });

  const openReceiptInNewWindow = (row?: any, decode?: any) => {
    const receiptHTML = ReactDOMServer.renderToStaticMarkup(
      way === 1 ? (
        <ReceiptPrint row={[row, decode]} />
      ) : (
        <div>
          <ReceiptPrint row={[row, decode]} />
          <ReceiptPrint row={[row, decode]} />
        </div>
      )
    );

    const printWindow = document.createElement('iframe');
    printWindow.style.position = 'absolute';
    printWindow.style.width = '0px';
    printWindow.style.height = '0px';
    printWindow.style.border = 'none';
    document.body.appendChild(printWindow);

    printWindow.contentWindow?.document.open();
    printWindow.contentWindow?.document.write(`
      <!DOCTYPE html>
      <html lang="pt-BR">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Recibo</title>
        <script src="https://cdn.tailwindcss.com?plugins=forms,typography"></script>
        <style>
          @media print {
            .no-print { display: none; }
          }
        </style>
      </head>
      <body>
        ${receiptHTML}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    printWindow.contentWindow?.document.close();
    setShowModalReceipt(false);
    setWay(1);
  };

  const handlePrintReceipt = (row: any, decodeParam: any) => {
    openReceiptInNewWindow(row, decodeParam);
  };

  const [filterDate, setFilterDate] = useState(`this_month`);

  const [type, setType] = useState<'R' | 'D'>('D');

  const accountProps: tokenModel = getTokenAccountInformation();
  const { FinancialsList, isLoadingList } = useFetchFinancials(
    key,
    { ...filterModal, name: input },
    filterDate
  );

  const { PlanList } = useFetchPlansAccount('');
  const { ProviderList } = useFetchProvider(
    keyProviders,
    accountProps.companie_id
  );

  const providersOptions = ProviderList.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const plansExpenseOptions = PlanList.filter(
    (item) =>
      item.num_plan.split('.').length > 1 &&
      item.type === (financialData?.type ?? type)
  ).map((elem) => ({
    label: `${elem.num_plan} - ${elem.title}`,
    value: elem.id ?? 0,
    isdisabled: elem.num_plan.split('.').length === 2,
  }));

  const { notify } = ToastNotify();

  const options = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
  ];

  const columns = [
    {
      name: 'Cliente ou Fornecedor',
      key: 'client_name',
      minWidth: '180px',
      selector: (row: FinancialProps) =>
        row.type === 'R'
          ? row.client
            ? row.client.name
            : row.contract.client_name
          : row.provider.name ?? '',
    },
    {
      name: 'Tipo',
      key: 'type',
      minWidth: '95px',
      selector: (row: FinancialProps) =>
        row.type === 'R' ? (
          <button
            type="button"
            onClick={() => {
              setRegisterPage({ title: 'Dados Pessoais', page: 0 });
              setFinancialData(row);
              setModalProviders(true);
            }}
            className="w-16 bg-green text-white font-semibold flex items-center justify-center py-1 rounded-sm"
          >
            Receita
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              setRegisterPage({ title: 'Dados Pessoais', page: 0 });
              setFinancialData(row);
              setModalProviders(true);
            }}
            className="w-16 bg-red text-white font-semibold flex items-center justify-center py-1 rounded-sm"
          >
            Despesa
          </button>
        ),
    },
    {
      name: 'Contas',
      key: 'plan_account.title',
      selector: (row: FinancialProps) => row.plan_accounts.title,
    },
    {
      name: 'ID Transação',
      key: 'galax_pay_id',
      minWidth: '115px',
      selector: (row: FinancialProps) => row.galax_pay_id,
    },
    {
      name: 'Forma/Método',
      key: 'payment_form',
      minWidth: '130px',
      selector: (row: FinancialProps) => {
        const form = () => {
          switch (row.form_of_payment) {
            case 'creditcard':
              return 'Cartão de crédito';
            case 'boleto':
              return 'Boleto';
            case 'pix':
              return 'Pix';
            case 'dinheiro':
              return 'Dinheiro';
            case 'ted':
              return 'TED';
            default:
              return '';
          }
        };
        let method;
        switch (row.method) {
          case 'installments':
            method = 'Parcelado';
            break;
          case 'recurrence':
            method = 'Recorrência';
            break;
          default:
            method = 'À vista';
        }
        return (
          <div className="flex flex-col gap-1">
            <p>{form()}</p>
            <p>{method}</p>
          </div>
        );
      },
    },

    {
      name: 'Parcela',
      key: 'installments',
      selector: (row: FinancialProps) => row.installment,
      hide: 1407,
    },
    {
      name: 'Valor',
      key: 'value',
      width: '100px',
      selector: (row: FinancialProps) => maskHelper.formatMoeda(row.value),
    },
    {
      name: 'Vencimento/Pagamento',
      minWidth: '190px',
      selector: (row: FinancialProps) => {
        const due =
          row.due_date !== undefined
            ? maskHelper.formatDateDMY(row.due_date.slice(0, 10))
            : '';
        const receiveDay =
          row.received_date !== undefined && row.received_date !== null
            ? maskHelper.formatDateDMY(row.received_date.slice(0, 10))
            : '';
        return (
          <div className="flex gap-1 justify-center">
            <p className="text-red font-medium">{due}</p>-
            <p className="text-green font-medium">{receiveDay}</p>
          </div>
        );
      },
    },
    {
      name: 'Status',
      key: 'status',
      minWidth: '10px',
      selector: (row: FinancialProps) => row.status,
      cell: (row: FinancialProps) => (
        <p className={statusColorFinancial[row.status]}>{row.status}</p>
      ),
    },
    {
      name: 'Valor Recebido',
      key: 'received_value',
      width: '130px',
      selector: (row: FinancialProps) =>
        maskHelper.formatMoeda(row.received_value),
    },
    {
      name: 'Ações',
      width: '90px',
      cell: (row: FinancialProps) => {
        const dropdownItems = [
          ...(row.type === 'R' && row.payment_link
            ? [
                {
                  label: 'Copiar Link',
                  value: (
                    <IconDictionary
                      name="Link"
                      size={20}
                      className="cursor-pointer text-gray-600 w-fit hover:scale-110"
                    />
                  ),
                  handleClick: () => {
                    CopyLink(row.payment_link);
                  },
                },
              ]
            : []),
          ...(row.status === 'Aberta' || row.status === 'Vencida'
            ? [
                {
                  label: 'Baixar',
                  value: (
                    <IconDictionary
                      key="download"
                      name="Download"
                      size={20}
                      className="cursor-pointer w-fit hover:scale-110"
                    />
                  ),
                  handleClick: () => {
                    setTotalValue(Number(row.value));
                    setFinancialData(row);
                    setModalDownload(true);
                  },
                },
                {
                  label: 'Excluir',
                  value: (
                    <IconDictionary
                      key="excluir"
                      name="Excluir"
                      size={20}
                      className="cursor-pointer w-fit hover:scale-110"
                      color="#DA3C3C"
                    />
                  ),
                  handleClick: () => {
                    setFinancialData(row);
                    setShowModalDelete(true);
                  },
                },
              ]
            : []),
          ...(row.status === 'Paga' &&
          (row.type === 'D' ||
            (row.type === 'R' &&
              (row.form_of_payment === 'dinheiro' ||
                row.form_of_payment === 'ted')))
            ? [
                {
                  label: 'Reabrir',
                  value: (
                    <IconDictionary
                      key="reabrir"
                      name="Reabrir"
                      title="Remover baixa"
                      size={20}
                      className="cursor-pointer w-fit text-indigo-500 hover:scale-110"
                    />
                  ),
                  handleClick: () => {
                    setFinancialData(row);
                    setShowModalRevertPayed(true);
                  },
                },
              ]
            : []),
          {
            label: 'Recibo',
            value: (
              <IconDictionary
                key="recibo"
                name="Recibo"
                title="Recibo"
                size={20}
                className="cursor-pointer w-fit hover:scale-110"
              />
            ),
            handleClick: () => {
              setDataRow(row);
              setShowModalReceipt(true);
            },
          },
          ...(row.type === 'R' && invoiceCreditBalance >= 1
            ? [
                {
                  label: 'Emitir nota fiscal de serviço',
                  value: (
                    <FaFileInvoiceDollar
                      key="service-invoice"
                      name="Emitir nota fiscal de serviço"
                      title="Emitir nota fiscal de serviço"
                      size={20}
                      className="cursor-pointer w-fit hover:scale-110"
                      color={
                        InvoiceStatusColors[
                          row.nfe_status as keyof typeof InvoiceStatusColors
                        ] || 'black'
                      }
                    />
                  ),
                  handleClick: () => {
                    setDataRow(row);
                    setShowRequestServiceInvoiceModal(true);
                  },
                },
              ]
            : []),
        ];

        return (
          <div className="ml-1.5">
            <DropdownIcons
              components={dropdownItems}
              modalSide="bottom"
              modalAlign="end"
            />
          </div>
        );
      },
    },
  ];

  const formatPaymentMethod = {
    creditcard: 'Cartão de crédito',
    boleto: 'Boleto',
    pix: 'Pix',
    ted: 'TED',
    dinheiro: 'Dinheiro',
  };

  const getNameClientProvider = (fin: FinancialProps) => {
    if (fin.client) {
      return fin.client.name;
    }
    if (fin.provider) {
      return fin.provider.name;
    }

    return fin.contract.client_name;
  };

  const generateCsv = async () => {
    const payload = {
      ...filterModal,
      contract_id: filterModal.contract_id.map((item) => item.value),
      client_id: filterModal.client_id.map((item) => item.value),
      take: 100000000,
    };

    if (payload.name === '' || (payload.name && payload.name.length < 3)) {
      delete payload.name;
    }

    const res = await ApiExpenses.getFinancials(payload, filterDate);
    if (typeof res !== 'string' && res.data) {
      const csvData = [
        [
          'Cliente ou Fornecedor',
          'Tipo',
          'Contas de Lançamento',
          'Vencimento',
          'Forma de pagamento',
          'Método',
          'Parcela',
          'Valor',
          'Status',
          'Data de Pagamento',
          'Valor recebido',
          'Descrição Status',
          'Observação',
          'Contrato',
          'Promotor de Vendas do Contrato',
        ],
        ...res.data.map((item: FinancialProps) => [
          getNameClientProvider(item),
          item.type === 'R' ? 'Receita' : 'Despesa',
          item.plan_accounts.title,
          maskHelper.formatDateDMY(item.due_date.slice(0, 10)),
          formatPaymentMethod[item.form_of_payment],
          item.method === 'installments' ? 'Parcelado' : 'À vista',
          item.installment,
          Number(item.value),
          item.status,
          maskHelper.formatDateDMY(item.received_date?.slice(0, 10) ?? ''),
          Number(item.received_value ?? ''),
          item.status_description,
          item.obs,
          item.contract ? item.contract?.client_name : '',
          item.companie_user?.name,
        ]),
      ];
      return csvData;
    }
    return [[]];
  };

  const restartPagination = () => {
    setStartData(0);
    setCurrentPage(1);
  };

  useEffect(() => {
    setFilterModal({ ...filterModal, skip: startData });
  }, [startData]);

  const searchFinancials = async (value: string) => {
    setCurrentPage(1);
    setFilterModal({ ...filterModal, skip: 0, take: 10, name: value });
    setInput(value);
  };

  const handleUpdate = async (e: FinancialProps) => {
    setRegisterPage({ title: 'Dados Pessoais', page: 0 });
    setFinancialData(e);
    setType(e.type);
    setModalProviders(true);
  };

  const exportToXLS = async () => {
    if (generateCsv) {
      setIsLoadingExport(true);
      const exportData = await generateCsv();
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const csvData = [];
      const keys = exportData[0];
      const values = exportData.slice(1, exportData.length);
      let item: any = {};

      for (let j = 0; j < values.length; j++) {
        for (let i = 0; i < keys.length; i++) {
          item[keys[i]] = values[j][i];
        }
        csvData.push(item);
        item = {};
      }

      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `contas${fileExtension}`);
      setIsLoadingExport(false);
    }
  };

  const handleDelete = async (id: number, excludeAll: boolean) => {
    setisLoadingDelete(true);
    const res = await ApiExpenses.deleteExpense(id, excludeAll);
    if (res === true) {
      notify({
        message: excludeAll
          ? 'Lançamentos excluidos com sucesso!'
          : 'Lançamento excluido com sucesso!',
        type: 'Success',
      });
      setShowModalDelete(false);
      setKey(String(Math.random()));
      setisLoadingDelete(false);
      return;
    }

    notify({ message: dictionaryError(String(res)), type: 'Error' });
    setisLoadingDelete(false);
  };

  return (
    <>
      <Modal
        title="Quantidade de vias"
        setIsOpen={setShowModalReceipt}
        isOpen={showModalReceipt}
        minHeight="medium"
      >
        <SelectComponent
          options={options}
          onChange={(selectedOption: any) => setWay(selectedOption.value)}
          defaultValue={options[0]}
        />

        <div className="flex justify-center mt-6">
          <Button
            className="flex items-center justify-center p-5"
            onClick={() => handlePrintReceipt(dataRow, dataToken)}
          >
            Seguir para impressão
          </Button>
        </div>
      </Modal>
      <MainPagePagination
        currentPageTable={currentPage}
        setCurrentPageTable={setCurrentPage}
        startDate={setStartData}
        totalRegisters={FinancialsList.total}
        titleCard="Despesas"
        isLoading={isLoadingList}
        column={columns}
        title="Contas a pagar e receber"
        filename="contas"
        filterTable={() => FinancialsList.data}
        cardsDirection="row"
        setModalCreateUpdate={setModalProviders}
        customHead={
          <div className="w-full gap-2 grid grid-cols-3 xl:grid-cols-6 ">
            <Card className="flex-col col-span-3 w-full pt-5 px-4 pb-3.5 gap-4">
              <strong>Pesquisar</strong>
              <div className="flex flex-col lg:flex-row gap-2">
                <div className="flex w-full flex-col sm:flex-row gap-2">
                  <Input
                    value={input}
                    onChange={(e) => searchFinancials(e.target.value)}
                    icon={
                      <IconDictionary
                        name="Lupa"
                        size={18}
                        color="#8C8C8C"
                        className="mr-4"
                      />
                    }
                    placeholder="Pesquisar por nome do cliente, contas e status"
                    className="w-full"
                  />
                </div>
                <div className="flex flex-col sm:flex-row gap-1 sm:gap-2 w-full mt-2 md:mt-0 lg:w-fit">
                  <FilterDateSelect
                    filterDate={filterDate}
                    restartPagination={restartPagination}
                    setFilterDate={setFilterDate}
                  />
                  <button
                    type="button"
                    className="xl:hidden w-12 h-11 rounded-lg md:place-self-start items-center justify-center cursor-pointer text-primary border-primary border-solid border"
                    onClick={() => setModalFilter(true)}
                  >
                    <IconDictionary name="Filtro" size={16} />
                  </button>
                </div>
                <button
                  type="button"
                  className="hidden xl:flex min-w-[44px] h-11 rounded-lg justify-center items-center cursor-pointer text-primary border-primary border-solid border"
                  onClick={() => setModalFilter(true)}
                >
                  <IconDictionary name="Filtro" size={16} />
                </button>
              </div>
            </Card>
            <Card
              className="flex-col  xl:w-full xl:w-1/3'
              justify-center items-center gap-4 pb-3.5 pt-5"
            >
              <strong>Cadastrar Despesa</strong>
              <Button
                className="w-40 h-11"
                onClick={() => {
                  setFinancialData(null);
                  setType('D');
                  setModalProviders(true);
                  setRegisterPage({ title: 'Dados Pessoais', page: 0 });
                }}
              >
                Cadastrar
              </Button>
            </Card>
            <Card
              className="flex-col xl:w-full xl:w-1/3'
              justify-center items-center gap-4 pb-3.5 pt-5"
            >
              <strong>Cadastrar Receita</strong>
              <Button
                className="w-40 h-11"
                onClick={() => {
                  setFinancialData(null);
                  setType('R');
                  setModalProviders(true);
                  setRegisterPage({
                    title: 'Dados Pessoais',
                    page: 0,
                  });
                }}
              >
                Cadastrar
              </Button>
            </Card>
            <Card className="flex-col w-full  justify-center items-center gap-4 pb-3.5 pt-5">
              <strong className="text-md">Exportar da tabela</strong>
              <Button
                className="max-w-[160px] h-11 relative flex items-center justify-center disabled:opacity-80"
                variant="primary"
                onClick={exportToXLS}
                disabled={isLoadingExport}
                isLoading={isLoadingExport}
                actionType="button-loading"
              >
                Download
              </Button>
            </Card>
          </div>
        }
        setRegisterPage={() => {
          setFinancialData(null);
          setRegisterPage({ title: 'Dados Pessoais', page: 0 });
        }}
        onRowClick={handleUpdate}
        objectReport={generateCsv}
      />
      <RegisterFinancial
        type={type}
        setProviderList={setKeyProviders}
        notEditable={
          financialData !== null
            ? financialData.type === 'R' || financialData.received_date !== null
            : false
        }
        companyUserId={accountProps.id}
        expense={financialData}
        reloadTable={setKey}
        registerPage={registerPage}
        setRegisterPage={setRegisterPage}
        setShow={setModalProviders}
        show={modalProviders}
        providerOptions={providersOptions}
        accountOptions={plansExpenseOptions}
      />
      <SearchFinancial
        restartPagination={restartPagination}
        filters={filterModal}
        setFilter={setFilterModal}
        providerOptions={providersOptions}
        setShow={setModalFilter}
        show={modalFilter}
        planAccounts={PlanList}
      />
      {financialData && (
        <>
          <RevertPayedModal
            reload={setKey}
            financialId={financialData.id}
            setShow={setShowModalRevertPayed}
            show={showModalRevertPayed}
          />
          <DownloadFinancialsModal
            isIntegrated={financialData.galax_pay_id !== null}
            isRevenue={financialData.type === 'R'}
            reload={setKey}
            date={financialData.due_date}
            value={totalValue}
            financialId={financialData.id}
            setShow={setModalDownload}
            show={modalDownload}
          />
          <ModalDeleteUnpaid
            confirm={(e) => handleDelete(financialData.id, e)}
            isLoading={isLoadingDelete}
            setShow={setShowModalDelete}
            show={showModalDelete}
            type={financialData.type}
          />
        </>
      )}
      {dataRow?.id && (
        <RequestServiceInvoiceModal
          account={dataRow}
          isOpen={showRequestServiceInvoiceModal}
          setIsOpen={(isOpen) => {
            setShowRequestServiceInvoiceModal(isOpen);
            setDataRow({});
            setKey(String(Math.random()));
          }}
        />
      )}
    </>
  );
};

export default Financials;
