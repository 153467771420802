import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';
import IconDictionary from '../Icons/icons';

interface DropdownProps {
  components: {
    label?: string;
    value: React.ReactNode;
    handleClick?: () => void;
    disabled?: boolean | undefined;
  }[];
  modalSide?: 'left' | 'top' | 'right' | 'bottom' | undefined;
  modalAlign?: 'center' | 'start' | 'end' | undefined;
  linesSeparator?: number[] | undefined;
}

const DropdownIcons: React.FC<DropdownProps> = ({
  components,
  modalAlign,
  modalSide,
  linesSeparator,
}) => (
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild>
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-sm bg-zinc-200 shadow-l p-2"
      >
        <IconDictionary name="Três Pontos Vertical" size={13} />
      </button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        className="w-auto rounded-md bg-white shadow-lg px-1 py-3"
        side={modalSide}
        align={modalAlign}
      >
        {components.map((item, index) => (
          <>
            <DropdownMenu.Item
              className={`${
                item.disabled
                  ? 'group relative flex items-center h-[36px] pl-[15px] pr-[15px] my-[2px] text-sm leading-none text-[#dadada] rounded-md cursor-default focus:bg-slate-50'
                  : 'group relative flex items-center h-[36px] pl-[15px] pr-[15px] my-[2px] text-sm leading-none text-gray-800 rounded-md cursor-pointer focus:bg-slate-50'
              }`}
              onClick={item.handleClick}
              key={item.label}
              disabled={item.disabled}
            >
              <div className="flex items-center space-x-2">
                <div className="text-gray-80">{item.value}</div>
                <div className="text-sm text-gray-500">{item.label}</div>
              </div>
            </DropdownMenu.Item>
            {linesSeparator?.includes(index + 1) && (
              <div className="h-[1px] bg-gray-300 my-[2px] w-[75%] ml-[15px]" />
            )}
          </>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
);

export default DropdownIcons;
