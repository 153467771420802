/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Spinner } from '../../../../../components';
import { generateHexColor } from '../../../../../helpers/utils.helper';
import { ApiDashboard } from '../../api';
import { segmentProps } from '../../types';

interface props {
  companyId: number;
  period: string;
  funnelId: any;
  seller: any;
}
const SegmentGraph: React.FC<props> = ({
  companyId,
  period,
  funnelId,
  seller,
}) => {
  const [graph, setGraph] = useState<segmentProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchGraph = async () => {
      setIsLoading(true);
      const segment = await ApiDashboard.getSegments(
        companyId,
        funnelId,
        period,
        seller
      );
      if (typeof segment !== 'string') {
        setGraph(segment);
      }
      setIsLoading(false);
    };
    fetchGraph();
  }, [period, funnelId, seller]);
  return !isLoading ? (
    <>
      <p className="font-medium text-xs uppercase">Top 5 segmentos</p>
      <div className="w-full h-full flex items-center">
        <Plot
          className="w-full"
          data={[
            {
              x: graph.slice(0, 5).map((item) => item.title),
              y: graph.slice(0, 5).map((item) => item._count),
              text: graph.map((item) => item._count),
              marker: {
                color: generateHexColor(graph.length),
              },
              type: 'bar',
            },
          ]}
          config={{ displayModeBar: false, responsive: true }}
          layout={{
            height: 200,
            autosize: true,
            margin: { l: 40, r: 20, b: 50, t: 28 },
            font: {
              size: 12,
            },
            xaxis: {
              showgrid: false,
            },
            yaxis: {
              showgrid: false,
              range: [0, 4],
              autorange:
                graph.reduce((accu, curr) => accu + Number(curr._count), 0) !==
                0,
              tickmode:
                graph.reduce(
                  (a, b) => Math.max(a, Number(b._count)),
                  -Infinity
                ) <= 2
                  ? 'linear'
                  : 'auto',
              tick0: 0,
              tickformat: ' ,d',
            },
          }}
        />
      </div>
    </>
  ) : (
    <div className="flex items-center justify-center h-52 min-h-full">
      <Spinner />
    </div>
  );
};

export default SegmentGraph;
