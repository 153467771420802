import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFetchWhatsappCampaign } from '../hooks';
import { ApiWhatsappCampaign } from '../api';
import {
  Button,
  Card,
  DeleteModal,
  DropdownIcons,
  Input,
  InputDate,
  SelectComponent,
  isLoadingApplication,
} from '../../../components';
import { ToastNotify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import IconDictionary from '../../../components/Icons/icons';
import { ModalHasNoPlan } from '../components';
import { whatsappCampaignProps } from '../types';
import maskHelper from '../../../helpers/mask.helper';
import ModalHasNoInstances from '../components/modal/modalNoInstances';
import { useFetchWhatsappInstances } from '../../whatsapp/hook';
import MainPagePagination from '../../main/components/mainPage/mainPageWithPagination';
import ModalCampaignOutgoing from '../components/modal/modalCampaignOutgoing';
import ModalStartCampaign from '../components/modal/modalConfirmStartCampaign';
import { CampaignUtils } from '../utils';
import ModalRestartCampaign from '../components/modal/modalConfirmStartCampaign copy';
import CardImportPerson from '../../contacts/components/card/import';
import { formatInstance } from '../../whatsapp/utils';

const WhatsappCampaign: React.FC = () => {
  const { setState } = useContext(isLoadingApplication);
  const [key, setKey] = useState('');
  const [reload, setReload] = useState('');
  const [skip, setSkip] = useState(0);
  const [currentPageTable, setCurrentPageTable] = useState(1);
  const [date, setDate] = useState<string>('');
  const [statusCampaign, setStatusCampaign] = useState<any[]>([
    { label: 'Não iniciada', value: 'notstarted', colorCard: 'gray-400' },
    { label: 'Em disparo', value: 'shooting', colorCard: 'green' },
    { label: 'Pausada', value: 'paused', colorCard: 'rgb(250 204 21)' },
  ]);
  const take = 10;

  const navigate = useNavigate();
  const { WhatsappCampaignList, isLoadingWhatsapp } = useFetchWhatsappCampaign(
    reload,
    skip,
    take,
    key,
    date,
    statusCampaign
  );

  const [modalHasNoPlan, setModalHasNoPlan] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [modalCampaignOutgoing, setModalCampaignOutgoing] = useState(false);
  const [modalCampaignStart, setModalCampaignStart] = useState(false);
  const [modalCampaignRestart, setModalCampaignRestart] = useState(false);
  const [planId, setPlanId] = useState(-1);
  const [reloadInstance, setReloadInstance] = useState('');

  const { InstancesWhatsApp } = useFetchWhatsappInstances(
    Number(planId),
    reloadInstance
  );

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalHasNoInstances, setModalHasNoInstances] =
    useState<boolean>(false);
  const [whatsappData, setWhatsappData] =
    useState<whatsappCampaignProps | null>(null);
  const [input, setInput] = useState('');

  const optionStatusCampign = [
    { label: 'Não iniciada', value: 'notstarted', colorCard: 'gray-400' },
    { label: 'Em disparo', value: 'shooting', colorCard: 'green' },
    { label: 'Terminada', value: 'finish', colorCard: 'gray-600' },
    { label: 'Pausada', value: 'paused', colorCard: 'rgb(250 204 21)' },
    { label: 'Cancelada', value: 'canceled', colorCard: 'red' },
  ];

  const { notify } = ToastNotify();

  const onUpdateStatus = async (
    status: string,
    campaign: whatsappCampaignProps | null
  ) => {
    if (!campaign) return;
    if (
      CampaignUtils.verifyStatusInstance(
        InstancesWhatsApp.find(
          (item) => item.id === campaign.companie_whatsapp_instance_id
        )
      )
    ) {
      notify({
        message: 'Instância em disparo por outra campanha',
        type: 'Warning',
      });
      return;
    }
    setIsLoadingStatus(true);
    const res = await ApiWhatsappCampaign.updateWhatsappCampaignStatus(
      { status },
      campaign.id
    );

    if (res.id) {
      notify({ message: 'Status atualizado com sucesso!', type: 'Success' });
      setReload(`${Math.random()} status`);
      setReloadInstance(String(Math.random()));
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
    }
    setIsLoadingStatus(false);
  };

  const onStartExecution = async (campaign: whatsappCampaignProps) => {
    setWhatsappData(campaign);
    setModalCampaignStart(true);
  };

  const onRestart = async (campaign: whatsappCampaignProps) => {
    setWhatsappData(campaign);
    setModalCampaignRestart(true);
  };

  const onDelete = async (campaign: whatsappCampaignProps) => {
    setWhatsappData(campaign);
    setShowModalDelete(true);
  };

  const handleOpenCreateUpdate = (id?: number) => {
    if (!id) {
      const availableInstances = InstancesWhatsApp.filter(
        (item) => item.status === 'Conectada' && item.is_bulk_shipping
      );

      if (availableInstances.length > 0) {
        navigate(`edit/${planId}/create`);
      } else {
        setModalHasNoInstances(true);
      }
    } else {
      navigate(`edit/${planId}/${id}`);
    }
  };

  const columns = [
    {
      name: 'Campanha',
      key: 'title',
      selector: (row: whatsappCampaignProps) => row.title,
    },
    {
      name: 'Envio',
      key: 'send_date',
      minWidth: '50px',
      selector: (row: whatsappCampaignProps) =>
        maskHelper.formatDateDMY(row.send_date ?? ''),
    },
    {
      name: 'Enviadas',
      key: 'send_success',
      selector: (row: whatsappCampaignProps) => (
        <p className="text-green">{row.send_success}</p>
      ),
      minWidth: '30px',
    },
    {
      name: 'Erros',
      key: 'send_error',
      selector: (row: whatsappCampaignProps) => (
        <p className="text-red">{row.send_error}</p>
      ),
      minWidth: '30px',
    },
    {
      name: 'Status',
      key: 'status',
      minWidth: '50px',
      selector: (row: whatsappCampaignProps) => (
        <p className="text-[10px]">
          {CampaignUtils.getTextByStatus(row.status)}
        </p>
      ),
    },
    {
      name: 'Instância',
      key: 'companie_whatsapp_instances.json_connection_update.data.instance',
      selector: (row: whatsappCampaignProps) =>
        row.companie_whatsapp_instances.json_connection_update?.data
          ? row.companie_whatsapp_instances.json_connection_update?.data
              .instance
          : row.companie_whatsapp_instances.json_connection_update?.instance
          ? row.companie_whatsapp_instances.json_connection_update?.instance
              .instanceName
          : '',
      minWidth: '140px',
    },
    {
      name: 'Conectado em',
      minWidth: '130px',
      key: 'companie_whatsapp_instances.json_connection_update.data.instance',
      selector: (row: whatsappCampaignProps) => {
        const number =
          row.companie_whatsapp_instances.json_connection_update?.sender?.split(
            '@'
          )[0] ?? '';
        return number.length === 12
          ? maskHelper.phone(
              `${number.slice(2, 11).substring(0, 2)}9${number.slice(4, 12)}`
            )
          : maskHelper.phone(number.slice(2));
      },
    },
    {
      name: 'Status Instância',
      key: 'companie_whatsapp_instances.whatsapp_number',
      minWidth: '140px',
      selector: (row: whatsappCampaignProps) => (
        <p className="text-[10px]">
          {CampaignUtils.getStatusInstance(
            row.companie_whatsapp_instances.status
          )}
        </p>
      ),
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '90px',
      selector: (row: whatsappCampaignProps) => {
        const dropdownItems = [];

        if (row.status === 'Não iniciada') {
          dropdownItems.push(
            {
              label: 'Iniciar execução',
              value: (
                <button type="button" onClick={() => onStartExecution(row)}>
                  <IconDictionary
                    size={20}
                    name="Iniciar"
                    className="text-green"
                  />
                </button>
              ),
              handleClick: () => {
                onStartExecution(row);
              },
            },
            {
              label: 'Excluir',
              value: (
                <button type="button" onClick={() => onDelete(row)}>
                  <IconDictionary
                    size={20}
                    name="Excluir"
                    color="#DA3C3C"
                    className="mr-0"
                  />
                </button>
              ),
              handleClick: () => {
                onDelete(row);
              },
            }
          );
        }

        if (row.status === 'Pausada') {
          dropdownItems.push({
            label: 'Reiniciar',
            value: (
              <button type="button" onClick={() => onRestart(row)}>
                <IconDictionary
                  size={20}
                  name="Reabrir"
                  className="text-indigo-400"
                />
              </button>
            ),
            handleClick: () => {
              onRestart(row);
            },
          });
        }

        return (
          <div className="ml-1.5">
            <DropdownIcons
              components={dropdownItems}
              modalSide="bottom"
              modalAlign="end"
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const verifyUserPlan = async () => {
      setState(true);
      const res = await formatInstance();
      if (!res || !res.id) {
        setModalHasNoPlan(true);
      } else {
        setPlanId(res.id);
      }
      setState(false);
    };
    verifyUserPlan();
  }, []);

  return (
    <>
      <ModalCampaignOutgoing
        notify={notify}
        setShow={setModalCampaignOutgoing}
        show={modalCampaignOutgoing}
        reloadTable={(e) => {
          setReload(e);
          setReloadInstance(e);
        }}
      />
      <ModalHasNoPlan setShow={setModalHasNoPlan} show={modalHasNoPlan} />
      <ModalHasNoInstances
        show={modalHasNoInstances}
        setShow={setModalHasNoInstances}
      />
      <MainPagePagination
        cardsDirection="row"
        filterTable={() => WhatsappCampaignList.data}
        currentPageTable={currentPageTable}
        setCurrentPageTable={setCurrentPageTable}
        startDate={setSkip}
        totalRegisters={WhatsappCampaignList.total}
        isLoading={isLoadingWhatsapp}
        column={columns}
        title="Envio em Massa"
        setModalCreateUpdate={() => handleOpenCreateUpdate()}
        setRegisterPage={() => setWhatsappData(null)}
        onRowClick={(e) => {
          handleOpenCreateUpdate(e.id);
        }}
        customHead={
          <div className="flex flex-col xl:flex-row w-full gap-3">
            <Card className="flex-col w-full xl:w-1/2 col-span-2 pt-5 px-4 pb-4 gap-4">
              <div className="flex flex-col lg:flex-row w-full gap-3">
                <div className="flex flex-col gap-4 w-full xl:max-w-xs lg:w-1/3">
                  <strong className="">Pesquisar</strong>
                  <Input
                    onChange={(e) =>
                      e.target.value.length > 3
                        ? setKey(e.target.value)
                        : e.target.value.length === 0
                        ? setKey('')
                        : {}
                    }
                    icon={
                      <IconDictionary
                        name="Lupa"
                        size={18}
                        color="#8C8C8C"
                        className="mr-4"
                      />
                    }
                    placeholder="Pesquisar Campanha"
                    onKeyDown={(e) => e.key === 'Enter' && setInput(input)}
                    clearField={
                      input !== '' && (
                        <IconDictionary
                          name="Excluir"
                          color="#8C8C8C"
                          size={16}
                          className="absolute right-3 cursor-pointer"
                          onClick={() => {
                            setInput('');
                          }}
                        />
                      )
                    }
                  />
                </div>

                <div className="flex flex-col gap-4 w-full lg:w-36">
                  <strong className="">Data de Envio</strong>

                  <InputDate
                    className="-mt-1.5"
                    onChange={(e) => {
                      if (e !== null) {
                        setDate(
                          maskHelper.formatDateYMD(
                            e.toLocaleDateString('pt-br')
                          )
                        );
                      } else {
                        setDate('');
                      }
                    }}
                  />
                </div>
                <div className="flex gap-4 flex-col col-span-2 relative mb-10 md:mb-4 2xl:w-1/2">
                  <strong className="">Status</strong>
                  <SelectComponent
                    isMulti
                    options={optionStatusCampign.filter(
                      (item) =>
                        !statusCampaign.some((p) => p.value === item.value)
                    )}
                    value={null}
                    onChange={(e: any) => {
                      setStatusCampaign([...statusCampaign, ...e]);
                    }}
                    className="w-full lg:w-96 xl:w-60 2xl:w-80 3xl:w-96"
                  />
                  <div className="absolute grid grid-cols-5 gap-1 z-10 -bottom-6">
                    {statusCampaign.map((item) => (
                      <button
                        key={`${item.value}_bnt`}
                        title={item.label}
                        type="button"
                        onClick={() =>
                          setStatusCampaign(
                            statusCampaign.filter(
                              (elem) => elem.value !== item.value
                            )
                          )
                        }
                        style={{
                          backgroundColor:
                            item.value === 'paused' ? item.colorCard : '',
                        }}
                        className={`hover:opacity-70 text-[8px] 2xl:text-[10px] p-1 rounded-sm truncate max-w-[80px] 2xl:max-w-32 bg-${item.colorCard}`}
                      >
                        {item.label}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
            <div className="w-full xl:w-1/2 flex flex-col md:flex-row gap-3">
              <Card
                className={`flex-col min-w-fit px-2 w-full text-center 'xl:w-1/2'
                justify-center items-center gap-4 pb-3.5 pt-5`}
              >
                <strong>Campanhas em Disparo</strong>

                <Button
                  className="w-40 h-11"
                  onClick={() => setModalCampaignOutgoing(true)}
                >
                  Ver
                </Button>
              </Card>
              <Card className="flex-col min-w-fit px-2 w-full justify-center items-center gap-4 pb-3.5 pt-5">
                <strong>Cadastrar Campanha</strong>
                <Button
                  className="w-40 h-11"
                  onClick={() => handleOpenCreateUpdate()}
                >
                  Cadastrar
                </Button>
              </Card>
              <CardImportPerson reloadTable={() => {}} />
            </div>
          </div>
        }
      />
      {whatsappData && (
        <>
          <ModalRestartCampaign
            isLoading={isLoadingStatus}
            setShow={setModalCampaignRestart}
            show={modalCampaignRestart}
            restart={async () => {
              await onUpdateStatus('shooting', whatsappData);
              setModalCampaignRestart(false);
              setWhatsappData(null);
              setReload(String(Math.random()));
            }}
          />
          <ModalStartCampaign
            isLoading={isLoadingStatus}
            setShow={setModalCampaignStart}
            show={modalCampaignStart}
            start={async () => {
              await onUpdateStatus('shooting', whatsappData);
              setModalCampaignStart(false);
              setWhatsappData(null);
              setReload(String(Math.random()));
            }}
          />
          <DeleteModal
            apiFunction={ApiWhatsappCampaign.deleteWhatsappCampaign}
            notify={notify}
            type="Mensagens"
            updateTable={() => setReload(`${Math.random()} 'delete'`)}
            close={() => setShowModalDelete(false)}
            id={whatsappData.id}
            name={whatsappData.title}
            open={showModalDelete}
          />
        </>
      )}
    </>
  );
};

export default WhatsappCampaign;
