import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Modal } from '../../../../../components';
import maskHelper from '../../../../../helpers/mask.helper';
import { contractProps } from '../../../../contract/types';

interface props {
  show: boolean;
  // eslint-disable-next-line no-unused-vars
  setShow: (e: boolean) => void;
  contract: contractProps;
}

const ModalClientHasContract: React.FC<props> = ({
  setShow,
  show,
  contract,
}) => {
  const navigate = useNavigate();
  const statusPage = () => {
    setShow(false);
    navigate(`/contracts/${contract.id}`);
  };
  return (
    <Modal
      title="Contrato existente"
      setIsOpen={setShow}
      isOpen={show}
      size="medium"
      minHeight="min-h-[230px]"
      header={false}
    >
      <p className="mb-5 text-md">
        Este cliente já possui um contrato{' '}
        <strong className="font-semibold">{contract.status}</strong>
      </p>
      <div className="fle flex-col text-sm">
        <div className="flex gap-1">
          <strong className="font-semibold">Contrato:</strong>
          <p>{contract.number}</p>
        </div>
        <div className="flex gap-1">
          <strong className="font-semibold">Data de criação:</strong>
          <p>{maskHelper.formatDateDMY(contract.created_at)}</p>
        </div>
        <div className="flex gap-1">
          <strong className="font-semibold">Status:</strong>
          <p>{contract.status}</p>
        </div>
        <div className="flex gap-1">
          <strong className="font-semibold">Última alteração status:</strong>
          <p>{maskHelper.formatDateDMY(contract.status_date ?? '')}</p>
        </div>
        <div className="flex gap-1">
          <strong className="font-semibold">Responsável:</strong>
          <p>
            {
              contract
                .companie_user_companie_userTocontract_companie_user_id_promoter
                .name
            }
          </p>
        </div>
        <Button
          className="col-span-2 w-full mt-5"
          variant="primary-strong"
          onClick={statusPage}
        >
          ok
        </Button>
      </div>
    </Modal>
  );
};

export default ModalClientHasContract;
