/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { MdCheckBox, MdEdit, MdOutlineClose } from 'react-icons/md';
import { EconomicActivity } from '../../../../../types';
import { ApiExpenses } from '../../../../financial/api';
import { Button, Card, Table } from '../../../../../components';
import CreateClientInvoiceServiceModal from './CreateClientInvoiceServiceModal';
import { ClientProps } from '../../../types';

interface FormProps {
  client: ClientProps;
}
const ClientInvoiceServices: FC<FormProps> = ({ client }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [economicActivities, setEconomicActivities] = useState<
    EconomicActivity[]
  >([]);
  const [selectedEconomicActivity, setSelectedEconomicActivity] =
    useState<EconomicActivity | null>(null);
  const getClientEconomicActivities = useCallback(() => {
    ApiExpenses.getClientEconomicActivities(client).then((response) =>
      setEconomicActivities(response.data)
    );
  }, []);

  useEffect(() => {
    getClientEconomicActivities();
  }, [getClientEconomicActivities]);

  const columns = useMemo(
    () => [
      {
        name: 'Padrão',
        width: '100px',
        cell: (row: EconomicActivity) =>
          row.isDefault ? <MdCheckBox color="green" size={18} /> : '',
      },
      {
        name: 'Código de serviço municipal',
        cell: (row: any) => row.cityServiceDescription,
      },
      { name: 'ISS', cell: (row: any) => `${row.issRate} %` },
      {
        name: 'Descrição',
        width: '50%',
        cell: (row: any) => row.defaultInvoiceDescription,
      },
      {
        name: 'Ações',
        width: '100px',
        cell: (row: EconomicActivity) => (
          <div className="text-center w-full">
            <button
              className="bg-none border-none"
              type="button"
              onClick={() => {
                setSelectedEconomicActivity(row);
                setIsOpen(true);
              }}
            >
              <MdEdit size={18} />
            </button>
            <button className="bg-none border-none" type="button">
              <MdOutlineClose size={18} />
            </button>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <Card className="flex-col">
      <h2>Serviços da empresa</h2>
      <div className="flex justify-end">
        <Button className="px-4" onClick={() => setIsOpen(true)}>
          Adicionar serviço
        </Button>
      </div>
      <Table columns={columns} data={economicActivities} isLoading={false} />

      {isOpen && (
        <CreateClientInvoiceServiceModal
          client={client}
          economicActivity={selectedEconomicActivity}
          setIsOpen={(isModalOpen: boolean) => {
            if (!isModalOpen) {
              getClientEconomicActivities();
            }
            setIsOpen(isModalOpen);
          }}
          isOpen={isOpen}
        />
      )}
    </Card>
  );
};

export default ClientInvoiceServices;
