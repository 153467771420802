/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import {
  Button,
  Card,
  Modal,
  SelectComponent,
  Spinner,
  isLoadingApplication,
} from '../../../components';
import { useFetchFunnelByProfile } from '../../funnel/hooks';
import { CreateStepFunnel, FunnelList } from '../../funnel/types';
import RegisterOpportunity from '../components/register';
import maskHelper from '../../../helpers/mask.helper';
import { ApiOpportunity } from '../api';
import IconDictionary from '../../../components/Icons/icons';
import { ApiFunnel } from '../../funnel/api';
import SearchOpportunity from '../components/searchOpportunity';
import { useFetchSegment } from '../../segment/hooks';
import { useFetchSalesChannel } from '../../salesChannel/hooks';
import { useFetchResponses } from '../../responseWinLose/hooks';
import { useFetchUsersCompanie } from '../../users/hooks';
import {
  OpportunityProps,
  ProductOpportunity,
  filterOpportunityProps,
  serviceOpportunity,
} from '../types';
import UpdateOpportunity from '../components/update';
import { useFetchActivitieType } from '../../activitieType/hooks';
import SendWhatsApp from '../components/update/components/sendWhatsapp';
import { CreateUpdateActivity } from '../components/update/components/activity';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { ToastNotify } from '../../../components/Toast/toast';
import OpportunityServices from '../components/update/components/services';
import OpportunityStepCards from '../components/mainPage/opportunityStepCards';
import {
  useFetchAllServicesOpportunity,
  useFetchOpportunityDropdown,
} from '../hooks';
import { useFetchAllProductsActive } from '../../product/hooks';
import ModalClone from '../components/clone/modalClone';
import ModalInfos from '../components/modal/info';

interface productProps {
  productsOptions: { label: string; value: number; price: number }[];
  OpportunityProductList: any[];
  opportunityId: number;
  companieUserId: number;
  show: boolean;
  close: () => void;
}

function AddProduct({
  productsOptions,
  OpportunityProductList,
  companieUserId,
  opportunityId,
  show,
  close,
}: productProps) {
  const { setState } = useContext(isLoadingApplication);
  const [productsList, setProductList] = useState(OpportunityProductList);

  async function reloadProducts(method: 'create' | 'update', products?: any[]) {
    setState(true);
    if (method === 'create') {
      const response = await ApiOpportunity.getProductByOpportunity(
        opportunityId
      );

      if (typeof response !== 'string') {
        setProductList(response);
      }
    } else if (products) {
      setProductList([...products]);
    }
    setState(false);
  }

  return (
    <Modal
      title="Serviços"
      setIsOpen={close}
      isOpen={show}
      size="2xlarge"
      minHeight="min-h-[360px]"
    >
      <OpportunityServices
        OpportunityProductList={productsList}
        companieUserId={companieUserId}
        opportunityId={opportunityId}
        productsOptions={productsOptions}
        updateProducts={(method, product) => reloadProducts(method, product)}
      />
    </Modal>
  );
}

const Opportunities: React.FC = () => {
  const { notify } = ToastNotify();
  const accountProps = getTokenAccountInformation();

  const { setState } = useContext(isLoadingApplication);
  const [currentFunnel, setCurrentFunnel] = useState<FunnelList | null>(null);
  const [showPlusOptions, setShowPlusOptions] = useState(-1);

  const [isLoadingFunnel, setIsLoadingFunnel] = useState(true);
  const [key, setKey] = useState('');
  const [inputOpportunity, setInputOpportunity] = useState('');
  const [modalCreateOpportunity, setModalCreateOpportunity] = useState(false);
  const [modalFilterOpportunity, setModalFilterOpportunity] = useState(false);
  const [stepInfo, setStepInfo] = useState<CreateStepFunnel>();
  const [cardsOrder, setCardsOrder] = useState({});

  const [cardActions, setCardsAction] = useState<{
    activity: { open: boolean; opportunityId: number };
    product: { open: boolean; opportunity: OpportunityProps | null };
    whatsapp: { open: boolean; opportunity: OpportunityProps | null };
    clone: { open: boolean; opportunityId: number };
  }>({
    activity: { open: false, opportunityId: -1 },
    product: { open: false, opportunity: null },
    whatsapp: { open: false, opportunity: null },
    clone: { open: false, opportunityId: -1 },
  });

  const [updateOpportunity, setUpdateOpportunity] = useState<{
    open: boolean;
    opportunity: OpportunityProps | null;
  }>({ open: true, opportunity: null });

  const [datePicker, setDatePicker] = useState<{
    open: boolean;
    start?: Date;
    end?: Date;
  }>({
    open: false,
  });

  const { OpportunityList, isLoadingOpportunityList } =
    useFetchOpportunityDropdown(inputOpportunity);

  const opportunityByFunnel = OpportunityList.data.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const defaultValues = {
    opportunityId: null,
    productId: [],
    salesChannelId: [],
    responses_win_lose_id: [],
    companieUserId: [],
    order: 'title_asc',
    status: 'Aberta',
    skip: 0,
    take: 30,
    close_date_filter: 'all',
  };

  const initialFilters = () => {
    const savedFilters = localStorage.getItem(`filters_${accountProps.id}`);
    return savedFilters ? JSON.parse(savedFilters) : defaultValues;
  };

  const [filterModal, setFilterModal] =
    useState<filterOpportunityProps>(initialFilters);

  const dateFilterOptions = [
    { value: 'all', label: 'Todas' },
    { value: 'today', label: 'Hoje' },
    { value: 'yesterday', label: 'Ontem' },
    { value: 'this_week', label: 'Esta semana' },
    { value: 'last_week', label: 'Semana passada' },
    { value: 'this_month', label: 'Este mês' },
    { value: 'last_30_days', label: 'Últimos 30 dias' },
    { value: 'other_period', label: 'Outro intervalo' },
  ];
  const { ProductsList } = useFetchAllProductsActive('key');
  const productOptions = ProductsList.map((item) => ({
    value: item.id,
    label: item.title,
    price: item.value,
  }));
  const [filterDate, setFilterDate] = useState(dateFilterOptions[0].value);
  const { OpportunityServicesList } = useFetchAllServicesOpportunity(
    '',
    cardActions.product.opportunity?.id ?? -1
  );
  const { ActivitieTypeList } = useFetchActivitieType(
    'key',
    accountProps.companie_id
  );
  const activityTypeOptions = ActivitieTypeList.map((sale) => ({
    value: sale.id,
    label: sale.title,
  }));

  const { SalesChannelList } = useFetchSalesChannel(
    'key',
    accountProps.companie_id
  );
  const salesChannelOptions = SalesChannelList.map((sale) => ({
    value: sale.id,
    label: sale.title,
  }));

  const { SegmentList } = useFetchSegment('key', accountProps.companie_id);
  const segmentOptions = SegmentList.map((segment) => ({
    value: segment.id,
    label: segment.title,
  }));

  const { FunnelsList } = useFetchFunnelByProfile('key', accountProps.id);
  const funnelsOptions = FunnelsList.filter(
    (item) => item.funnel_step.length > 0
  ).map((funnel) => ({
    value: funnel.id,
    label: funnel.title,
  }));

  const { UsersList } = useFetchUsersCompanie('key', accountProps.companie_id);
  const userOptions = UsersList.map((sale) => ({
    value: sale.id,
    label: sale.name,
  }));

  const { ResponsesList } = useFetchResponses('key', accountProps.companie_id);
  const positiveResOptions = ResponsesList.filter((item) => item.is_win).map(
    (res) => ({ value: res.id, label: res.title, isdisabled: false })
  );
  const negativeResOptions = ResponsesList.filter((item) => !item.is_win).map(
    (res) => ({ value: res.id, label: res.title, isdisabled: false })
  );

  const division = [
    [{ label: 'Respostas de Perda', value: 0, isdisabled: true }],
    [{ label: 'Respostas de Ganho', value: 0, isdisabled: true }],
  ];

  const responseOptions = division[0]
    .concat(negativeResOptions)
    .concat(division[1])
    .concat(positiveResOptions);

  useEffect(() => {
    const fetchOpportunity = async () => {
      setState(true);
      const opportunity = currentFunnel
        ? currentFunnel.id !== -1
          ? await ApiOpportunity.getOpportunityByCompanyFunnelAdvanced(
              accountProps.companie_id,
              currentFunnel.id,
              filterDate,
              {
                ...filterModal,
                opportunityId: filterModal.opportunityId?.value ?? null,
              }
            )
          : []
        : [];
      if (typeof opportunity !== 'string') {
        if (accountProps.profile.title === 'Vendedor') {
          setCardsOrder(
            opportunity[0]
              ? opportunity[0].funnel_step
                  .map((step) => ({
                    path: step.path,
                    obs: step.obs,
                    title: step.title,
                    id: step.id,
                    listOpportunity: step.opportunity.filter(
                      (item) => item.companie_user_id === accountProps.id
                    ),
                    total: step.total,
                    totalPages: step.totalPages,
                    order: step.order,
                  }))
                  .reduce(
                    (acc, current) => ({ ...acc, [current.id]: current }),
                    {}
                  )
              : {}
          );
        } else {
          const allOpportunities = opportunity[0]
            ? opportunity[0].funnel_step
                .map((step) => ({
                  path: step.path,
                  obs: step.obs,
                  title: step.title,
                  id: step.id,
                  listOpportunity: step.opportunity,
                  total: step.total,
                  totalPages: step.totalPages,
                  order: step.order,
                }))
                .reduce(
                  (acc, current) => ({ ...acc, [current.id]: current }),
                  {}
                )
            : {};
          setCardsOrder(allOpportunities);
        }
      } else {
        setCardsOrder([]);
      }
      setState(false);
    };

    if (filterDate !== 'other_period') {
      fetchOpportunity();
      if (!filterDate.includes('other_period')) {
        setDatePicker({ ...datePicker, open: false });
      }
    } else {
      setDatePicker({ ...datePicker, open: true });
    }
  }, [key, currentFunnel, filterDate, filterModal]);

  useEffect(() => {
    const FetchFunnel = async () => {
      setIsLoadingFunnel(true);
      const funnel = await ApiFunnel.getFunnelByProfileId(accountProps.id);
      if (typeof funnel !== 'string' && funnel.length > 0) {
        const validFunnels = funnel.filter(
          (item: any) => item.funnel_step.length > 0
        );
        if (validFunnels.length > 0) setCurrentFunnel(validFunnels[0]);
      }
      setIsLoadingFunnel(false);
    };
    FetchFunnel();
    if (accountProps.profile_id === 5) {
      setFilterModal({ ...filterModal, companieUserId: [accountProps.id] });
    }
  }, []);

  return (
    <div
      className={
        updateOpportunity.open && updateOpportunity.opportunity
          ? 'flex flex-col max-w-full h-full'
          : 'flex flex-col max-h-[93vh] max-w-full h-full'
      }
    >
      <ModalInfos
        obs={stepInfo?.obs}
        path={stepInfo?.path}
        setShow={() => setStepInfo(undefined)}
        show={!!stepInfo}
      />

      {updateOpportunity.open && updateOpportunity.opportunity ? (
        <>
          <div className="flex gap-3 items-center text-primary">
            <IconDictionary name="Oportunidades" size={24} />
            <strong className="text-lg text-black">Oportunidades</strong>
          </div>
          <UpdateOpportunity
            funnels={funnelsOptions}
            productOptions={productOptions}
            activityOptions={activityTypeOptions}
            closeUpdate={() => {
              setUpdateOpportunity({ ...updateOpportunity, open: false });
              setFilterModal(defaultValues);
            }}
            responseWinLose={ResponsesList}
            opportunityId={updateOpportunity.opportunity.id}
            salesChannelOptions={salesChannelOptions}
            segmentOptions={segmentOptions}
            stepsOptions={currentFunnel?.funnel_step ?? []}
          />
        </>
      ) : (
        <>
          <ModalClone
            reloadMain={(e) =>
              setCurrentFunnel(
                FunnelsList.find((item) => item.id === e) ?? null
              )
            }
            show={cardActions.clone.open}
            funnelOptions={funnelsOptions}
            opportunityId={cardActions.clone.opportunityId}
            userOptions={userOptions}
            setShow={(e) => {
              setCardsAction({
                ...cardActions,
                clone: { open: e, opportunityId: -1 },
              });
              setShowPlusOptions(-1);
            }}
          />
          <SendWhatsApp
            number={
              cardActions.whatsapp.opportunity?.opportunity_person[0].person
                .whatsapp
            }
            show={cardActions.whatsapp.open}
            setOpen={() => {
              setCardsAction((prev) => ({
                ...prev,
                whatsapp: { open: false, opportunity: null },
              }));
              setShowPlusOptions(-1);
            }}
          />
          {cardActions.activity.opportunityId !== -1 && (
            <CreateUpdateActivity
              activityOptions={activityTypeOptions}
              companieUserId={accountProps.id}
              opportunityId={cardActions.activity.opportunityId}
              reloadTable={setKey}
              setShowModalCreateUpdate={() => {
                setCardsAction((prev) => ({
                  ...prev,
                  activity: {
                    open: false,
                    opportunityId: -1,
                  },
                }));
                setShowPlusOptions(-1);
              }}
              showModalCreateUpdate={cardActions.activity.open}
              notify={notify}
              currentActivity={null}
              personId={null}
            />
          )}

          {cardActions.product.open && cardActions.product.opportunity && (
            <AddProduct
              OpportunityProductList={OpportunityServicesList}
              companieUserId={accountProps.id}
              opportunityId={cardActions.product.opportunity.id}
              productsOptions={productOptions}
              show={cardActions.product.open}
              close={() => {
                setCardsAction((prev) => ({
                  ...prev,
                  product: {
                    open: false,
                    opportunity: null,
                  },
                }));
                setShowPlusOptions(-1);
                setKey(`${Math.random()}`);
              }}
            />
          )}
          <div className="flex gap-3 items-center text-primary w-fit">
            <IconDictionary name="Oportunidades" size={24} />
            <strong className="text-lg text-black">Oportunidades</strong>
          </div>
          <div className="flex flex-col flex-wrap md:flex-row justify-between w-full mt-10 gap-3">
            <div className="flex gap-3 w-full md:w-fit">
              <Button
                className="flex w-44 gap-2.5 items-center justify-center text-white  mt-7"
                variant="primary-strong"
                actionType="button-add"
                onClick={() => setModalCreateOpportunity(true)}
                disabled={
                  currentFunnel === null ||
                  currentFunnel.funnel_step.length === 0 ||
                  isLoadingFunnel
                }
              >
                Oportunidade
              </Button>
              <Card
                className="w-10 h-10 rounded-lg items-center justify-center cursor-pointer text-primary  mt-7"
                onClick={() => setModalFilterOpportunity(true)}
              >
                <IconDictionary name="Filtro" size={16} />
              </Card>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 w-full mt-2 md:mt-0 lg:w-fit">
              <div className="flex flex-col w-full sm:w-60 lg:w-48 2xl:w-60">
                <p className="text-gray-600 text-sm mb-1">Data de Abertura</p>
                <SelectComponent
                  variant="outline-primary"
                  icon="Calendario"
                  value={dateFilterOptions.find(
                    (item) => item.value === filterDate
                  )}
                  className="w-full sm:w-60 lg:w-48 2xl:w-60"
                  options={dateFilterOptions}
                  closeMenuOnSelect
                  onChange={(e: any) => {
                    setFilterDate(e.value);
                  }}
                  maxOptionsHeight="300px"
                />
              </div>
              {datePicker.open && (
                <div className="flex flex-col w-full sm:w-auto">
                  <p className="text-gray-600 text-sm mb-1">
                    Selecione Intervalo
                  </p>
                  <div className="flex flex-col sm:flex-row gap-2">
                    <DatePicker
                      openCalendarOnFocus
                      format="dd/MM/yyyy"
                      className="start_date_input"
                      onChange={(e: Date) => {
                        setDatePicker({ ...datePicker, start: e });
                        if (typeof datePicker.end !== 'undefined') {
                          setFilterDate(
                            `other_period/${maskHelper.formatDateYMD(
                              e.toLocaleDateString('pt-br')
                            )}/${maskHelper.formatDateYMD(
                              datePicker.end?.toLocaleDateString('pt-br') ?? ''
                            )}`
                          );
                        }
                      }}
                      value={datePicker.start}
                    />
                    <p className="bg-primary w-11 h-11 flex items-center justify-center text-xs text-white">
                      Até
                    </p>
                    <DatePicker
                      openCalendarOnFocus
                      format="dd/MM/yyyy"
                      className="end_date_input"
                      onChange={(e: Date) => {
                        setDatePicker({ ...datePicker, end: e });
                        if (typeof datePicker.start !== 'undefined') {
                          setFilterDate(
                            `other_period/${maskHelper.formatDateYMD(
                              datePicker.start?.toLocaleDateString('pt-br') ??
                                ''
                            )}/${maskHelper.formatDateYMD(
                              e.toLocaleDateString('pt-br')
                            )}`
                          );
                        }
                      }}
                      value={datePicker.end}
                      minDate={datePicker.start}
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col w-full sm:w-60 lg:w-48 2xl:w-60">
                <p className="text-gray-600 text-sm mb-1">Funil</p>
                <SelectComponent
                  maxOptionsHeight="300px"
                  variant="outline-primary"
                  icon="Funil"
                  value={funnelsOptions.find(
                    (item) => item.value === currentFunnel?.id
                  )}
                  className="w-full sm:w-60 lg:w-48 2xl:w-60"
                  options={funnelsOptions}
                  closeMenuOnSelect
                  onChange={(e: any) => {
                    setCurrentFunnel(
                      FunnelsList.find((item) => item.id === e.value) ?? null
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {isLoadingFunnel ? (
            <div className="m-auto">
              <Spinner />
            </div>
          ) : (
            <>
              {!isLoadingFunnel && currentFunnel === null ? (
                <div className="flex flex-col mt-10">
                  <p className="text-center text-sm my-auto sm:w-96 mx-auto text-gray-600">
                    Nenhum funil encontrado.
                    <br /> Para liberar a criação de oportunidades, crie um novo
                    funil e adicione pelo menos uma etapa a ele.
                  </p>
                </div>
              ) : Object.entries(cardsOrder).length > 0 ? (
                <OpportunityStepCards
                  setStepInfo={setStepInfo}
                  filters={filterModal}
                  period={filterDate}
                  cardsOrder={cardsOrder}
                  setCardsAction={setCardsAction}
                  setCardsOrder={setCardsOrder}
                  setShowPlusOptions={setShowPlusOptions}
                  setUpdateOpportunity={setUpdateOpportunity}
                  showPlusOptions={showPlusOptions}
                />
              ) : (
                <p className="text-center text-sm my-auto sm:w-96 mx-auto text-gray-600">
                  Nenhuma oportunidade encontrada.
                </p>
              )}
              <RegisterOpportunity
                salesChannelOptions={salesChannelOptions}
                segmentOptions={segmentOptions}
                stepsOptions={
                  currentFunnel?.funnel_step.map((items) => ({
                    value: items.id,
                    label: items.title,
                  })) ?? []
                }
                reloadTable={setKey}
                setShow={setModalCreateOpportunity}
                show={modalCreateOpportunity}
              />
              {currentFunnel && (
                <SearchOpportunity
                  isLoadingDropdown={isLoadingOpportunityList}
                  setInputOpportunity={setInputOpportunity}
                  filters={filterModal}
                  setFilter={setFilterModal}
                  responseOptions={responseOptions}
                  salesChannelOptions={salesChannelOptions}
                  userOptions={userOptions}
                  opportunityList={opportunityByFunnel}
                  setCardsOrder={setCardsOrder}
                  companyId={accountProps.companie_id}
                  funnelId={currentFunnel.id}
                  period={filterDate}
                  setShow={setModalFilterOpportunity}
                  show={modalFilterOpportunity}
                  datePicker={datePicker}
                  setDatePicker={setDatePicker}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Opportunities;
