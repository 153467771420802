import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, Input, InputDate } from '../../../../../components';
import maskHelper from '../../../../../helpers/mask.helper';
import {
  clearNullValuesObject,
  dictionaryError,
} from '../../../../../helpers/utils.helper';
import cpfValidation from '../../../../../helpers/validations/cpf.validation';
import phoneValidate from '../../../../../helpers/validations/phone.validate';
import { ApiContact } from '../../../api';
import { formPropsPage, generalDataContact } from '../../../types';
import cnpjValidation from '../../../../../helpers/validations/cnpj.validation';

interface props extends formPropsPage {
  validateDocument?: boolean;
}

type PersonWithDateOfBirth = Omit<generalDataContact, 'birth'> & {
  birth?: Date;
};

export const ContactGeneralData: React.FC<props> = ({
  nextPage,
  defaultValue,
  id,
  notify,
  setId,
  setContact,
  companyUserId,
  onePageRegister,
  validateDocument = false,
}) => {
  const defaultUserValues: PersonWithDateOfBirth = {
    companie_user_id: id,
    email: defaultValue?.email,
    name: defaultValue?.name ?? '',
    whatsapp: defaultValue?.whatsapp ?? '',
    office: defaultValue?.office,
    organization: defaultValue?.organization,
    phone: defaultValue?.phone,
    document: defaultValue?.document,
    birth: defaultValue?.birth
      ? new Date(`${defaultValue?.birth.slice(0, 10)} `)
      : undefined,
  };
  const todayDate = new Date();
  const yesterdayDate = new Date(todayDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const schemaOnePage = yup.object().shape(
    {
      name: yup.string().required('Nome é obrigatório'),
      birth: yup
        .date()
        .typeError('Data inválida')
        .nullable(true)
        .max(yesterdayDate, 'Data deve ser menor que hoje'),
      document: yup
        .string()
        .nullable()
        .test({
          message: 'CPF/CNPJ inválido',
          test: (value) => {
            if (value === '' || typeof value === 'undefined' || value === null)
              return true;
            return (
              cpfValidation.isValid(maskHelper.number(value)) ||
              cnpjValidation.isValid(maskHelper.number(value))
            );
          },
        }),
      whatsapp: yup.string().when('email', {
        is: (email: any) => !email || email.length === 0,
        then: yup
          .string()
          .required('É obrigatório preencher E-mail ou WhatsApp')
          .test({
            message: 'Número inválido',
            test: (value) =>
              value !== undefined
                ? phoneValidate.isValid(maskHelper.number(value))
                : true,
          }),
        otherwise: yup.string().test({
          message: 'Número inválido',
          test: (value) =>
            value !== undefined && value !== ''
              ? phoneValidate.isValid(maskHelper.number(value))
              : true,
        }),
      }),
      email: yup
        .string()
        .email('E-mail inválido')
        .when('whatsapp', {
          is: (whatsapp: any) => !whatsapp || whatsapp.length === 0,
          then: yup.string().required(''),
          otherwise: yup.string(),
        }),
    },
    [['email', 'whatsapp']]
  );

  const schemaWithDocument = yup.object().shape({
    name: yup.string().required('Nome é obrigatório'),
    birth: yup
      .date()
      .typeError('Data inválida')
      .nullable(true)
      .max(yesterdayDate, 'Data deve ser menor que hoje'),
    document: yup
      .string()
      .nullable()
      .test({
        message: 'CPF/CNPJ inválido',
        test: (value) => {
          if (value === '' || typeof value === 'undefined' || value === null)
            return true;
          return (
            cpfValidation.isValid(maskHelper.number(value)) ||
            cnpjValidation.isValid(maskHelper.number(value))
          );
        },
      }),
    email: yup.string().required('Campo obrigatório').email('E-mail inválido'),
    whatsapp: yup
      .string()
      .required('Campo obrigatório')
      .test({
        message: 'Número inválido',
        test: (value) =>
          value !== undefined
            ? phoneValidate.isValid(maskHelper.number(value))
            : true,
      }),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] =
    useState<PersonWithDateOfBirth>(defaultUserValues);

  const { handleSubmit, setValue, formState, reset, setError } =
    useForm<generalDataContact>({
      resolver: yupResolver(
        validateDocument ? schemaWithDocument : schemaOnePage
      ),
      defaultValues: defaultValue ? clearNullValuesObject(defaultValue) : {},
    });

  const Submit = async (values: generalDataContact) => {
    if (defaultValue !== null) {
      setIsLoading(true);
      const res = await ApiContact.updateContact(
        {
          name: values.name,
          whatsapp: values.whatsapp,
          email: values.email,
          office: values.office,
          organization: values.organization,
          phone: values.phone,
          document: values.document,
          birth: defaultValues.birth
            ? maskHelper.formatDateYMD(
                String(defaultValues.birth.toLocaleString('pt-BR'))
              )
            : null,
        },
        id
      );
      if (res.id) {
        setContact(res);
        setIsLoading(false);
        if (!onePageRegister) {
          notify({
            message: 'Contato atualizado com sucesso!',
            type: 'Success',
          });
        }

        nextPage(res.id);
      } else {
        switch (res) {
          case '"error_person_duplicate_document"':
            setError('document', { type: 'value', message: 'CPF já em uso' });
            break;
          case '"error_person_duplicate_email"':
            setError('email', { type: 'value', message: 'Email já em uso' });
            break;
          case '"error_person_duplicate_whatsapp"':
            setError('whatsapp', {
              type: 'value',
              message: 'WhatsApp já em uso',
            });
            break;
          case '"error_person_duplicate_phone"':
            setError('phone', {
              type: 'value',
              message: 'Telefone já em uso',
            });
            break;
        }
        setIsLoading(false);
        notify({
          message: dictionaryError(res),
          type: 'Error',
        });
      }
    } else {
      setIsLoading(true);
      const response = await ApiContact.createContact({
        ...values,
        companie_user_id: companyUserId,
        birth: defaultValues.birth
          ? maskHelper.formatDateYMD(
              String(defaultValues.birth.toLocaleString('pt-BR'))
            )
          : null,
      });
      if (response.id) {
        setContact(response);
        setId(response.id);
        if (!onePageRegister) {
          notify({
            message: 'Dados gerais salvos com sucesso',
            type: 'Success',
          });
        }
        setIsLoading(false);
        nextPage(response.id);
      } else {
        notify({ message: dictionaryError(response), type: 'Error' });
        switch (response) {
          case '"error_person_duplicate_document"':
            setError('document', { type: 'value', message: 'CPF já em uso' });
            break;
          case '"error_person_duplicate_email"':
            setError('email', { type: 'value', message: 'Email já em uso' });
            break;
          case '"error_person_duplicate_whatsapp"':
            setError('whatsapp', {
              type: 'value',
              message: 'WhatsApp já em uso',
            });
            break;
          case '"error_person_duplicate_phone"':
            setError('phone', {
              type: 'value',
              message: 'Telefone já em uso',
            });
            break;
        }
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setDefaultValues(defaultUserValues);
    reset({
      name: defaultValue?.name,
      whatsapp: defaultValue?.whatsapp,
      email: defaultValue?.email,
      document: defaultValue?.document,
      office: defaultValue?.office,
      organization: defaultValue?.organization,
      companie_user_id: defaultValue?.companie_user_id,
      phone: defaultValue?.phone,
    });
  }, [defaultValue]);

  return (
    <form
      className="flex flex-col sm:grid sm:grid-cols-2 gap-3 mt-8"
      onSubmit={handleSubmit(Submit)}
    >
      <Input
        variant="outline-orange"
        title="Nome"
        value={defaultValues.name}
        onChange={(e) => {
          setDefaultValues({ ...defaultValues, name: e.target.value });
          setValue('name', e.target.value);
        }}
        errorMensage={formState.errors.name?.message}
      />
      <Input
        variant="outline-orange"
        title="Organização"
        value={defaultValues.organization}
        onChange={(e) => {
          setDefaultValues({ ...defaultValues, organization: e.target.value });
          setValue('organization', e.target.value);
        }}
        errorMensage={formState.errors.organization?.message}
      />
      <Input
        title="CPF/CNPJ"
        variant="outline-orange"
        type="string"
        placeholder="000.000.000-00"
        value={maskHelper.cnpjCpf(defaultValues.document ?? '')}
        onChange={(e) => {
          setDefaultValues({
            ...defaultValues,
            document: e.target.value,
          });
          setValue('document', maskHelper.numberCPFCNPJ(e.target.value));
        }}
        errorMensage={formState.errors.document?.message}
      />
      <InputDate
        className="col-span-2 sm:col-span-1"
        title="Data de Nascimento"
        defaultValue={defaultValues.birth}
        onChange={(e: Date) => {
          if (e !== null) {
            setValue(
              'birth',
              maskHelper.formatDateYMD(String(e.toLocaleString('pt-BR')))
            );
            setDefaultValues({ ...defaultValues, birth: e });
          }
        }}
        errorMensage={formState.errors.birth?.message}
      />

      <Input
        title="Telefone"
        variant="outline-orange"
        placeholder="(00) 00000-0000"
        value={maskHelper.phone(defaultValues.phone ?? '')}
        onChange={(e) => {
          e.target.value = maskHelper.phone(e.target.value);
          setDefaultValues({ ...defaultValues, phone: e.target.value });
          setValue('phone', maskHelper.number(e.target.value));
        }}
        errorMensage={formState.errors.phone?.message}
      />
      <Input
        title="WhatsApp"
        variant="outline-orange"
        placeholder="(00) 00000-0000"
        value={maskHelper.phone(defaultValues.whatsapp)}
        onChange={(e) => {
          e.target.value = maskHelper.phone(e.target.value);
          setDefaultValues({ ...defaultValues, whatsapp: e.target.value });
          setValue('whatsapp', maskHelper.number(e.target.value));
        }}
        errorMensage={formState.errors.whatsapp?.message}
      />
      <Input
        title="E-mail"
        variant="outline-orange"
        value={defaultValues.email}
        onChange={(e) => {
          setDefaultValues({ ...defaultValues, email: e.target.value });
          setValue('email', e.target.value);
        }}
        errorMensage={formState.errors.email?.message}
      />
      <Input
        title="Cargo"
        variant="outline-orange"
        value={defaultValues.office}
        onChange={(e) => {
          setDefaultValues({ ...defaultValues, office: e.target.value });
          setValue('office', e.target.value);
        }}
        errorMensage={formState.errors.office?.message}
      />

      <div className="col-span-2 flex justify-center mt-6">
        <Button
          type="submit"
          actionType="button-loading"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {onePageRegister ? 'Salvar' : 'Salvar e continuar'}
        </Button>
      </div>
    </form>
  );
};

export default ContactGeneralData;
