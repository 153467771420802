import React, { useEffect, useState } from 'react';
import { Card, HeaderPages } from '../../../components';
import {
  getDefaultList,
  getDefaultMessages,
  postConfiguration,
  putDays,
  putForm,
  putMessage,
  putPeriod,
  putStatus,
  putValue,
} from '../api';
import {
  ISelectedOption,
  IWhatsAppAutomation,
  IWhatsAppMessages,
} from '../types';
import { useFetchFormSearch } from '../../searchForm/hooks';
import AutomationLayout from '../components/automationLayout';

const WhatsAppAutomations: React.FC = () => {
  const [data, setData] = useState<IWhatsAppAutomation[]>([]);
  const [messages, setMessages] = useState<IWhatsAppMessages[]>([]);
  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [valueAutomations, setValue] = useState<number | null>(null);
  const { SearchFormList, isLoadingData } = useFetchFormSearch('', true);
  const [intervalDays, setIntervalDays] = useState<number | null>(null);

  const formOptions = [
    ...SearchFormList.map((form: any) => ({
      label: form.title,
      value: form.id,
    })),
  ];
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    const result = await getDefaultList();
    setData(result);
    const messages1 = await getDefaultMessages();
    setMessages(messages1);

    const initialSwitchStates: { [key: string]: boolean } = {};

    result.forEach((item: IWhatsAppAutomation) => {
      initialSwitchStates[item.id.toString()] =
        item.companie_whatsapp_message_sending_configuration.length > 0
          ? item.companie_whatsapp_message_sending_configuration[0].is_active
          : false;
    });

    const initialValue =
      result[9]?.companie_whatsapp_message_sending_configuration[0]?.value ?? 0;
    setValue(initialValue);
    setSwitchStates(initialSwitchStates);
  };

  const handleSwitchChange = async (
    rowId: string,
    row: IWhatsAppAutomation
  ) => {
    const newState = (prevStates: { [key: string]: boolean }) => ({
      ...prevStates,
      [rowId]: !prevStates[rowId],
    });
    setSwitchStates(newState);
    const updatedState = newState(switchStates);
    const status = {
      companie_whatsapp_message_sending_title_default_id:
        row.companie_whatsapp_message_sending_configuration[0].id,
      is_active: updatedState[rowId],
    };
    await putStatus(status);
  };

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    };
    loadInitialData();
  }, []);

  const handleSelectMessageChange = async (
    selectedOption: ISelectedOption,
    row: IWhatsAppAutomation
  ) => {
    if (row.companie_whatsapp_message_sending_configuration[0]) {
      const message = {
        companie_whatsapp_message_default_id: selectedOption.value,
        companie_whatsapp_message_sending_title_default_id:
          row.companie_whatsapp_message_sending_configuration[0].id,
      };
      await putMessage(message);
    } else {
      const configuration = {
        companie_whatsapp_message_sending_title_default_id: row.id,
        companie_whatsapp_message_default_id: selectedOption.value,
        qty: null,
        is_active: false,
      };
      await postConfiguration(configuration);
    }
    await fetchData();
  };

  const handleSelectDays = async (selectDay: ISelectedOption, id: number) => {
    const qty = {
      id,
      qty: selectDay.value,
    };
    await putDays(qty);
  };

  const handleSelectForm = async (id: number, formId: number | null) => {
    await putForm(id, formId);
  };

  const handleSelectPeriod = async (id: number, period: number) => {
    await putPeriod(id, period);
  };

  const handlePostValue = async (id: number, value: number) => {
    await putValue(id, value);
  };

  return (
    <div>
      <div className="mb-8">
        <HeaderPages title="Automação de envio de Whatsapp" />
      </div>
      <Card className="min-h-[54rem]">
        <div className="w-full px-6 overflow-x-auto">
          <div className="min-w-[1000px]">
            <div className="flex flex-col flex-wrap place-content-start sm:flex-row">
              {data.map((form) => (
                <AutomationLayout
                  form={form}
                  states={switchStates}
                  switchState={handleSwitchChange}
                  selectDays={handleSelectDays}
                  selectPeriod={handleSelectPeriod}
                  valueAutomations={valueAutomations}
                  setValue={setValue}
                  setIntervalDays={setIntervalDays}
                  intervalDays={intervalDays}
                  postValue={handlePostValue}
                  selectMessageChange={handleSelectMessageChange}
                  messages={messages}
                  isLoadingData={isLoadingData}
                  selectForm={handleSelectForm}
                  formOptions={formOptions}
                />
              ))}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default WhatsAppAutomations;
