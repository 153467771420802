import { useState } from 'react';
import { useFetchAdministrator } from './hooks';

import ModalEdit from './modal';
import IconDictionary from '../../../components/Icons/icons';
import maskHelper from '../../../helpers/mask.helper';
import { AdminProps } from './types';
import MainPage from '../../main/components/mainPage';

export default function Parameters() {
  const [modalEdit, setModalEdit] = useState(false);
  const [input, setInput] = useState('');
  const [data, setData] = useState<AdminProps | null>(null);
  const [key, setKey] = useState('');

  const { Administrator, isLoadingAdministrator } = useFetchAdministrator(key);

  const columns = [
    {
      name: 'Empresa',
      key: 'companie',
      width: '180px',
      selector: (row: AdminProps) => row.companie.name,
    },
    {
      name: 'Conta - Whatsapp',
      width: '150px',
      selector: (row: AdminProps) =>
        `${row.plan_accounts_administrator_plan_account_id_whatsapp_planToplan_accounts?.num_plan} - ${row.plan_accounts_administrator_plan_account_id_whatsapp_planToplan_accounts?.title}`,
    },
    {
      name: 'Conta - Assinatura Digital',
      Width: '150px',
      selector: (row: AdminProps) =>
        `${row.plan_accounts_administrator_plan_account_id_digital_signature_planToplan_accounts?.num_plan} - ${row.plan_accounts_administrator_plan_account_id_digital_signature_planToplan_accounts?.title}`,
    },
    {
      name: 'Mensalidade Leadsim',
      key: 'value_subscription',
      width: '180px',
      selector: (row: AdminProps) =>
        maskHelper.formatMoeda(Number(row.value_subscription)),
    },
    {
      name: 'Laks',
      key: 'is_laks',
      width: '100px',
      selector: (row: AdminProps) => (row.is_laks ? 'Sim' : 'Não'),
    },
    {
      name: 'GalaxID Split',
      key: 'account_split_galax_id',
      width: '135px',
      selector: (row: AdminProps) => row.account_split_galax_id,
    },
    {
      name: 'Conta - Limite de usuários',
      key: 'plan_account_id_limit_user_plan',
      width: '200px',
      selector: (row: AdminProps) => row.plan_account_id_limit_user_plan,
    },
    {
      name: 'Conta - Nota Fiscal',
      key: 'plan_account_id_invoice_credit_plan',
      width: '200px',
      selector: (row: AdminProps) =>
        `${row.plan_accounts_administrator_plan_account_id_invoice_credit_planToplan_accounts?.num_plan} - ${row.plan_accounts_administrator_plan_account_id_invoice_credit_planToplan_accounts?.title}`,
    },

    {
      name: '',
      key: '',
      width: '80px',
      selector: (row: AdminProps) => (
        <button
          type="button"
          onClick={() => {
            setModalEdit(true);
            setData(row);
          }}
          className="w-16 flex items-center justify-center py-1"
        >
          <IconDictionary name="Editar" color="#E63B2E" />
        </button>
      ),
    },
  ];

  const filterTable = () => {
    if (input === '') {
      return Administrator;
    }
    return Administrator.filter(
      (item) =>
        item.companie.name.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        (
          item
            .plan_accounts_administrator_plan_account_id_digital_signature_planToplan_accounts
            ?.title ?? ''
        )
          .toLowerCase()
          .indexOf(input.toLowerCase()) > -1 ||
        (
          item
            .plan_accounts_administrator_plan_account_id_whatsapp_planToplan_accounts
            ?.title ?? ''
        )
          .toLowerCase()
          .indexOf(input.toLowerCase()) > -1
    );
  };

  return (
    <>
      {modalEdit && (
        <ModalEdit
          data={data}
          reloadTable={setKey}
          setShow={setModalEdit}
          show={modalEdit}
        />
      )}
      <MainPage
        column={columns}
        filterTable={filterTable}
        setInput={setInput}
        title="Parâmetros"
        setRegisterPage={() => {}}
        isLoading={isLoadingAdministrator}
        noCards
      />
    </>
  );
}
