import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-date-picker';
import {
  Button,
  Modal,
  SelectComponent,
  Spinner,
} from '../../../../components';
import { filterOpportunityProps } from '../../types';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setCardsOrder: (e: any) => void;
  funnelId: number;
  period: string;
  companyId: number;
  responseOptions: { label: string; value: number }[];
  userOptions: { label: string; value: number }[];
  salesChannelOptions: { label: string; value: number }[];
  opportunityList: { label: string; value: number }[];
  setFilter: (e: filterOpportunityProps) => void;
  filters: filterOpportunityProps;
  setInputOpportunity: (e: string) => void;
  isLoadingDropdown: boolean;
  datePicker: { open: boolean; start?: Date; end?: Date };
  setDatePicker: React.Dispatch<
    React.SetStateAction<{ open: boolean; start?: Date; end?: Date }>
  >;
}

const SearchOpportunity: React.FC<registerModalProps> = ({
  show,
  setShow,
  setCardsOrder,
  opportunityList,
  salesChannelOptions,
  responseOptions,
  userOptions,
  companyId,
  funnelId,
  period,
  filters,
  setFilter,
  isLoadingDropdown,
  setInputOpportunity,
  datePicker,
  setDatePicker,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClear, setIsLoadingClear] = useState(false);
  const schema = yup.object().shape({
    order: yup.string().required('Selecione uma opção'),
    status: yup.string().required('Selecione uma opção'),
    close_date_filter: yup.string().required('Selecione uma opção'),
    close_date_start: yup.date().when('close_date_filter', {
      is: 'other_period',
      then: yup.date().required('Selecione a data de início'),
      otherwise: yup.date().notRequired(),
    }),
    close_date_end: yup.date().when('close_date_filter', {
      is: 'other_period',
      then: yup
        .date()
        .min(
          yup.ref('close_date_start'),
          'A data final deve ser posterior à data inicial'
        )
        .required('Selecione a data de fim'),
      otherwise: yup.date().notRequired(),
    }),
  });

  const accountProps = getTokenAccountInformation();
  const userId = accountProps.id;

  const dateFilterOptions = [
    { value: 'all', label: 'Todas' },
    { value: 'today', label: 'Hoje' },
    { value: 'yesterday', label: 'Ontem' },
    { value: 'this_week', label: 'Esta semana' },
    { value: 'last_week', label: 'Semana passada' },
    { value: 'this_month', label: 'Este mês' },
    { value: 'last_30_days', label: 'Últimos 30 dias' },
    { value: 'other_period', label: 'Outro intervalo' },
  ];

  const { formState, handleSubmit, setValue, reset, control, watch } =
    useForm<filterOpportunityProps>({
      resolver: yupResolver(schema),
      defaultValues: {
        opportunityId: null,
        productId: [],
        salesChannelId: [],
        responses_win_lose_id: [],
        companieUserId: [],
        order: 'title_asc',
        status: 'Aberta',
        close_date_filter: 'all',
        close_date_start: undefined,
        close_date_end: undefined,
      },
    });

  const closeDateFilter = watch('close_date_filter');

  const loadFiltersFromLocalStorage = () => {
    const savedFilters = localStorage.getItem(`filters_${userId}`);
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      if (parsedFilters.close_date_start) {
        parsedFilters.close_date_start = new Date(
          parsedFilters.close_date_start
        );
      }
      if (parsedFilters.close_date_end) {
        parsedFilters.close_date_end = new Date(parsedFilters.close_date_end);
      }
      return parsedFilters;
    }
    return null;
  };

  const Submit = async (e: filterOpportunityProps) => {
    setIsLoading(true);
    const payload = {
      ...e,
      close_date_start: e.close_date_start || undefined,
      close_date_end: e.close_date_end || undefined,
    };
    setFilter(payload);
    localStorage.setItem(`filters_${userId}`, JSON.stringify(payload));
    setShow(false);
    setIsLoading(false);
  };

  const orderOptions = [
    { value: 'title_asc', label: 'Alfabética crescente' },
    { value: 'title_desc', label: 'Alfabética decrescente' },
    { value: 'created_at_asc', label: 'Data crescente' },
    { value: 'created_at_desc', label: 'Data decrescente' },
  ];

  const statusOptions = [
    { value: 'Aberta', label: 'Aberta' },
    { value: 'Ganha', label: 'Ganha' },
    { value: 'Perdida', label: 'Perdida' },
    { value: 'Todos', label: 'Todos' },
  ];

  const ClearData = async () => {
    setIsLoadingClear(true);

    const isFieldDisabled = accountProps.profile.id === 5;
    const currentCompanieUserId = isFieldDisabled
      ? watch('companieUserId') ?? []
      : [];

    const defaultFilter: filterOpportunityProps = {
      opportunityId: null,
      productId: [],
      salesChannelId: [],
      responses_win_lose_id: [],
      companieUserId: isFieldDisabled ? currentCompanieUserId : [],
      order: 'title_asc',
      status: 'Aberta',
      skip: 0,
      take: 30,
      close_date_filter: 'all',
      close_date_start: undefined,
      close_date_end: undefined,
    };
    await Submit(defaultFilter);
    reset(defaultFilter);
    localStorage.removeItem(`filters_${userId}`);
    setShow(false);

    setIsLoadingClear(false);
  };

  useEffect(() => {
    const savedFilters = loadFiltersFromLocalStorage();
    if (savedFilters) {
      reset(savedFilters);
      setFilter(savedFilters);
    } else {
      reset(filters);
    }
  }, [show]);

  return (
    <Modal
      title="Procurar oportunidade"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[540px]"
    >
      <form
        className="grid grid-cols-1 sm:grid-cols-2 gap-4"
        onSubmit={handleSubmit(Submit)}
      >
        <Controller
          control={control}
          name="order"
          render={({ field }) => (
            <SelectComponent
              title="Ordenar"
              options={orderOptions}
              value={orderOptions.filter((item) => field.value === item.value)}
              defaultValue={orderOptions[0]}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('order', e.value);
              }}
              errorMensage={formState.errors.order?.message}
              className="col-span-2 sm:col-span-1"
            />
          )}
        />

        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <SelectComponent
              title="Status"
              options={statusOptions}
              closeMenuOnSelect
              value={statusOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              defaultValue={statusOptions[0]}
              maxOptionsHeight="300px"
              onChange={(e: any) => {
                setValue('status', e.value);
              }}
              errorMensage={formState.errors.status?.message}
              className="col-span-2 sm:col-span-1"
            />
          )}
        />

        <Controller
          control={control}
          name="responses_win_lose_id"
          render={({ field }) => (
            <SelectComponent
              title="Respostas"
              options={responseOptions}
              value={responseOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              isMulti
              closeMenuOnSelect={false}
              hasDivision
              onChange={(e: any[]) => {
                setValue(
                  'responses_win_lose_id',
                  e.map((res) => res.value)
                );
              }}
              className="col-span-2 sm:col-span-1"
              errorMensage={formState.errors.responses_win_lose_id?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="companieUserId"
          render={({ field }) => (
            <SelectComponent
              title="Usuários"
              options={userOptions}
              value={userOptions.filter((item) =>
                field.value?.includes(item.value)
              )}
              isMulti
              isDisabled={accountProps.profile.id === 5}
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue(
                  'companieUserId',
                  e.map((user) => user.value)
                );
              }}
              className="col-span-2 sm:col-span-1"
              errorMensage={formState.errors.companieUserId?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="opportunityId"
          render={({ field }) => (
            <SelectComponent
              value={field.value}
              title="Oportunidade"
              isLoading={isLoadingDropdown}
              onInputChange={setInputOpportunity}
              autoComplete
              isClearable
              maxOptionsHeight="120px"
              options={opportunityList}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('opportunityId', e);
              }}
              className="col-span-2 sm:col-span-1"
              errorMensage={formState.errors.opportunityId?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="salesChannelId"
          render={({ field }) => (
            <SelectComponent
              title="Canal de venda"
              maxOptionsHeight="120px"
              value={salesChannelOptions.filter((item) =>
                field.value?.includes(item.value)
              )}
              options={salesChannelOptions}
              closeMenuOnSelect
              isMulti
              onChange={(e: any[]) => {
                setValue(
                  'salesChannelId',
                  e.map((sale) => sale.value)
                );
              }}
              className="col-span-2 sm:col-span-1"
              errorMensage={formState.errors.salesChannelId?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="close_date_filter"
          render={({ field }) => (
            <SelectComponent
              title="Data de fechamento"
              icon="Calendario"
              value={dateFilterOptions.find(
                (item) => field.value === item.value
              )}
              className="col-span-2 sm:col-span-1"
              options={dateFilterOptions}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('close_date_filter', e.value);
                if (e.value !== 'other_period') {
                  setValue('close_date_start', undefined);
                  setValue('close_date_end', undefined);
                }
              }}
              maxOptionsHeight="120px"
              errorMensage={formState.errors.close_date_filter?.message}
            />
          )}
        />

        {closeDateFilter === 'other_period' && (
          <div className="flex flex-col sm:flex-row sm:items-center gap-2 mt-2">
            <Controller
              control={control}
              name="close_date_start"
              render={({ field }) => (
                <DatePicker
                  openCalendarOnFocus
                  format="dd/MM/yyyy"
                  className="start_date_input w-full sm:w-auto"
                  onChange={(date: Date) => {
                    setValue('close_date_start', date);
                    field.onChange(date);
                  }}
                  value={field.value ? new Date(field.value) : null}
                />
              )}
            />
            <p className="bg-primary w-11 h-11 flex items-center justify-center text-xs text-white">
              Até
            </p>
            <Controller
              control={control}
              name="close_date_end"
              render={({ field }) => (
                <DatePicker
                  openCalendarOnFocus
                  format="dd/MM/yyyy"
                  className="end_date_input w-full sm:w-auto"
                  onChange={(date: Date) => {
                    setValue('close_date_end', date);
                    field.onChange(date);
                  }}
                  value={field.value ? new Date(field.value) : null}
                  minDate={watch('close_date_start')}
                />
              )}
            />
          </div>
        )}

        <div className="col-span-2 w-full flex gap-3 justify-center flex-col sm:flex-row">
          <Button
            disabled={isLoadingClear}
            variant="outline-primary"
            onClick={ClearData}
            actionType="button-loading"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            {isLoadingClear && (
              <div className="absolute right-0">
                <Spinner />
              </div>
            )}
            Limpar
          </Button>
          <Button
            disabled={isLoading}
            isLoading={!isLoadingClear && isLoading}
            variant="primary-strong"
            type="submit"
            actionType="button-loading"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            Filtrar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SearchOpportunity;
