/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import moment from 'moment';
import { Button, Input, InputDate, Modal } from '../../../../components';
import maskHelper from '../../../../helpers/mask.helper';

interface props {
  show: boolean;
  setShow: (e: boolean) => void;
  isLoading: boolean;
  confirm: () => void;
  type: 'R' | 'D';
  setSubItems: (e: any[]) => void;
  subItems: any[];
  savedValues: string[];
  installmentId: number | undefined;
  data: {
    value: string;
    clientName: string;
    contract: string;
    type: 'D' | 'R';
    planName: string;
    paymentForm: string;
    method: string;
    due_date: string;
  };
}

const ModalChangeInstallments: React.FC<props> = ({
  setShow,
  confirm,
  setSubItems,
  show,
  isLoading,
  type,
  data,
  subItems,
  savedValues,
  installmentId,
}) => {
  const methodText = {
    installments: 'Parcelado',
    in_sight: 'À vista',
  };
  return (
    <Modal
      isOpen={show}
      setIsOpen={setShow}
      size="6xlarge"
      title="Cobranças futuras do lançamento"
      minHeight="min-h-[100px]"
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 text-sm mb-10">
        <div className="flex flex-col">
          <div className="flex gap-1">
            <strong>{type === 'R' ? 'Cliente' : 'Fornecedor'}:</strong>
            <p>{data.clientName}</p>
          </div>

          <div className="flex gap-1">
            <strong>Contrato:</strong>
            <p>{data.contract}</p>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex gap-1">
            <strong>Tipo do lançamento:</strong>
            <p>{data.type === 'D' ? 'Despesa' : 'Receita'}</p>
          </div>
          <div className="flex gap-1">
            <strong>Conta de lançamento:</strong>
            <p>{data.planName}</p>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex gap-1">
            <strong>Forma de pagamento:</strong>
            <p>{data.paymentForm}</p>
          </div>
          <div className="flex gap-1">
            <strong>Método:</strong>
            <p>{methodText[data.method as keyof typeof methodText]}</p>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto grid grid-cols-7 text-sm items-center gap-x-3 ">
        <div className="min-w-[800px] col-span-7 grid grid-cols-7 gap-x-3">
          <p className="font-semibold">Parcela</p>
          <p className="font-semibold">Plano</p>
          <p className="font-semibold col-span-2">Valor</p>
          <p className="font-semibold ">Data de vencimento</p>
          <p className="font-semibold col-span-2">Observação</p>
        </div>

        <div className="min-w-[900px] col-span-7 max-h-[300px] overflow-y-auto grid grid-cols-7 text-sm items-center gap-x-3">
          {subItems.map((elem, index) => (
            <>
              <hr className="col-span-3 col-span-7 h-px bg-gray-400 w-full my-2" />
              <p className="text-xs ml-3">{elem.installment}</p>
              <p className="text-xs ">{elem.plan_accounts?.title}</p>
              <Input
                key={`value${elem.id}`}
                id={`value${elem.id}`}
                variant="outline-orange"
                value={maskHelper.formatCurrencyInput(elem.value)}
                onChange={(e) => {
                  const newArr = [...subItems];
                  newArr[index] = {
                    ...newArr[index],
                    value: e.target.value,
                  };

                  setSubItems(newArr);
                }}
                onBlur={(e) => {
                  const newArr = [...subItems];
                  newArr[index] = {
                    ...newArr[index],
                    value: maskHelper.formatCurrencyInput(e.target.value),
                  };

                  setSubItems(newArr);
                }}
              />
              <button
                type="button"
                className="flex gap-1 items-center justify-center text-xs font-medium cursor-pointer"
              >
                <input
                  key={`checkbox${elem.id}`}
                  type="checkbox"
                  defaultChecked
                  className="w-3 h-3 cursor-pointer"
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newArr = [...subItems];
                      newArr[index] = {
                        ...newArr[index],
                        value: maskHelper.formatMoeda(data.value),
                      };

                      setSubItems(newArr);
                    } else {
                      const newArr = [...subItems];
                      newArr[index] = {
                        ...newArr[index],
                        value: savedValues[index],
                      };
                      setSubItems(newArr);
                    }
                  }}
                />
                <p>Replicar Valor</p>
              </button>

              <InputDate
                defaultValue={
                  installmentId === elem.id
                    ? moment(data.due_date).toDate()
                    : moment(elem.due_date).toDate()
                }
                onChange={(e: Date) => {
                  const newArr = [...subItems];
                  newArr[index] = {
                    ...newArr[index],
                    due_date: maskHelper.formatDateYMD(
                      e.toLocaleString('pt-BR').slice(0, 10)
                    ),
                  };
                  setSubItems(newArr);
                }}
                className="mr-2"
              />
              <Input
                key={`obs${elem.id}`}
                classNameDiv="sm:col-span-2 pr-2"
                variant="outline-orange"
                disabled
                defaultValue={elem.obs}
              />
            </>
          ))}
        </div>
      </div>

      <div className="flex gap-3 mx-auto mt-8 w-fit">
        <Button
          disabled={isLoading}
          className="w-56"
          variant="outline-primary"
          onClick={() => setShow(false)}
        >
          Sair
        </Button>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          className="w-56"
          actionType="button-loading"
          variant="primary-strong"
          onClick={confirm}
        >
          Confirmar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalChangeInstallments;
