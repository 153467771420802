/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Spinner } from '../../../../../components';
import { generateHexColor } from '../../../../../helpers/utils.helper';
import { ApiDashboard } from '../../api';
import { opportuniyByStepProps } from '../../types';

interface props {
  companyId: number;
  period: string;
  funnelId: any;
  seller: any;
}
const OpportunityStepsGraph: React.FC<props> = ({
  companyId,
  period,
  funnelId,
  seller,
}) => {
  const [graph, setGraph] = useState<opportuniyByStepProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchOpportunityGraph = async () => {
    setIsLoading(true);
    const opportuniyByStep = await ApiDashboard.getOpportuniyByStep(
      companyId,
      funnelId,
      period,
      seller
    );
    if (typeof opportuniyByStep !== 'string') {
      setGraph(opportuniyByStep);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchOpportunityGraph();
  }, [period, funnelId, seller]);

  return !isLoading ? (
    <>
      <div className="flex flex-col sm:flex-row justify-between w-full">
        {' '}
        <p className="font-medium text-xs mb-2 sm:mb-0 uppercase">
          Oportunidades por Etapa do Funil
        </p>
      </div>

      <div className="w-full h-full flex items-center">
        <Plot
          className="w-full"
          data={[
            {
              x: graph.map((item) => item.title),
              y: graph.map((item) => Number(item._count)),
              text: graph.map((item) => item._count),
              marker: {
                color: generateHexColor(graph.length),
              },
              type: 'bar',
            },
          ]}
          config={{ displayModeBar: false, responsive: true }}
          layout={{
            height: 200,
            autosize: true,
            margin: { l: 40, r: 20, b: 80, t: 28 },
            font: {
              size: 10,
            },
            xaxis: {
              showgrid: false,
            },
            yaxis: {
              showgrid: false,
              range: [0, 4],
              autorange:
                graph.reduce((accu, curr) => accu + Number(curr._count), 0) !==
                0,
              tickmode:
                graph.reduce(
                  (a, b) => Math.max(a, Number(b._count)),
                  -Infinity
                ) <= 2
                  ? 'linear'
                  : 'auto',
              tick0: 0,
              tickformat: ' ,d',
              //   autorange: true,
            },
          }}
        />
      </div>
    </>
  ) : (
    <div className="flex items-center justify-center h-52">
      <Spinner />
    </div>
  );
};

export default OpportunityStepsGraph;
