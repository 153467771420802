import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { date, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MdOutlineCancel } from 'react-icons/md';
import { BsFiletypePdf, BsFiletypeXml } from 'react-icons/bs';
import format from 'date-fns/format';
import {
  Button,
  Input,
  Modal,
  SelectComponent,
  Spinner,
  TextArea,
} from '../../../components';
import { ModalProps } from '../../../components/Modal/Modal';
import { ApiExpenses } from '../api';
import { EconomicActivity } from '../../../types';
import maskHelper from '../../../helpers/mask.helper';
import { ToastNotify } from '../../contract/components/dependents/Toast/toastnotify';
import { FinancialProps } from '../types';
import CancelDialog from './canceDialog';

export interface RequestServiceInvoiceModalProps
  extends Omit<ModalProps, 'title' | 'children'> {
  account: FinancialProps;
}

const checkPercentageType = (value: string | undefined | null) => {
  if (!value) return false;
  const valueAsNumber = Number(value);
  return (
    !Number.isNaN(valueAsNumber) && valueAsNumber >= 0 && valueAsNumber <= 100
  );
};
const schema = object().shape({
  emissionDate: date().required(),
  servicesAmount: string().matches(
    /^R\$ \d{1,3}(\.\d{3})*,\d{2}$/,
    'O valor da nota fiscal deve ser um valor válido'
  ),
  issAmountWithheldPercentage: string()
    .nullable()
    .test(
      'is-valid-percentage',
      'O valor deve ser uma porcentagem válida entre 1 e 100',
      checkPercentageType
    ),
  cofinsAmountWithheldPercentage: string().test(
    'is-valid-percentage',
    'O valor deve ser uma porcentagem válida entre 1 e 100',
    checkPercentageType
  ),
  inssAmountWithheldPercentage: string().test(
    'is-valid-percentage',
    'O valor deve ser uma porcentagem válida entre 1 e 100',
    checkPercentageType
  ),
  irAmountWithheldPercentage: string().test(
    'is-valid-percentage',
    'O valor deve ser uma porcentagem válida entre 1 e 100',
    checkPercentageType
  ),
  csllAmountWithheldPercentage: string().test(
    'is-valid-percentage',
    'O valor deve ser uma porcentagem válida entre 1 e 100',
    checkPercentageType
  ),
  pisAmountWithheldPercentage: string().test(
    'is-valid-percentage',
    'O valor deve ser uma porcentagem válida entre 1 e 100',
    checkPercentageType
  ),
  customerServiceId: number().required(),
});
const getPercentageValue = (totalValue: any, percentage: any) =>
  maskHelper.formatMoeda(
    (totalValue && percentage ? +totalValue * +percentage : 0) / 100
  );
const BOOLEAN_OPTIONS = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];
const RequestServiceInvoiceModal: FC<RequestServiceInvoiceModalProps> = ({
  setIsOpen,
  ...props
}) => {
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [loadingServiceInvoiceDetail, setLoadingServiceInvoiceDetail] =
    useState(Boolean(props.account.nfe_id));
  const [serviceInvoiceDetail, setServiceInvoiceDetail] = useState<Record<
    string,
    any
  > | null>(null);
  const [loadingInvoiceAction, setLoadingInvoiceAction] = useState(false);
  const [loadingRequestServiceInvoice, setLoadingRequestServiceInvoice] =
    useState(false);
  const [economicActivities, setEconomicActivities] = useState<
    EconomicActivity[]
  >([]);
  const [showMore, setShowMore] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    // setError,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      emissionDate: new Date().toISOString().slice(0, 10),
      servicesAmount: maskHelper
        .formatMoeda(+props.account.value)
        ?.replace(/\s/, ' '),
      canRetainISS: false,
      issAmountWithheldPercentage: 0,
      cofinsAmountWithheldPercentage: 0,
      inssAmountWithheldPercentage: 0,
      irAmountWithheldPercentage: 0,
      csllAmountWithheldPercentage: 0,
      pisAmountWithheldPercentage: 0,
      customerServiceId: 0,
      additionalInformation: '',
      issAmountWithheld: '0',
    },
    disabled: Boolean(props.account.nfe_id),
    resolver: yupResolver(schema),
  });
  // const issAmountWithheld = watch('issAmountWithheld');
  const canRetainISS = watch('canRetainISS');
  const cofinsAmountWithheldPercentage = watch(
    'cofinsAmountWithheldPercentage'
  );
  const inssAmountWithheldPercentage = watch('inssAmountWithheldPercentage');
  const irAmountWithheldPercentage = watch('irAmountWithheldPercentage');
  const csllAmountWithheldPercentage = watch('csllAmountWithheldPercentage');
  const pisAmountWithheldPercentage = watch('pisAmountWithheldPercentage');
  const servicesAmount = Number(
    watch('servicesAmount')
      ?.replaceAll?.('.', '')
      .replace(',', '.')
      .replace(/R\$\s/, '')
  );

  const getClientEconomicActivities = useCallback(() => {
    ApiExpenses.getClientEconomicActivities({
      id: props.account.client_id ?? props.account.contract?.client_id,
    } as any).then((response) => {
      setEconomicActivities(response.data);
      const mainService = response.data.find((e) => e.isDefault);
      if (mainService && !props.account.nfe_id) {
        setValue('customerServiceId', mainService.id);
        setValue('issAmountWithheldPercentage', mainService.issRate ?? 0);
        setValue(
          'additionalInformation',
          mainService.defaultInvoiceDescription
        );
      }
    });
  }, []);

  useEffect(() => {
    getClientEconomicActivities();
  }, [getClientEconomicActivities]);

  const loadServiceInvoiceDetails = useCallback(() => {
    if (!props.account.nfe_id || !economicActivities.length) return;
    setLoadingServiceInvoiceDetail(true);
    ApiExpenses.getServiceInvoiceById(props.account.nfe_id)
      .then((response) => {
        setValue('emissionDate', response?.data?.issuedOn?.slice(0, 10));
        setValue(
          'servicesAmount',
          String(response?.data?.servicesAmount) ?? '0'
        );
        setValue('canRetainISS', response?.data?.canRetainISS);
        setValue(
          'issAmountWithheldPercentage',
          (response?.data?.issRate ?? 0) * 100
        );
        setValue(
          'cofinsAmountWithheldPercentage',
          response?.data?.servicesAmount && response?.data?.cofinsAmountWithheld
            ? (response.data.cofinsAmountWithheld /
                response.data.servicesAmount) *
                100
            : 0
        );
        setValue(
          'inssAmountWithheldPercentage',
          response?.data?.servicesAmount && response?.data?.inssAmountWithheld
            ? (response.data.inssAmountWithheld /
                response.data.servicesAmount) *
                100
            : 0
        );
        setValue(
          'irAmountWithheldPercentage',
          response?.data?.servicesAmount && response?.data?.irAmountWithheld
            ? (response.data.irAmountWithheld / response.data.servicesAmount) *
                100
            : 0
        );
        setValue(
          'csllAmountWithheldPercentage',
          response?.data?.servicesAmount && response?.data?.csllAmountWithheld
            ? (response.data.csllAmountWithheld /
                response.data.servicesAmount) *
                100
            : 0
        );
        setValue(
          'pisAmountWithheldPercentage',
          response?.data?.servicesAmount && response?.data?.pisAmountWithheld
            ? (response.data.pisAmountWithheld / response.data.servicesAmount) *
                100
            : 0
        );
        setValue('customerServiceId', response?.data?.customerServiceId);
        setValue(
          'additionalInformation',
          response?.data?.additionalInformation
        );
        setValue(
          'customerServiceId',
          economicActivities.find(
            (e) => e.cityServiceCodeId === response.data?.cityServiceCode
          )?.id!
        );
        setServiceInvoiceDetail(response?.data || {});
      })
      .finally(() => setLoadingServiceInvoiceDetail(false));
  }, [props.account.nfe_id, economicActivities]);

  useEffect(() => {
    loadServiceInvoiceDetails();
  }, [loadServiceInvoiceDetails]);

  const economicActivitiesOptions = useMemo(
    () =>
      economicActivities.map((economicActivity) => ({
        label: economicActivity.cityServiceDescription!,
        value: economicActivity.id,
      })),
    [economicActivities]
  );
  const { notify } = ToastNotify();
  const onSubmit = async (data: any) => {
    const serviceAmountAsNumber = Number(
      data.servicesAmount
        .replaceAll('.', '')
        .replace(',', '.')
        .replace(/R\$\s/, '')
    );
    const issAmountWithheldAsNumber = Number(
      data.issAmountWithheld
        .replaceAll('.', '')
        .replace(',', '.')
        .replace(/R\$\s/, '')
    );
    const cityServiceCode = economicActivities.find(
      (e) => e.id === +data.customerServiceId
    );
    const serviceInvoiceData = {
      customerId: props.account.client_id ?? props.account.contract?.client_id,
      cofinsAmountWithheld: Number(
        getPercentageValue(
          serviceAmountAsNumber,
          +data.cofinsAmountWithheldPercentage
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      cityServiceCode: cityServiceCode?.cityServiceCodeId,
      description: cityServiceCode?.defaultInvoiceDescription ?? '',
      customerServiceId: data.customerServiceId,
      inssAmountWithheld: Number(
        getPercentageValue(
          serviceAmountAsNumber,
          +data.inssAmountWithheldPercentage
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      irAmountWithheld: Number(
        getPercentageValue(
          serviceAmountAsNumber,
          +data.irAmountWithheldPercentage
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      csllAmountWithheld: Number(
        getPercentageValue(
          serviceAmountAsNumber,
          +data.csllAmountWithheldPercentage
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      pisAmountWithheld: Number(
        getPercentageValue(
          serviceAmountAsNumber,
          +data.pisAmountWithheldPercentage
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      servicesAmount: serviceAmountAsNumber,
      issRate: +(data.issAmountWithheldPercentage ?? 0) / 100,
      issTaxAmount: Number(
        getPercentageValue(
          data.servicesAmount ?? 0,
          data.issAmountWithheldPercentage ?? 0
        )
          .replaceAll('.', '')
          .replace(',', '.')
          .replace(/R\$\s/, '')
      ),
      additionalInformation: data.additionalInformation,
      issAmountWithheld: issAmountWithheldAsNumber,
    };
    setLoadingRequestServiceInvoice(true);
    const response = await ApiExpenses.requestServiceInvoice(
      props.account.id,
      serviceInvoiceData
    );

    if (response?.ok) {
      notify({
        message: 'Nota fiscal emitida com sucesso',
        type: 'Success',
      });
      setIsOpen(false);
    } else {
      notify({
        message:
          typeof response === 'string'
            ? response?.replace(/validation|"/g, '')
            : 'Houve um erro inesperado.',
        type: 'Error',
      });
    }

    setLoadingRequestServiceInvoice(false);
  };
  const onClickXmLButton = async () => {
    try {
      setLoadingInvoiceAction(true);
      const response = await ApiExpenses.getServiceInvoiceXMl(
        props.account.nfe_id!
      );
      if (typeof response === 'string') {
        notify({
          message: 'Não foi possível baixar o xml da nota fiscal',
          type: 'Error',
        });
        return;
      }
      const blob = new Blob([response], { type: 'application/xml' });
      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'file.xml'; // Set the file name for the download

      // Trigger the download by simulating a click
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);
    } catch (error) {
      notify({
        message: 'Não foi possível baixar o xml da nota fiscal',
        type: 'Error',
      });
    } finally {
      setLoadingInvoiceAction(false);
    }
  };

  const onClickPDFButton = async () => {
    try {
      setLoadingInvoiceAction(true);
      const response = await ApiExpenses.getServiceInvoicePDF(
        props.account.nfe_id!
      );
      if (typeof response === 'string') {
        notify({
          message: 'Não foi possível baixar o pdf da nota fiscal',
          type: 'Error',
        });
        return;
      }
      const blob = new Blob([response], { type: 'application/pdf' });
      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'file.pdf'; // Set the file name for the download

      // Trigger the download by simulating a click
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);
    } catch (error) {
      notify({
        message: 'Não foi possível baixar o xml da nota fiscal',
        type: 'Error',
      });
    } finally {
      setLoadingInvoiceAction(false);
    }
  };
  const onClickCancelButton = async () => {
    try {
      setLoadingInvoiceAction(true);
      const response = await ApiExpenses.cancelServiceInvoice(
        props.account.nfe_id!
      );
      if (typeof response === 'string') {
        notify({
          message: response || 'Não foi possível cancelar a nota fiscal',
          type: 'Error',
        });
        return;
      }
      notify({
        message: 'Nota fiscal cancelada com sucesso',
        type: 'Success',
      });
      setConfirmCancel(false);
      loadServiceInvoiceDetails();
    } catch (error) {
      notify({
        message: 'Não foi possível cancelar a nota fiscal',
        type: 'Error',
      });
    } finally {
      setLoadingInvoiceAction(false);
    }
  };

  return (
    <>
      <Modal
        title="Emitir nota fiscal"
        size="4xlarge"
        minHeight="min-h-[735px]"
        setIsOpen={setIsOpen}
        {...props}
      >
        {loadingServiceInvoiceDetail && (
          <div className="flex justify-center items-center">
            <Spinner /> Carregando dados da nota fiscal...
          </div>
        )}
        {!loadingServiceInvoiceDetail && (
          <div className="flex flex-col flex-1">
            <div>
              Cliente:{' '}
              <strong>
                {props.account?.client?.name ??
                  props.account?.contract?.client_name}
              </strong>
            </div>
            {serviceInvoiceDetail?.status === 'Cancelled' && (
              <div className="text-red mt-2">
                Essa nota fiscal foi cancelada no dia{' '}
                <strong>
                  {format(
                    new Date(serviceInvoiceDetail?.cancelledOn),
                    "d/MM/yyyy 'às' HH:mm"
                  )}
                </strong>
              </div>
            )}
            <form
              className="flex flex-col gap-4 justify-between flex-1 mt-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Input
                  disabled={Boolean(props.account.nfe_id)}
                  {...register('emissionDate')}
                  variant="outline-orange"
                  title="Data de emissão"
                  errorMensage={errors.emissionDate?.message}
                  type="date"
                />
                <div className="grid grid-cols-2 gap-2">
                  <Controller
                    name="customerServiceId"
                    control={control}
                    render={({ field }) => (
                      <SelectComponent
                        title="Serviço"
                        {...field}
                        // options={LEGAL_NATURE_OPTIONS}
                        options={economicActivitiesOptions}
                        value={economicActivitiesOptions.find(
                          (o) => o.value === field.value
                        )}
                        onChange={(option: { value: string }) => {
                          field.onChange(option.value);
                          const economicActivity = economicActivities.find(
                            (e) => String(e.id) === String(option.value)
                          );
                          setValue(
                            'issAmountWithheldPercentage',
                            economicActivity?.issRate ?? 0
                          );
                          setValue(
                            'additionalInformation',
                            economicActivity?.defaultInvoiceDescription ?? ''
                          );
                        }}
                        errorMensage={
                          errors.customerServiceId?.message as string
                        }
                      />
                    )}
                  />
                  <Input
                    disabled={Boolean(props.account.nfe_id)}
                    {...register('issAmountWithheldPercentage')}
                    errorMensage={
                      errors.issAmountWithheldPercentage?.message as string
                    }
                    variant="outline-orange"
                    title="Alíquota ISS"
                    icon={<>%</>}
                    // borderDefault="border-[#ddd]"
                    min={0}
                    max={100}
                    type="string"
                    step={0.01}
                    classNameDiv="mt-2"
                  />
                </div>
                <TextArea
                  {...register('additionalInformation')}
                  disabled={Boolean(props.account.nfe_id)}
                  title="Descrição do serviço:"
                  onChange={(e) =>
                    setValue('additionalInformation', e.target.value)
                  }
                />
                <Controller
                  name="servicesAmount"
                  control={control}
                  render={({ field }) => (
                    <Input
                      disabled={Boolean(props.account.nfe_id)}
                      value={field.value}
                      variant="outline-orange"
                      title="Valor da nota fiscal (R$)"
                      onChange={(e) => {
                        const formattedValue = maskHelper.formatCurrencyInput(
                          e.target.value
                        );
                        e.target.value = formattedValue;
                        field.onChange(formattedValue);
                      }}
                      type="string"
                      errorMensage={errors.servicesAmount?.message as string}
                      step={0.01}
                    />
                  )}
                />
                <div className="grid grid-cols-2 gap-2">
                  <Controller
                    name="canRetainISS"
                    control={control}
                    render={({ field }) => (
                      <SelectComponent
                        title="Deve reter ISS?"
                        {...field}
                        options={BOOLEAN_OPTIONS}
                        value={BOOLEAN_OPTIONS.find(
                          (o) => o.value === field.value
                        )}
                        onChange={(option: { value: string }) => {
                          field.onChange(option.value);
                          if (!option.value) {
                            setValue(
                              'issAmountWithheld',
                              maskHelper.formatMoeda('0')
                            );
                          }
                        }}
                        errorMensage={errors.canRetainISS?.message as string}
                      />
                    )}
                  />
                  <Controller
                    name="issAmountWithheld"
                    control={control}
                    render={({ field }) => (
                      <Input
                        disabled={
                          Boolean(props.account.nfe_id) || !canRetainISS
                        }
                        value={field.value}
                        required={canRetainISS}
                        variant="outline-orange"
                        title="Valor retido do ISS"
                        onChange={(e) => {
                          const formattedValue = maskHelper.formatCurrencyInput(
                            e.target.value
                          );
                          e.target.value = formattedValue;
                          field.onChange(formattedValue);
                        }}
                        type="string"
                        errorMensage={
                          errors.issAmountWithheld?.message as string
                        }
                        step={0.01}
                      />
                    )}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => setShowMore((prev) => !prev)}
                  >
                    <strong>Mais informações {showMore ? '-' : '+'}</strong>
                  </button>
                  {showMore && (
                    <div className="grid grid-cols-2 gap-2">
                      <Input
                        disabled={Boolean(props.account.nfe_id)}
                        {...register('cofinsAmountWithheldPercentage')}
                        errorMensage={
                          errors.cofinsAmountWithheldPercentage
                            ?.message as string
                        }
                        variant="outline-orange"
                        title="COFINS"
                        icon={<>%</>}
                        min={0}
                        max={100}
                        type="string"
                        step={0.01}
                      />
                      <Input
                        disabled={Boolean(props.account.nfe_id)}
                        variant="outline-orange"
                        title="Valor COFINS"
                        icon={<>R$</>}
                        value={getPercentageValue(
                          servicesAmount,
                          cofinsAmountWithheldPercentage
                        )}
                        readOnly
                        type="string"
                        step={0.01}
                      />
                      <Input
                        disabled={Boolean(props.account.nfe_id)}
                        {...register('csllAmountWithheldPercentage')}
                        errorMensage={
                          errors.csllAmountWithheldPercentage?.message as string
                        }
                        variant="outline-orange"
                        title="CSLL"
                        icon={<>%</>}
                        min={0}
                        max={100}
                        type="string"
                        step={0.01}
                      />
                      <Input
                        disabled={Boolean(props.account.nfe_id)}
                        variant="outline-orange"
                        title="Valor CSLL"
                        value={getPercentageValue(
                          servicesAmount,
                          csllAmountWithheldPercentage
                        )}
                        icon={<>R$</>}
                        type="string"
                        step={0.01}
                      />
                      <Input
                        disabled={Boolean(props.account.nfe_id)}
                        {...register('inssAmountWithheldPercentage')}
                        errorMensage={
                          errors.inssAmountWithheldPercentage?.message as string
                        }
                        variant="outline-orange"
                        title="INSS"
                        icon={<>%</>}
                        min={0}
                        max={100}
                        type="string"
                        step={0.01}
                      />
                      <Input
                        disabled={Boolean(props.account.nfe_id)}
                        variant="outline-orange"
                        value={getPercentageValue(
                          servicesAmount,
                          inssAmountWithheldPercentage
                        )}
                        title="Valor INSS"
                        icon={<>R$</>}
                        type="string"
                        step={0.01}
                      />
                      <Input
                        disabled={Boolean(props.account.nfe_id)}
                        {...register('irAmountWithheldPercentage')}
                        errorMensage={
                          errors.irAmountWithheldPercentage?.message as string
                        }
                        variant="outline-orange"
                        title="IR"
                        icon={<>%</>}
                        min={0}
                        max={100}
                        type="string"
                        step={0.01}
                      />
                      <Input
                        disabled={Boolean(props.account.nfe_id)}
                        value={getPercentageValue(
                          servicesAmount,
                          irAmountWithheldPercentage
                        )}
                        variant="outline-orange"
                        title="Valor IR"
                        icon={<>R$</>}
                        type="string"
                        step={0.01}
                      />
                      <Input
                        disabled={Boolean(props.account.nfe_id)}
                        {...register('pisAmountWithheldPercentage')}
                        errorMensage={
                          errors.pisAmountWithheldPercentage?.message as string
                        }
                        variant="outline-orange"
                        title="PIS"
                        icon={<>%</>}
                        min={0}
                        max={100}
                        type="string"
                        step={0.01}
                      />
                      <Input
                        disabled={Boolean(props.account.nfe_id)}
                        value={getPercentageValue(
                          servicesAmount,
                          pisAmountWithheldPercentage
                        )}
                        variant="outline-orange"
                        title="Valor PIS"
                        icon={<>R$</>}
                        type="string"
                        step={0.01}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex gap-2 justify-center">
                {!serviceInvoiceDetail?.status && (
                  <Button
                    type="submit"
                    actionType="button-loading"
                    isLoading={loadingRequestServiceInvoice}
                    disabled={loadingRequestServiceInvoice}
                    className="flex items-center justify-center w-[200px]"
                  >
                    Emitir nota fiscal
                  </Button>
                )}
                {serviceInvoiceDetail?.status &&
                  serviceInvoiceDetail?.status !== 'Cancelled' && (
                    <Button
                      type="button"
                      actionType="button-loading"
                      isLoading={loadingInvoiceAction}
                      disabled={loadingInvoiceAction}
                      className="flex items-center justify-center w-[200px] gap-2"
                      onClick={() => setConfirmCancel(true)}
                    >
                      <MdOutlineCancel size={16} />
                      Cancelar nota fiscal
                    </Button>
                  )}
                {['Issued', 'Created'].includes(
                  serviceInvoiceDetail?.status
                ) && (
                  <>
                    <Button
                      type="button"
                      actionType="button-loading"
                      isLoading={loadingInvoiceAction}
                      disabled={loadingInvoiceAction}
                      className="flex items-center justify-center w-[200px] gap-2"
                      onClick={onClickXmLButton}
                    >
                      <BsFiletypeXml size={16} />
                      Baixar XML
                    </Button>
                    <Button
                      type="button"
                      actionType="button-loading"
                      isLoading={loadingInvoiceAction}
                      disabled={loadingInvoiceAction}
                      className="flex items-center justify-center w-[200px] gap-2"
                      onClick={onClickPDFButton}
                    >
                      <BsFiletypePdf />
                      Baixar PDF
                    </Button>
                  </>
                )}
              </div>
            </form>
          </div>
        )}
      </Modal>
      <CancelDialog
        open={confirmCancel}
        setIsOpen={() => {
          if (loadingInvoiceAction) return;
          setConfirmCancel(false);
        }}
        loading={loadingInvoiceAction}
        onConfirm={onClickCancelButton}
      />
    </>
  );
};

export default RequestServiceInvoiceModal;
