import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../styles/textEditor.css';
import { PiSignatureThin } from 'react-icons/pi';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import {
  Card,
  DropdownIcons,
  Input,
  SelectComponent,
  Spinner,
} from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { contractProps, filterPropsContract } from '../types';
import maskHelper from '../../../helpers/mask.helper';
import { useFetchContractsList, useFetchGalaxyMethods } from '../hooks';
import SearchContract from '../components/filterContract';
import { useFetchClientDropdown } from '../../clients/hooks';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { useFetchUsersCompanie } from '../../users/hooks';
import SendWhatsApp from '../components/sendWhatsapp';
import InvoiceContract from '../components/invoiceContract';
import CancelContract from '../components/cancelContract';
import RegisterContract from '../components/registerContract';
import { useFetchPlansAccount } from '../../chartAccounts/hooks';
import SendEmailModal from '../components/email';
import { ApiContract } from '../api';
import { useFetchAllProductsActive } from '../../product/hooks';
import MainPagePagination from '../../main/components/mainPage/mainPageWithPagination';
import ReopenContract from '../components/reopenContract';
import { ToastNotify } from '../../../components/Toast/toast';
import { useFetchOfferDropdown } from '../../offer/hooks';
import { useFetchSegment } from '../../segment/hooks';
import SignContractModal from '../components/signContractModal';
import { getStatusStyle } from '../components/table/status';
import { useDigitalSignaturedCreditContext } from '../../DigitalSignatureCredit/dashboard/context';
import {
  calContractValue,
  calcMonths,
  dateFilterOptions,
  downloadPdf,
  getPaymentLink,
  getRequestSignButtonTitle,
  getSignColor,
  getStatusMessenger,
  getStyledDotMonth,
  hasOutDateFin,
  statusFormat,
} from '../utils/index';
import ChangeDueDate from '../components/changeDueDate';
import AlertOutDateFinContract from '../components/alertHasOutDateFin';
import { tokenModel } from '../../../types';
import { alertGalaxAccount } from '../../../hooks/globalAtom';

const ContractPage: React.FC = () => {
  const { id } = useParams();
  const [, setAlertGalaxAccount] = useAtom(alertGalaxAccount);
  const signatureCreditContext = useDigitalSignaturedCreditContext();
  const { notify } = ToastNotify();
  const accountProps: tokenModel = getTokenAccountInformation();
  const { SegmentList } = useFetchSegment('', accountProps.companie_id);
  const optionsSegment = SegmentList.map((item) => ({
    label: item.title,
    value: item.title,
  }));
  const [modalFilterContract, setModalFilterContract] = useState(false);
  const [modalInvoiceContract, setModalInvoiceContract] = useState(false);
  const [modalCancelContract, setModalCancelContract] = useState(false);
  const [isLoadingPDF, setIsLoadingPDF] = useState(-1);
  const [modalEmail, setModalEmail] = useState(false);
  const [registerPage, setRegisterPage] = useState({
    title: 'Dados Pessoais',
    page: 0,
    isCreate: true,
  });
  const [selectedContract, setSelectedContract] =
    useState<contractProps | null>(null);
  const [modalWhatsApp, setModalWhatsApp] = useState({
    open: false,
    message: '',
    number: '',
  });

  const [showReopenContract, setShowReopenContract] = useState(false);

  const [filterDate, setFilterDate] = useState(`this_month`);
  const [startData, setStartData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalCreateUpdateContract, setModalCreateUpdateContract] =
    useState(false);
  const [filterModalContract, setFilterModalContract] =
    useState<filterPropsContract>({
      offerId: [],
      clientId: [],
      companieUserPromoterId:
        accountProps.profile_id === 5 ? [accountProps.id] : [],
      order: 'created_at_desc',
      status: [],
      date_start: '',
      date_end: '',
      filter: filterDate,
      skip: startData,
      take: 10,
    });
  const [searchInput, setSearchInput] = useState('');
  const [showChangeDueDate, setShowChangeDueDate] = useState(false);
  const [modalHasOutDateFin, setModalHasOutDateFin] = useState(false);

  const [key, setKey] = useState('');
  const [contractData, setContractData] = useState<contractProps | null>(null);
  const [contractDataIcons, setContractDataIcons] =
    useState<contractProps | null>(null);
  const { methods } = useFetchGalaxyMethods();
  const [datePicker, setDatePicker] = useState<{
    open: boolean;
    start?: Date;
    end?: Date;
  }>({
    open: false,
  });

  const { UsersList } = useFetchUsersCompanie('', accountProps.companie_id);

  const { ContractsList, isLoadingList, fetchContractList } =
    useFetchContractsList(key, filterModalContract, searchInput);

  const { PlanList } = useFetchPlansAccount('');

  const [offerInput, setOfferInput] = useState('');
  const { OfferListDropdown, isLoadingOfferDropdownList } =
    useFetchOfferDropdown(offerInput);

  const userOptions = UsersList.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const offerOptions = OfferListDropdown.data.map((item) => ({
    label: `${item.person_name} - ${item.person_whatsapp} - ${item.person_email}`,
    value: item.id,
  }));

  const { ProductsList } = useFetchAllProductsActive('key');
  const productOptions = ProductsList.map((item) => ({
    value: item.id,
    label: item.title,
    price: item.value,
  }));

  const [inputClient, setInputClient] = useState('');
  const { ClientsList, isLoadingClientsDropDown } = useFetchClientDropdown(
    '',
    0,
    100000000,
    inputClient
  );
  const clientsOptions = ClientsList.data.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const columns = [
    {
      name: 'Cliente',
      key: 'client_name',
      selector: (row: contractProps) => row.client_name,
    },
    {
      name: 'CPF ou CNPJ',
      key: 'client_document',
      selector: (row: contractProps) => row.client_document,
      cell: (row: contractProps) =>
        row.client_document && maskHelper.cnpjCpf(row.client_document),
    },
    {
      name: 'Promotor da venda',
      key: 'companie_user_companie_userTocontract_companie_user_id_promoter.name',
      selector: (row: contractProps) =>
        row.companie_user_companie_userTocontract_companie_user_id_promoter
          .name,
    },
    {
      name: 'Número',
      key: 'created_at',
      selector: (row: contractProps) => maskHelper.formatDateDMY(row.number),
    },
    {
      name: 'Data do Contrato',
      key: 'created_at',
      selector: (row: contractProps) =>
        maskHelper.formatDateDMY(row.created_at),
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: contractProps) => getStatusStyle(row.status),
    },
    {
      name: 'Alteração de Status',
      key: 'status_date',
      selector: (row: contractProps) =>
        row.status_date ? maskHelper.formatDateDMY(row.status_date) : '',
      cell: (row: contractProps) =>
        row.status_date ? maskHelper.formatDateDMY(row.status_date) : '',
    },
    {
      name: 'FINAL (MESES)',
      key: '',
      cell: (row: contractProps) =>
        row.date_end ? getStyledDotMonth(row.date_end) : '',
    },
    {
      name: 'RESIDUAL',
      key: '',
      cell: (row: contractProps) =>
        row.date_end
          ? maskHelper.formatMoeda(
              calContractValue(row) * calcMonths(row.date_end)
            )
          : '',
    },
    {
      name: 'Ações',
      width: '90px',
      cell: (row: contractProps) => {
        const dropdownItems = [
          {
            label: 'Faturar Contrato',
            value: (
              <IconDictionary
                name="Money"
                title="Faturar Contrato"
                size={20}
                color={row.status === 'Aberto' ? '#2FAC66' : '#dadada'}
                className={
                  row.status !== 'Aberto' ? 'w-fit' : 'cursor-pointer w-fit'
                }
              />
            ),
            handleClick: () => {
              if (row.status === 'Aberto') {
                if (accountProps.companie.companie_id_galax === null) {
                  setAlertGalaxAccount(true);
                  return;
                }
                setContractDataIcons(row);
                setModalInvoiceContract(true);
              }
            },
            disabled: row.status !== 'Aberto',
          },
          {
            label: 'Reabrir Contrato',
            value: (
              <IconDictionary
                title="Reabrir Contrato"
                name="reload"
                size={20}
                className={
                  row.status === 'Interrompido'
                    ? 'cursor-pointer w-fit text-purple-600 hover:scale-110 delay-75'
                    : 'cursor-default w-fit text-[#dadada]'
                }
              />
            ),
            handleClick: () => {
              if (row.status === 'Interrompido') {
                setContractDataIcons(row);
                setShowReopenContract(true);
              }
            },
            disabled: row.status !== 'Interrompido',
          },
          {
            label: 'Assinar Contrato',
            value: (
              <button
                className="border-none bg-transparent"
                type="button"
                name="Assinar contrato"
                title={getRequestSignButtonTitle(row)}
              >
                <PiSignatureThin size={20} color={getSignColor(row)} />
              </button>
            ),
            handleClick: () => {
              setSelectedContract(row);
            },
          },
          ...(accountProps.profile_id !== 5
            ? [
                {
                  label: 'Alterar Vencimento',
                  value: (
                    <IconDictionary
                      title="Alterar vencimento"
                      name="Calendario Editar"
                      size={20}
                      color={
                        row.status === 'Ativo' ||
                        row.status === 'Ag.Pagamento' ||
                        row.status === 'Inadimplente'
                          ? '#000000'
                          : '#dadada'
                      }
                      className={
                        row.status === 'Ativo' ||
                        row.status === 'Ag.Pagamento' ||
                        row.status === 'Inadimplente'
                          ? 'cursor-pointer w-fit hover:scale-110 delay-75'
                          : 'cursor-default w-fit'
                      }
                    />
                  ),
                  handleClick: () => {
                    setContractData(row);

                    if (
                      row.status === 'Ativo' ||
                      row.status === 'Ag.Pagamento' ||
                      row.status === 'Inadimplente'
                    ) {
                      if (hasOutDateFin(row.financial ?? [])) {
                        setModalHasOutDateFin(true);
                      } else {
                        setContractDataIcons(row);
                        setShowChangeDueDate(true);
                      }
                    }
                  },
                  disabled:
                    row.status !== 'Ativo' &&
                    row.status !== 'Ag.Pagamento' &&
                    row.status !== 'Inadimplente',
                },
              ]
            : []),
          {
            label: 'Cancelar Contrato',
            value: (
              <IconDictionary
                name="Cancelar"
                title="Cancelar Contrato"
                size={20}
                color={row.status === 'Cancelado' ? '#dadada' : '#DA3C3C'}
                className={
                  row.status === 'Cancelado' ? 'w-fit' : 'cursor-pointer w-fit'
                }
              />
            ),
            handleClick: () => {
              if (row.status !== 'Cancelado') {
                setContractDataIcons(row);
                setModalCancelContract(true);
              }
            },
            disabled: row.status === 'Cancelado',
          },
          {
            label: row.autentique_document_link
              ? 'Abrir documento'
              : 'Salvar como PDF',
            value:
              isLoadingPDF === row.id ? (
                <Spinner size="w-5 h-5" />
              ) : (
                <IconDictionary
                  title={
                    row.autentique_document_link
                      ? 'Abrir documento'
                      : 'Salvar como PDF'
                  }
                  name="PDF"
                  size={20}
                  className="cursor-pointer w-fit"
                />
              ),
            handleClick: () => {
              if (row.autentique_document_link) {
                window.open(row.autentique_document_link, '_blank');
                return;
              }
              downloadPdf(row.id, row.number, row.client_name, setIsLoadingPDF);
            },
          },
          {
            label: 'Enviar por Whatsapp',
            value: (
              <IconDictionary
                title="Enviar por Whatsapp"
                name="Whatsapp"
                size={20}
                className="cursor-pointer w-fit"
              />
            ),
            handleClick: () => {
              setModalWhatsApp({
                open: true,
                message: getStatusMessenger(
                  row.status,
                  row.accept,
                  row.id,
                  row.financial && row.financial.length > 0
                    ? getPaymentLink(row.financial, notify)
                    : '',
                  row.client_name,
                  accountProps.companie_id
                ),
                number: row.client_whatsapp,
              });
            },
          },
          {
            label: 'Enviar por E-mail',
            value: (
              <IconDictionary
                title="Enviar por e-mail"
                name="Email"
                size={20}
                className="cursor-pointer w-fit"
              />
            ),
            handleClick: () => {
              setContractDataIcons(row);
              setModalEmail(true);
            },
          },
        ];

        return (
          <div className="ml-1.5">
            <DropdownIcons
              components={dropdownItems}
              modalSide="bottom"
              modalAlign="end"
              linesSeparator={[5]}
            />
          </div>
        );
      },
    },
  ];

  const generateCsv = async () => {
    const res = await ApiContract.filterContracts({
      ...filterModalContract,
      status: filterModalContract.status.map((item) => item.value),
      clientId: filterModalContract.clientId.map((item) => item.value),
      offerId: filterModalContract.offerId.map((item) => item.value),
      take: 100000000,
    });
    if (typeof res !== 'string' && res.data) {
      const csvData = [
        [
          'Cliente',
          'Data do Contrato',
          'CPF ou CNPJ',
          'Promotor da Venda',
          'Status',
          'Número',
          'Nome (Cliente)',
          'Apelido (Cliente)',
          'Data de nascimento (Cliente)',
          'CPF/CNPJ (Cliente)',
          'Whatsapp (Cliente)',
          'E-mail (Cliente)',
          'RG (Cliente)',
          'Gênero (Cliente)',
          'Estado Civil (Cliente)',
          'Rua (Cliente)',
          'Número (Cliente)',
          'Complemento (Cliente)',
          'Bairro (Cliente)',
          'Cidade (Cliente)',
          'Estado (Cliente)',
          'CEP (Cliente)',
          'Parente Referência',
          'Telefone Celular (Parente)',
          'WhatsApp (Parente)',
          'Telefone Fixo (Parente)',
          'Cliente é responável financeiro?',
          'Tipo de Pessoa (Responsável Financeiro)',
          'Nome (Responsável Financeiro)',
          'CPF/CNPJ (Responsável Financeiro)',
          'E-mail Pessoa (Responsável Financeiro)',
          'Telefone (Responsável Financeiro)',
          'WhatsApp (Responsável Financeiro)',
          'Rua (Responsável Financeiro)',
          'Número (Responsável Financeiro)',
          'Complemento (Responsável Financeiro)',
          'Bairro (Responsável Financeiro)',
          'Cidade (Responsável Financeiro)',
          'Estado (Responsável Financeiro)',
          'CEP (Responsável Financeiro)',
          'Data Status',
          'Total',
          'IP',
          'Data do aceite',
          'Hora do aceite',
        ],
        ...res.data.map((item) => [
          item.client_name,
          maskHelper.formatDateDMY(item.created_at ?? ''),
          maskHelper.cnpjCpf(item.client_document ?? ''),
          UsersList.find((user) => user.id === item.companie_user_id_promoter)
            ?.name,
          statusFormat[item.status],
          item.number,
          item.client_name,
          item.client_name_fantasy ?? '',
          maskHelper.formatDateDMY(item.client_birth ?? ''),
          maskHelper.cnpjCpf(item.client_document ?? ''),
          maskHelper.phone(item.client_whatsapp ?? ''),
          item.client_email,
          item.client_rg,
          item.client_gender,
          item.client_civil_status,
          item.client_address,
          item.client_address_number,
          item.client_address_complement,
          item.client_address_province,
          item.client_address_city,
          item.client_address_state,
          item.client_address_zipcode,
          item.client_agent_legal,
          maskHelper.phone(item.client_agent_celular ?? ''),
          maskHelper.phone(item.client_agent_whatsapp ?? ''),
          maskHelper.phone(item.client_agent_fixo ?? ''),
          item.is_financial_responsible_client === true ? 'Sim' : 'Não',
          item.resp_financial_type,
          item.resp_financial_name,
          maskHelper.cnpjCpf(item.resp_financial_document ?? ''),
          item.resp_financial_email,
          maskHelper.phone(item.resp_financial_phone ?? ''),
          maskHelper.phone(item.resp_financial_whatsapp ?? ''),
          item.resp_financial_address,
          item.resp_financial_number,
          item.resp_financial_complement,
          item.resp_financial_province,
          item.resp_financial_city,
          item.resp_financial_state,
          item.resp_financial_zipcode,
          maskHelper.formatDateDMY(item.status_date ?? ''),
          Number(item.total ?? ''),
          item.accept_ip,
          maskHelper.formatDateDMY(item.accept_date ?? ''),
          item.accept_date?.slice(11, 19),
        ]),
      ];
      return csvData;
    }
    return [[]];
  };

  useEffect(() => {
    setFilterModalContract({ ...filterModalContract, skip: startData });
  }, [startData]);

  const searchContracts = async (value: string) => {
    setCurrentPage(1);
    setFilterModalContract({ ...filterModalContract, skip: 0, take: 10 });
    setSearchInput(value);
  };

  useEffect(() => {
    const fetch = async () => {
      const contract = await ApiContract.getContractById(Number(id));

      if (typeof contract !== 'string') {
        setContractData(contract);
        setRegisterPage({
          isCreate: false,
          title: 'Hist. Pagamentos',
          page: 6,
        });
        setModalCreateUpdateContract(true);
      }
    };
    if (id) fetch();
  }, []);

  return (
    <>
      <MainPagePagination
        search={searchContracts}
        currentPageTable={currentPage}
        setCurrentPageTable={setCurrentPage}
        startDate={setStartData}
        totalRegisters={ContractsList.total}
        isLoading={isLoadingList}
        column={columns}
        title="Contratos"
        filename="contrato"
        filterTable={() => ContractsList.data}
        FilterComponent={
          <Card className="flex-col col-span-2 w-full pt-5 px-4 pb-3.5 gap-4">
            <strong>Pesquisar</strong>
            <div className="flex flex-col xl:flex-row gap-2">
              <div className="flex w-full flex-col sm:flex-row gap-2">
                <Input
                  value={searchInput}
                  onChange={(e) => searchContracts(e.target.value)}
                  icon={
                    <IconDictionary
                      name="Lupa"
                      size={18}
                      color="#8C8C8C"
                      className="mr-4"
                    />
                  }
                  placeholder="Pesquisar Contratos"
                  className="w-full"
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-1 sm:gap-3 w-full mt-2 md:mt-0 lg:w-fit">
                <SelectComponent
                  variant="outline-primary"
                  icon="Calendario"
                  value={dateFilterOptions.find(
                    (item) => item.value === filterDate
                  )}
                  className="w-full sm:w-48 lg:w-48 2xl:w-60"
                  options={dateFilterOptions}
                  closeMenuOnSelect
                  onChange={(e: any) => {
                    setFilterDate(e.value);
                    if (e.value !== 'other_period') {
                      setDatePicker({ open: false });
                      setFilterModalContract({
                        ...filterModalContract,
                        filter: e.value,
                        date_end: '',
                        date_start: '',
                        skip: 0,
                      });
                      setStartData(0);
                      setCurrentPage(1);
                    } else {
                      setDatePicker({ open: true });
                    }
                  }}
                  maxOptionsHeight="300px"
                />
                {datePicker.open && (
                  <div className="flex mb-1.5">
                    <DatePicker
                      openCalendarOnFocus
                      format="dd/MM/yyyy"
                      className="start_date_input"
                      value={datePicker.start}
                      onChange={(e: Date) => {
                        setDatePicker({ ...datePicker, start: e });
                        if (typeof datePicker.end !== 'undefined') {
                          setFilterModalContract({
                            ...filterModalContract,
                            filter: 'other_period',
                            date_end: maskHelper.formatDateYMD(
                              datePicker.end.toLocaleDateString('pt-br')
                            ),
                            date_start: maskHelper.formatDateYMD(
                              e?.toLocaleDateString('pt-br') ?? ''
                            ),
                          });
                        }
                      }}
                    />
                    <p className="bg-primary w-11 h-11 flex items-center justify-center text-xs text-white">
                      Até
                    </p>
                    <DatePicker
                      openCalendarOnFocus
                      format="dd/MM/yyyy"
                      className="end_date_input"
                      onChange={(e: Date) => {
                        setDatePicker({ ...datePicker, end: e });
                        if (typeof datePicker.start !== 'undefined') {
                          setFilterModalContract({
                            ...filterModalContract,
                            filter: 'other_period',
                            date_start: maskHelper.formatDateYMD(
                              datePicker.start.toLocaleDateString('pt-br')
                            ),
                            date_end: maskHelper.formatDateYMD(
                              e?.toLocaleDateString('pt-br') ?? ''
                            ),
                          });
                        }
                      }}
                      minDate={datePicker.start}
                      value={datePicker.end}
                    />
                  </div>
                )}
                <button
                  type="button"
                  className="xl:hidden w-12 h-11 rounded-lg md:place-self-start items-center justify-center cursor-pointer text-primary border-primary border-solid border"
                  onClick={() => setModalFilterContract(true)}
                >
                  <IconDictionary name="Filtro" size={16} />
                </button>
              </div>
              <button
                type="button"
                className="hidden xl:flex min-w-[44px] h-11 rounded-lg justify-center items-center cursor-pointer text-primary border-primary border-solid border"
                onClick={() => setModalFilterContract(true)}
              >
                <IconDictionary name="Filtro" size={16} />
              </button>
            </div>
          </Card>
        }
        setModalCreateUpdate={setModalCreateUpdateContract}
        setRegisterPage={() => {
          setContractData(null);
          setRegisterPage({ title: 'Dados Pessoais', page: 0, isCreate: true });
        }}
        onRowClick={(e: contractProps) => {
          setContractData(e);
          setRegisterPage({
            title: 'Dados Pessoais',
            page: 0,
            isCreate: false,
          });
          setModalCreateUpdateContract(true);
        }}
        objectReport={generateCsv}
        reportAndCreate
      />
      <SearchContract
        isLoadingDropdown={isLoadingOfferDropdownList}
        setInputOpportunity={setOfferInput}
        isLoadingClients={isLoadingClientsDropDown}
        setClientInput={setInputClient}
        profileId={accountProps.profile_id}
        restartPagination={() => {
          setStartData(0);
          setCurrentPage(1);
          setSearchInput('');
        }}
        period={filterDate}
        filters={filterModalContract}
        setFilter={setFilterModalContract}
        offerList={offerOptions}
        userOptions={userOptions}
        clientsOptions={clientsOptions}
        show={modalFilterContract}
        setShow={setModalFilterContract}
      />
      {modalWhatsApp.open === true && (
        <SendWhatsApp
          message={modalWhatsApp.message}
          setOpen={(e) => setModalWhatsApp({ ...modalWhatsApp, open: e })}
          show={modalWhatsApp.open}
          clientWhatsapp={modalWhatsApp.number}
        />
      )}

      <RegisterContract
        galaxyFormPayments={methods}
        optionsSegment={optionsSegment}
        productsOptions={productOptions}
        setContract={setContractData}
        PlanList={PlanList}
        usersOptions={userOptions}
        contract={contractData}
        reloadTable={setKey}
        registerPage={registerPage}
        setRegisterPage={setRegisterPage}
        setShow={setModalCreateUpdateContract}
        show={modalCreateUpdateContract}
      />
      <AlertOutDateFinContract
        show={modalHasOutDateFin}
        setShow={setModalHasOutDateFin}
        openCharge={() => {
          setModalHasOutDateFin(false);
          setRegisterPage({
            isCreate: false,
            title: 'Hist. Pagamentos',
            page: 6,
          });
          setModalCreateUpdateContract(true);
        }}
      />
      {contractDataIcons !== null && (
        <>
          {modalInvoiceContract === true && (
            <InvoiceContract
              contract={contractDataIcons}
              setShow={setModalInvoiceContract}
              show={modalInvoiceContract}
              reloadTable={() => setKey(`${Math.random()}`)}
            />
          )}
          <ReopenContract
            galaxy={
              contractDataIcons.financial?.pop() ?? {
                galax_pay_id_subs: -1,
                payment_link: '',
              }
            }
            close={() => setShowReopenContract(false)}
            open={showReopenContract}
            id={contractDataIcons.id}
            updateTable={() => setKey(`${Math.random()}`)}
          />
          <CancelContract
            PlanList={PlanList}
            installments={contractDataIcons.financial?.filter(
              (item) => item.status === 'Aberto' || item.status === 'Vencido'
            )}
            close={() => setModalCancelContract(false)}
            open={modalCancelContract}
            id={contractDataIcons.id}
            updateTable={() => setKey(`${Math.random()}`)}
          />
          <SendEmailModal
            contract={contractDataIcons}
            close={() => setModalEmail(false)}
            open={modalEmail}
            id={contractDataIcons.id}
            updateTable={() => setKey(`${Math.random()}`)}
          />
          <ChangeDueDate
            contract={contractDataIcons}
            setShow={setShowChangeDueDate}
            show={showChangeDueDate}
            reloadTable={() => setKey(`${Math.random()}`)}
          />
        </>
      )}
      <SignContractModal
        show={selectedContract != null}
        setOpen={() => setSelectedContract(null)}
        contract={selectedContract}
        onSuccessRequest={() => {
          setSelectedContract(null);
          fetchContractList();
          signatureCreditContext.loadSignatureCreditBalance();
        }}
      />
    </>
  );
};

export default ContractPage;
