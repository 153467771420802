import { baseApiPrivate } from '../../../../api/baseApi';

async function getTotalSales(
  companyId: number,
  funnelId: number,
  period: string,
  seller: string | number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalSales/${companyId}/${funnelId}/${period}?seller=${seller}`,
  });
}

async function getTotalPredicted(
  companyId: number,
  funnelId: number,
  period: string,
  seller: string | number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/totalPredicted/${companyId}/${funnelId}/${period}?seller=${seller}`,
  });
}

async function getReasonsGained(
  companyId: number,
  funnelId: number,
  period: string,
  seller: string | number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/reasonsGained/${companyId}/${funnelId}/${period}?seller=${seller}`,
  });
}

async function getReasonsLoss(
  companyId: number,
  funnelId: number,
  period: string,
  seller: string | number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/reasonsLoss/${companyId}/${funnelId}/${period}?seller=${seller}`,
  });
}

async function getSellers(
  companyId: number,
  funnelId: number,
  period: string,
  seller: number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/sellers/${companyId}/${funnelId}/${period}?seller=${seller}`,
  });
}

async function getOpportuniyByStep(
  companyId: number,
  funnelId: number,
  period: string,
  seller: number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/opportuniyByStep/${companyId}/${funnelId}/${period}?seller=${seller}`,
  });
}

async function getSalesChannel(
  companyId: number,
  funnelId: number,
  period: string,
  seller: number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/salesChannel/${companyId}/${funnelId}/${period}?seller=${seller}`,
  });
}

async function getSegments(
  companyId: number,
  funnelId: number,
  period: string,
  seller: number
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/segments/${companyId}/${funnelId}/${period}?seller=${seller}`,
  });
}

async function getSellersActive(companieId: number) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/activeSellers/${companieId}`,
  });
}

async function getTopProductsServices(
  companieId: number,
  funnelId: number,
  period: string,
  seller: string
) {
  return baseApiPrivate({
    method: 'GET',
    url: `companie/dashboard/topProductsOrServices/${companieId}/${funnelId}/${period}?seller=${seller}`,
  });
}

export const ApiDashboard = {
  getTotalPredicted,
  getReasonsGained,
  getSalesChannel,
  getSegments,
  getSellers,
  getReasonsLoss,
  getTotalSales,
  getOpportuniyByStep,
  getSellersActive,
  getTopProductsServices,
};
