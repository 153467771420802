/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../../../components';
import maskHelper from '../../../../../helpers/mask.helper';
import { ApiDashboard } from '../../api';

interface props {
  companyId: number;
  period: string;
  funnelId: any;
  seller: any;
}
const TopProductsServices: React.FC<props> = ({
  companyId,
  period,
  funnelId,
  seller,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const sellingProducts = await ApiDashboard.getTopProductsServices(
        companyId,
        funnelId,
        period,
        seller
      );
      if (typeof sellingProducts !== 'string') {
        setData(sellingProducts);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [period, funnelId, seller]);

  return !isLoading ? (
    <>
      <p className="font-medium text-xs uppercase">TOP 10 PRODUTOS/SERVIÇOS</p>
      {data.length === 0 ? (
        <div className="w-full h-64 lg:h-full flex items-center justify-center">
          <p className="text-xs">Nenhum dado encontrado</p>
        </div>
      ) : (
        <div className="flex flex-col mt-4">
          <div className="mb-4 grid grid-cols-3 w-full">
            <p className="text-xs font-medium col-span-1">Nome</p>
            <p className="text-xs font-medium mx-auto">Total</p>
            <p className="text-xs font-medium mx-auto">Valor</p>
          </div>
          {data.slice(0, 10).map((item) => (
            <React.Fragment key={item.product_id}>
              <div className="grid grid-cols-3 w-full justify-between">
                <p className="text-xs col-span-1">{item.title}</p>
                <p className="text-xs bg-green/20 rounded-lg col-span-1 p-1.5 mx-auto">
                  {Number(item.total_qty ?? '').toLocaleString('pt-br')}
                </p>
                <p className="text-xs bg-primary/20 col-span-1 rounded-lg p-1.5 mx-auto">
                  {maskHelper.formatMoeda(item.total_value ?? '')}
                </p>
              </div>
              <hr className="w-full h-px bg-[#dadada] my-2" />
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  ) : (
    <div className="flex items-center justify-center h-72">
      <Spinner />
    </div>
  );
};

export default TopProductsServices;
