import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  SelectComponent,
  Spinner,
} from '../../../../components';
import { reportFilter } from '../types';
import { getTokenAccountInformation } from '../../../../helpers/token.helper';

/* eslint-disable no-unused-vars */
interface registerModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  responseOptions: { label: string; value: number }[];
  userOptions: { label: string; value: number }[];
  salesChannelOptions: { label: string; value: number }[];
  opportunityList: { label: string; value: number }[];
  funnelId: number | null;
  funnelsOptions: { label: string; value: number }[];
  isLoading: boolean;
  setFilter: (e: reportFilter) => void;
  filters: reportFilter;
  setInputOpportunity: (e: string) => void;
  isLoadingDropdown: boolean;
  setCurrentPage: (e: number) => void;
  profileId: number;
  userId: number;
}

const ReportsOpportunity: React.FC<registerModalProps> = ({
  show,
  setShow,
  opportunityList,
  salesChannelOptions,
  responseOptions,
  userOptions,
  funnelId,
  funnelsOptions,
  isLoading,
  filters,
  setFilter,
  setCurrentPage,
  isLoadingDropdown,
  setInputOpportunity,
  profileId,
  userId,
}) => {
  const [isLoadingClear, setIsLoadingClear] = useState(false);

  const accountProps = getTokenAccountInformation();
  const schema = yup.object().shape({
    order: yup.string().required('Selecione uma opção'),
    status: yup.string().required('Selecione uma opção'),
  });

  const { formState, handleSubmit, setValue, reset, control } =
    useForm<reportFilter>({
      resolver: yupResolver(schema),
      defaultValues: {
        opportunityId: null,
        productId: [],
        salesChannelId: [],
        responses_win_lose_id: [],
        companieUserId: profileId === 5 ? [userId] : [],
        order: 'title_asc',
        status: 'Todos',
        funnelId,
      },
    });

  const orderOptions = [
    { value: 'title_asc', label: 'Alfabética crescente' },
    { value: 'title_desc', label: 'Alfabética decrescente' },
    { value: 'created_at_asc', label: 'Data crescente' },
    { value: 'created_at_desc', label: 'Data decrescente' },
  ];

  const statusOptions = [
    { value: 'Aberta', label: 'Aberta' },
    { value: 'Ganha', label: 'Ganha' },
    { value: 'Perdida', label: 'Perdida' },
    { value: 'Todos', label: 'Todos' },
  ];

  const Submit = async (e: reportFilter) => {
    setFilter({ ...e, skip: 0, take: 10 });
    setCurrentPage(1);
    setShow(false);
  };

  const ClearData = async () => {
    setIsLoadingClear(true);
    const defaultFilter: reportFilter = {
      opportunityId: null,
      productId: [],
      salesChannelId: [],
      responses_win_lose_id: [],
      companieUserId: profileId === 5 ? [userId] : [],
      order: 'title_asc',
      status: 'Todos',
      funnelId,
      skip: 0,
      take: 10,
    };
    setFilter(defaultFilter);
    reset(defaultFilter);
    setIsLoadingClear(false);
    setShow(false);
  };

  useEffect(() => {
    reset(filters);
  }, [show]);
  return (
    <Modal
      title="Procurar oportunidade"
      isOpen={show}
      setIsOpen={setShow}
      size="2xlarge"
      minHeight="min-h-[540px]"
    >
      <form
        className="flex flex-col sm:grid sm:grid-cols-2 gap-3"
        onSubmit={handleSubmit(Submit)}
      >
        <Controller
          control={control}
          name="order"
          render={({ field }) => (
            <SelectComponent
              title="Ordenar"
              options={orderOptions}
              value={orderOptions.filter((item) => field.value === item.value)}
              defaultValue={orderOptions[0]}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('order', e.value);
              }}
              errorMensage={formState.errors.order?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="status"
          render={({ field }) => (
            <SelectComponent
              title="Status"
              options={statusOptions}
              closeMenuOnSelect
              defaultValue={statusOptions[0]}
              value={statusOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              maxOptionsHeight="300px"
              onChange={(e: any) => {
                setValue('status', e.value);
              }}
              errorMensage={formState.errors.status?.message}
            />
          )}
        />
        <div className="col-span-2">
          <Controller
            control={control}
            name="companieUserId"
            render={({ field }) => (
              <SelectComponent
                disabled={profileId === 5}
                title="Usuários"
                options={userOptions}
                value={userOptions.filter((item) =>
                  field.value?.includes(item.value)
                )}
                isMulti
                isDisabled={accountProps.profile.title === 'Vendedor'}
                defaultValue={
                  accountProps.profile.title === 'Vendedor'
                    ? userOptions.find(
                        (option) => option.value === accountProps.id
                      )
                    : undefined
                }
                closeMenuOnSelect={false}
                onChange={(e: any[]) => {
                  setValue(
                    'companieUserId',
                    e.map((user) => user.value)
                  );
                }}
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name="responses_win_lose_id"
          render={({ field }) => (
            <SelectComponent
              value={responseOptions.filter((item) =>
                field.value.includes(item.value)
              )}
              hasDivision
              title="Respostas"
              options={responseOptions}
              isMulti
              closeMenuOnSelect={false}
              onChange={(e: any[]) => {
                setValue(
                  'responses_win_lose_id',
                  e.map((res) => res.value)
                );
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="funnelId"
          render={({ field }) => (
            <SelectComponent
              title="Funil"
              value={funnelsOptions.filter(
                (item) => field.value === item.value
              )}
              maxOptionsHeight="300px"
              options={funnelsOptions}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('funnelId', e.value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="opportunityId"
          render={({ field }) => (
            <SelectComponent
              value={field.value}
              title="Oportunidade"
              isLoading={isLoadingDropdown}
              onInputChange={setInputOpportunity}
              autoComplete
              isClearable
              maxOptionsHeight="120px"
              options={opportunityList}
              closeMenuOnSelect
              onChange={(e: any) => {
                setValue('opportunityId', e);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="salesChannelId"
          render={({ field }) => (
            <SelectComponent
              title="Canal de venda"
              maxOptionsHeight="120px"
              value={salesChannelOptions.filter((item) =>
                field.value?.includes(item.value)
              )}
              options={salesChannelOptions}
              closeMenuOnSelect
              isMulti
              onChange={(e: any[]) => {
                setValue(
                  'salesChannelId',
                  e.map((sale) => sale.value)
                );
              }}
            />
          )}
        />

        <div className="col-span-2 w-full flex gap-3 justify-center flex-col sm:flex-row">
          <Button
            disabled={isLoadingClear}
            variant="outline-primary"
            onClick={ClearData}
            actionType="button-loading"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            {isLoadingClear && (
              <div className="absolute right-0">
                <Spinner />
              </div>
            )}
            Limpar
          </Button>
          <Button
            disabled={isLoading}
            isLoading={!isLoadingClear && isLoading}
            actionType="button-loading"
            variant="primary-strong"
            type="submit"
            className="font-bold relative flex items-center justify-center mt-4 w-64"
          >
            Filtrar
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ReportsOpportunity;
