import React, { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { GrFormClose } from 'react-icons/gr';
import classNames from 'classnames';

export interface ModalProps {
  title: string | ReactNode;
  size?: 'medium' | '2xlarge' | '4xlarge' | '6xlarge' | '7xlarge';
  isOpen?: boolean;
  header?: boolean;
  minHeight?: string;
  /* eslint-disable no-unused-vars */
  setIsOpen: (e: boolean) => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  title,
  isOpen = false,
  header = true,
  minHeight = 'md:min-h-[600px]',
  setIsOpen,
  size = 'medium',
  children,
}) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-40" onClose={() => {}}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              autoFocus
              className={classNames(
                'w-full transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all mt-10 lg:mt-0',
                minHeight,
                {
                  'max-w-md': size === 'medium',
                  'max-w-2xl': size === '2xlarge',
                  'max-w-4xl': size === '4xlarge',
                  'max-w-6xl': size === '6xlarge',
                  'max-w-7xl': size === '7xlarge',
                }
              )}
            >
              {header && (
                <div className="flex justify-between items-center mb-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <GrFormClose
                    size={25}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setIsOpen(false)}
                  />
                </div>
              )}

              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default Modal;
