/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from '../../../../../components';
import { ApiContract } from '../../../../contract/api';
import { ContactOpportunity, OpportunityProps } from '../../../types';
import { ToastNotify } from '../../../../../components/Toast/toast';
import { dictionaryError } from '../../../../../helpers/utils.helper';
import { ApiOpportunity } from '../../../api';
import GeneralDataClient from '../../../../clients/components/register/components/generalData';
import { ClientProps } from '../../../../clients/types';
import { ApiClient } from '../../../../clients/api';
import phoneValidate from '../../../../../helpers/validations/phone.validate';
import cpfValidation from '../../../../../helpers/validations/cpf.validation';
import cnpjValidation from '../../../../../helpers/validations/cnpj.validation';
import { OfferProps } from '../../../../offer/types';
import { ApiOffer } from '../../../../offer/api';
import { ContactProps } from '../../../../contacts/types';
import ContactGeneralData from '../../../../contacts/components/register/components/generalData';
import { getTokenAccountInformation } from '../../../../../helpers/token.helper';
import ModalClientHasContract from './clientHasContract';
import { contractProps } from '../../../../contract/types';

interface props {
  show: boolean;
  isLoading: boolean;
  offer?: OfferProps;
  setIsLoading: (e: boolean) => void;
  updateOffer: (e: string) => void;
  setShow: (e: boolean) => void;
  closeOffer: (e: string) => Promise<any>;
  setState: (e: boolean) => void;
  personOpportunity: any;
  opportunityId: number;
  winRes: number;
}
const CreateContractModalAlert: React.FC<props> = ({
  show,
  isLoading,
  offer,
  personOpportunity,
  opportunityId,
  winRes,
  closeOffer,
  setShow,
  setIsLoading,
  updateOffer,
  setState,
}) => {
  const { notify } = ToastNotify();
  const navigate = useNavigate();
  const accountProps = getTokenAccountInformation();
  const [newContact, setNewContact] = useState<ContactProps | null>(null);
  const [modalClient, setModalClient] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(true);
  const [[modalHasContract, currentContract], setModalHasContract] = useState<
    [boolean, contractProps | null]
  >([false, null]);

  const [newOffer, setNewOffer] = useState(-1);

  const startContract = async (payload: any, offerId: number) => {
    const hasContract = await ApiContract.filterContracts({
      offerId: [offerId],
      clientId: [],
      companieUserPromoterId: [],
      order: 'created_at_desc',
      status: [
        'Aberto',
        'Ag.Pagamento',
        'Ativo',
        'Inadimplente',
        'Interrompido',
      ],
      date_start: '',
      date_end: '',
      filter: 'all',
      skip: 0,
      take: 1,
    });

    if (typeof hasContract !== 'string' && hasContract.total > 0) {
      setModalHasContract([true, hasContract.data[0]]);

      await ApiOffer.updateStatusOffer(
        {
          status: 'Aberta',
        },
        offerId
      );
      updateOffer(`${Math.random()} status`);

      return false;
    }

    const contract = await ApiContract.createClientContract({
      ...payload,
    });

    if (contract.id) {
      updateOffer(`${Math.random()} status`);
      notify({
        message: 'Status atualizado com sucesso',
        type: 'Success',
      });
      setShow(false);
      return true;
    }
    setIsLoading(false);
    await ApiOffer.updateStatusOffer(
      {
        status: 'Aberta',
      },
      offerId
    );
    updateOffer(`${Math.random()} status`);
    if (contract === 'error_exist_client_contract') {
      return contract;
    }
    return false;
  };

  const schema = yup.object().shape({
    email: yup.string().email(),
  });

  const validateData = async (
    email: string | null,
    phone: string | null,
    document: string
  ) => {
    if (phone === null || email === null || document === null) return false;
    const isPhoneValid = phoneValidate.isValid(phone);
    const isEmailValid = await schema.isValid({ email });
    const isValidDocument =
      cpfValidation.isValid(document) || cnpjValidation.isValid(document);

    return isPhoneValid && isEmailValid && isValidDocument;
  };

  const createContract = async () => {
    setIsLoading(true);
    if (offer) {
      if (
        await validateData(
          offer.person_email.trim(),
          offer.person_whatsapp,
          offer.person_document
        )
      ) {
        await closeOffer('Fechada');
        const payload = {
          offer_id: offer.id,
          client_id: null,
          client_document: offer.person_document,
          client_email: offer.person_email,
          client_name: offer.person_name,
          client_whatsapp: offer.person_whatsapp,
          client_civil_status: offer.person_civil_status ?? '',
          client_name_fantasy: offer.person_name_fantasy ?? '',
          client_birth: offer.person_birth,
          client_rg: offer.person_rg ?? '',
          client_gender: offer.person_gender ?? '',
        };
        const createContractRes = await startContract(payload, offer.id);
        if (createContractRes === true) {
          updateOffer(`${Math.random()} status`);
          notify({
            message: 'Status atualizado com sucesso',
            type: 'Success',
          });
          setShow(false);
          navigate('/contracts');
        } else if (typeof createContractRes === 'string') {
          notify({
            message: dictionaryError(createContractRes),
            type: 'Error',
          });
        } else {
          setIsLoading(false);
          notify({
            message: 'Não foi possível criar o contrato',
            type: 'Error',
          });
        }
      } else {
        setNewContact({
          companie_user_id: offer.companie_user_id,
          address: offer.person_address,
          city: offer.person_address_city,
          name: offer.person_name,
          email: offer.person_email,
          document: offer.person_document,
          id: offer.person_id,
          whatsapp: offer.person_whatsapp,
          phone: personOpportunity.person.phone,
          complement: offer.person_address_complement,
          state: offer.person_address_state,
          province: offer.person_address_province,
          zipcode: offer.person_address_zipcode,
          organization: personOpportunity.person.organization,
          office: personOpportunity.person.office,
          person_tag: personOpportunity.person_tag,
        });
        setModalClient(true);
      }
    } else {
      const offerData = await ApiOffer.createOffer({
        person_document: personOpportunity.person.document ?? '',
        person_email: personOpportunity.person.email ?? '',
        person_name: personOpportunity.person.name,
        person_whatsapp: personOpportunity.person.whatsapp ?? '',
        opportunity_id: opportunityId,
        person_id: personOpportunity.person_id,
        person_birth: personOpportunity.person.birth,
      });
      if (offerData.id) {
        setNewOffer(offerData.id);

        if (
          await validateData(
            offerData.person_email.trim(),
            offerData.person_whatsapp,
            offerData.person_document
          )
        ) {
          await ApiOffer.updateStatusOffer({ status: 'Fechada' }, offerData.id);
          const payload = {
            client_document: offerData.person_document,
            client_email: offerData.person_email.trim(),
            client_name: offerData.person_name,
            client_whatsapp: offerData.person_whatsapp,
            client_civil_status: offerData.person_civil_status ?? '',
            offer_id: offerData.id,
            client_id: null,
            client_name_fantasy: offerData.person_name_fantasy ?? '',
            client_birth: offerData.person_birth,
            client_rg: offerData.person_rg ?? '',
            client_gender: offerData.person_gender ?? '',
          };
          const createContractRes = await startContract(payload, offerData.id);
          if (createContractRes === true) {
            updateOffer(`${Math.random()} status`);
            notify({
              message: 'Status atualizado com sucesso',
              type: 'Success',
            });
            setShow(false);
            await ApiOpportunity.updateOpportunityStatus(opportunityId, {
              status: 'Ganha',
              responses_win_lose_id: winRes,
            });
            navigate('/contracts');
          } else if (typeof createContractRes === 'string') {
            notify({
              message: dictionaryError(createContractRes),
              type: 'Error',
            });
          } else {
            setIsLoading(false);
            notify({
              message: 'Não foi possível criar o contrato',
              type: 'Error',
            });
          }
        } else {
          setNewContact({
            companie_user_id: offerData.companie_user_id,
            address: offerData.person_address,
            city: offerData.person_address_city,
            name: offerData.person_name,
            email: offerData.person_email,
            document: offerData.person_document,
            id: offerData.person_id,
            whatsapp: offerData.person_whatsapp,
            phone: personOpportunity.person.phone,
            complement: offerData.person_address_complement,
            state: offerData.person_address_state,
            province: offerData.person_address_province,
            zipcode: offerData.person_address_zipcode,
            office: personOpportunity.person.office,
            organization: personOpportunity.person.organization,
            person_tag: personOpportunity.person_tag,
          });
          setModalClient(true);
        }
      } else {
        setIsLoading(false);
        notify({ message: dictionaryError(offerData), type: 'Error' });
      }
    }
  };

  const changeStatus = async () => {
    setIsLoading(true);
    if (offer) {
      const res = await ApiOffer.updateStatusOffer(
        {
          status: 'Fechada',
        },
        offer.id
      );
      if (res.id) {
        updateOffer(`${Math.random()} status`);
        notify({
          message: 'Proposta fechada com sucesso',
          type: 'Success',
        });
        setShow(false);
      }
    } else {
      const res = await ApiOpportunity.updateOpportunityStatus(opportunityId, {
        status: 'Ganha',
        responses_win_lose_id: winRes,
      });
      if (res.id) {
        notify({ message: 'Oportunidade ganha com sucesso', type: 'Success' });
        setIsLoading(false);
        updateOffer(String(Math.random()));
        setShow(false);
      } else {
        notify({ message: dictionaryError(res), type: 'Error' });
        setIsLoading(false);
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      {currentContract && (
        <ModalClientHasContract
          contract={currentContract}
          setShow={() => setModalHasContract([false, null])}
          show={modalHasContract}
        />
      )}
      <Modal
        setIsOpen={(e) => {
          // setModalClient(e);
          // closeAll();
        }}
        title="Atualize os dados para criar o contrato"
        isOpen={modalClient}
        size="6xlarge"
        minHeight="min-h-[200px]"
      >
        <ContactGeneralData
          validateDocument
          onePageRegister
          companyUserId={accountProps.id}
          defaultValue={newContact}
          id={newContact?.id ?? -1}
          setContact={async (client) => {
            setState(true);
            const offerId = offer ? offer.id : newOffer;
            const payload = {
              offer_id: offerId,
              client_id: null,
              client_document: client.document,
              client_email: client.email,
              client_name: client.name,
              client_whatsapp: client.whatsapp,
              client_birth: client.birth,
            };
            await ApiOffer.updateStatusOffer({ status: 'Fechada' }, offerId);
            await ApiOpportunity.updateOpportunityStatus(opportunityId, {
              status: 'Ganha',
              responses_win_lose_id: winRes,
            });
            const res = await startContract(payload, offerId);
            setState(false);
            if (res === true) {
              navigate('/contracts');
            }
          }}
          nextPage={() => {
            setModalClient(false);
            setShow(true);
          }}
          notify={notify}
          setId={() => {}}
        />
      </Modal>
      <Modal
        title="Criar contrato"
        setIsOpen={(e) => {
          setModalConfirmation(e);
          setShow(e);
        }}
        isOpen={modalConfirmation}
        size="medium"
        minHeight="min-h-[200px]"
      >
        <p className="text-sm">Deseja realmente criar o contrato?</p>
        <div className="flex w-full items-center justify-center gap-3 mt-5">
          <Button
            className="w-64"
            variant="outline-primary"
            onClick={changeStatus}
          >
            Não
          </Button>
          <Button
            className="w-64"
            variant="primary-strong"
            actionType="button-loading"
            onClick={() => {
              createContract();
              setModalConfirmation(false);
            }}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Sim
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default CreateContractModalAlert;
