/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Input, SelectComponent, Switch } from '../../../components';
import {
  ISelectedOption,
  IWhatsAppAutomation,
  IWhatsAppMessages,
} from '../types';
import {
  defaultDays,
  optionDays,
  quantityOfPurchases,
  periodOptions,
  absenceOptions,
  transformMessageOptions,
} from '../utils';
import maskHelper from '../../../helpers/mask.helper';
import { postConfiguration, putInterval } from '../api';
import { getTokenAccountInformation } from '../../../helpers/token.helper';

interface props {
  form: IWhatsAppAutomation;
  states: { [key: string]: boolean };
  switchState: (formId: string, form: IWhatsAppAutomation) => void;
  selectDays: (selectDay: ISelectedOption, id: number) => void;
  selectPeriod: (id: number, period: number) => void;
  valueAutomations: number | null;
  postValue: (id: number, value: number) => void;
  setValue: React.Dispatch<React.SetStateAction<number | null>>;
  selectMessageChange: (
    selectedOption: ISelectedOption,
    row: IWhatsAppAutomation
  ) => void;
  messages: IWhatsAppMessages[];
  formOptions: {
    label: any;
    value: any;
  }[];
  isLoadingData: boolean;
  selectForm: (id: number, formId: number | null) => void;
  setIntervalDays: React.Dispatch<React.SetStateAction<number | null>>;
  intervalDays: number | null;
}
const AutomationLayout: React.FC<props> = ({
  form,
  states,
  switchState,
  selectDays,
  valueAutomations,
  postValue,
  selectPeriod,
  setValue,
  setIntervalDays,
  selectMessageChange,
  messages,
  formOptions,
  isLoadingData,
  intervalDays,
  selectForm,
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <div className="h-[450px] w-80 m-5 relative transition ease-in-out delay-75 duration-150 shadow-sm text-xs flex flex-col border-solid border-gray-400 hover:border-primary rounded-md border">
      <div className="flex flex-col items-center justify-between border">
        {![5, 7, 9, 10].includes(form.id) && (
          <div className="min-h-16 grid place-items-center px-5">
            <strong>{form.title}</strong>
          </div>
        )}

        {![5, 7, 9, 10].includes(form.id) && (
          <hr className="w-full bg-gray-400 h-px mb-3" />
        )}

        {form.id === 5 && (
          <div className="min-h-16 grid place-items-center px-5">
            <strong>{form.title}</strong>
          </div>
        )}
        <div className="w-full">
          {form.id === 5 && (
            <SelectComponent
              className="w-full"
              options={optionDays}
              placeholder="Dias"
              onChange={(selectedOption: ISelectedOption) => {
                selectDays(
                  selectedOption,
                  form.companie_whatsapp_message_sending_configuration[0].id
                );
              }}
              defaultValue={
                form.companie_whatsapp_message_sending_configuration.length >
                  0 &&
                form.companie_whatsapp_message_sending_configuration[0].qty
                  ? {
                      label:
                        defaultDays[
                          form
                            .companie_whatsapp_message_sending_configuration[0]
                            .qty
                        ],
                      value:
                        form.companie_whatsapp_message_sending_configuration[0]
                          .qty,
                    }
                  : undefined
              }
              isDisabled={
                form.companie_whatsapp_message_sending_configuration.length ===
                0
              }
              menuPortalTarget={document.body}
            />
          )}
        </div>

        {form.id === 5 && <hr className="w-full bg-gray-400 h-px my-3" />}

        {form.id === 7 && (
          <div className="min-h-16 grid place-items-center px-5">
            <strong>{form.title}</strong>
          </div>
        )}
        <div className="w-full">
          {form.id === 7 && (
            <>
              <SelectComponent
                className="w-full"
                options={absenceOptions}
                placeholder="Dias"
                onChange={(selectedOption: ISelectedOption) =>
                  selectDays(
                    selectedOption,
                    form.companie_whatsapp_message_sending_configuration[0].id
                  )
                }
                defaultValue={
                  form.companie_whatsapp_message_sending_configuration.length >
                    0 &&
                  form.companie_whatsapp_message_sending_configuration[0].qty
                    ? absenceOptions.find(
                        (option) =>
                          option.value ===
                          form
                            .companie_whatsapp_message_sending_configuration[0]
                            .qty
                      ) || undefined
                    : undefined
                }
                isDisabled={
                  form.companie_whatsapp_message_sending_configuration
                    .length === 0
                }
                menuPortalTarget={document.body}
              />{' '}
            </>
          )}
        </div>

        {form.id === 7 && <hr className="w-full bg-gray-400 h-px my-3" />}

        {form.id === 9 && (
          <div className="min-h-16 grid place-items-center">
            <strong>Nº de compras XXX</strong>
            <strong>No período XXX</strong>
          </div>
        )}
        <div className="w-full">
          {form.id === 9 && (
            <>
              <SelectComponent
                className="w-full"
                placeholder="Número de compras"
                options={quantityOfPurchases}
                onChange={(selectedOption: ISelectedOption) =>
                  selectDays(
                    selectedOption,
                    form.companie_whatsapp_message_sending_configuration[0].id
                  )
                }
                isDisabled={
                  form.companie_whatsapp_message_sending_configuration
                    .length === 0
                }
                defaultValue={
                  form.companie_whatsapp_message_sending_configuration.length >
                    0 &&
                  form.companie_whatsapp_message_sending_configuration[0].qty
                    ? quantityOfPurchases.find(
                        (option) =>
                          option.value ===
                          form
                            .companie_whatsapp_message_sending_configuration[0]
                            .qty
                      ) || undefined
                    : undefined
                }
                menuPortalTarget={document.body}
              />{' '}
              <SelectComponent
                className="w-full"
                options={periodOptions}
                placeholder="Período"
                onChange={(selectedOption: any) =>
                  selectPeriod(
                    form.companie_whatsapp_message_sending_configuration[0].id,
                    selectedOption.value
                  )
                }
                defaultValue={
                  form.companie_whatsapp_message_sending_configuration.length >
                    0 &&
                  form.companie_whatsapp_message_sending_configuration[0].period
                    ? periodOptions.find(
                        (option) =>
                          option.value ===
                          form
                            .companie_whatsapp_message_sending_configuration[0]
                            .period
                      ) || undefined
                    : undefined
                }
                isDisabled={
                  form.companie_whatsapp_message_sending_configuration
                    .length === 0
                }
                menuPortalTarget={document.body}
              />{' '}
            </>
          )}
        </div>

        {form.id === 9 && <hr className="w-full bg-gray-400 h-px my-3" />}

        {form.id === 10 && (
          <div className="min-h-16 grid place-items-center">
            <strong>Valor total de compras XXX</strong>
            <strong>No período XXX</strong>
          </div>
        )}

        {form.id === 10 && (
          <div className="w-full">
            <Input
              variant="outline-orange"
              value={
                valueAutomations !== null
                  ? `R$ ${maskHelper.formatDecimal(
                      valueAutomations!.toString()
                    )}`
                  : 'R$ 0.00'
              }
              disabled={
                form.companie_whatsapp_message_sending_configuration.length ===
                0
              }
              onChange={(e) => {
                const numericValue = maskHelper.number(e.target.value);

                const valueAut = numericValue ? Number(numericValue) : null;
                setValue(valueAut);
              }}
              onBlur={() => {
                if (valueAutomations !== null) {
                  const formattedValue = valueAutomations / 100;
                  postValue(
                    form.companie_whatsapp_message_sending_configuration[0].id,
                    formattedValue!
                  );
                }
              }}
            />
            <SelectComponent
              className="w-full"
              placeholder="Período"
              options={periodOptions}
              onChange={(selectedOption: any) =>
                selectPeriod(
                  form.companie_whatsapp_message_sending_configuration[0].id,
                  selectedOption.value
                )
              }
              defaultValue={
                form.companie_whatsapp_message_sending_configuration.length >
                  0 &&
                form.companie_whatsapp_message_sending_configuration[0].period
                  ? periodOptions.find(
                      (option) =>
                        option.value ===
                        form.companie_whatsapp_message_sending_configuration[0]
                          .period
                    ) || undefined
                  : undefined
              }
              isDisabled={
                form.companie_whatsapp_message_sending_configuration.length ===
                0
              }
              menuPortalTarget={document.body}
            />
          </div>
        )}

        {form.id === 10 && <hr className="w-full bg-gray-400 h-px my-3" />}

        <div className="w-full my-2">
          <SelectComponent
            placeholder="Selecione a mensagem"
            className="w-full z-5000 absolute"
            options={transformMessageOptions(messages)}
            onChange={(selectedOption: ISelectedOption) =>
              selectMessageChange(selectedOption, form)
            }
            defaultValue={
              form.companie_whatsapp_message_sending_configuration.length > 0 &&
              form.companie_whatsapp_message_sending_configuration[0]
                .companie_whatsapp_messages_default
                ? {
                    label:
                      form.companie_whatsapp_message_sending_configuration[0]
                        .companie_whatsapp_messages_default.text,
                    value:
                      form.companie_whatsapp_message_sending_configuration[0]
                        .companie_whatsapp_messages_default.id,
                  }
                : undefined
            }
            menuPortalTarget={document.body}
          />
          <div className="w-full">
            {!isLoadingData && (
              <SelectComponent
                isClearable
                options={formOptions}
                placeholder="Selecione o formulário"
                menuPortalTarget={document.body}
                defaultValue={
                  form.companie_whatsapp_message_sending_configuration.length >
                    0 &&
                  form.companie_whatsapp_message_sending_configuration[0]
                    .search_form_id
                    ? formOptions.find(
                        (option: { value: any }) =>
                          option.value ===
                          form
                            .companie_whatsapp_message_sending_configuration[0]
                            .search_form_id
                      ) || undefined
                    : undefined
                }
                isDisabled={
                  form.companie_whatsapp_message_sending_configuration
                    .length === 0
                }
                onChange={(selectedOption: any) => {
                  if (selectedOption) {
                    selectForm(
                      form.companie_whatsapp_message_sending_configuration[0]
                        .id,
                      selectedOption.value
                    );
                  } else {
                    selectForm(
                      form.companie_whatsapp_message_sending_configuration[0]
                        .id,
                      null
                    );
                  }
                }}
              />
            )}
          </div>
        </div>
        <div className="w-full -mt-3">
          {' '}
          <Input
            variant="outline-orange"
            placeholder="Intervalo em dias"
            max={99}
            value={
              form.companie_whatsapp_message_sending_configuration.length > 0
                ? form.companie_whatsapp_message_sending_configuration[0]
                    .interval_in_days ?? ''
                : ''
            }
            disabled={
              form.companie_whatsapp_message_sending_configuration.length === 0
            }
            onChange={(e) => {
              const value = Math.min(Number(e.target.value), 99);
              const updatedData = [
                ...form.companie_whatsapp_message_sending_configuration,
              ];
              if (updatedData[0]) {
                updatedData[0].interval_in_days = value;
              }
              setIntervalDays(value);
            }}
            onBlur={async () => {
              const configurationExists =
                form.companie_whatsapp_message_sending_configuration.length > 0;

              if (configurationExists) {
                await putInterval(
                  form.companie_whatsapp_message_sending_configuration[0].id,
                  intervalDays ?? 0
                );
              }
            }}
          />
        </div>
        <div className="absolute bottom-4">
          <Switch
            state={states[form.id]}
            onChange={() => {
              switchState(form.id.toString(), form);
            }}
            disabled={
              form.companie_whatsapp_message_sending_configuration.length === 0
            }
          />
        </div>
        <hr className="w-full absolute bottom-16 bg-gray-400 h-px" />
      </div>
    </div>
  );
};

export default AutomationLayout;
