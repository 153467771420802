import React from 'react';
import { Button, Modal } from '../../../components';

/* eslint-disable no-unused-vars */
interface CancelDialogProps {
  setIsOpen: () => void;
  open: boolean;
  onConfirm: () => Promise<void>;
  loading: boolean;
}

const CancelDialog: React.FC<CancelDialogProps> = ({
  setIsOpen,
  open,
  onConfirm,
  loading,
}) => (
  <Modal
    setIsOpen={setIsOpen}
    title="Cancelar nota fiscal de serviço"
    isOpen={open}
    // size="2xlarge"
    minHeight="min-h-[300px]"
  >
    <strong className="text-center text-lg">
      Tem certeza que deseja cancelar a nota fiscal?
    </strong>
    <p className="text-sm mt-4 mb-10">
      A nota fiscal será cancelada e esta ação não pode ser desfeita.
    </p>
    <div className="flex gap-3 w-full">
      <Button variant="primary-strong" onClick={setIsOpen} className="w-1/2">
        Cancelar
      </Button>
      <Button
        className="w-1/2 relative flex items-center justify-center hover:bg-gray/40 disabled:border-[#ddd] disabled:text-gray-600"
        variant="outline-primary"
        actionType="button-loading"
        disabled={loading}
        isLoading={loading}
        onClick={() => onConfirm()}
      >
        Confirmar cancelamento de nota fiscal{' '}
      </Button>
    </div>
  </Modal>
);

export default CancelDialog;
