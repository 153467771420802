import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { CheckmarkCircle2Outline } from '@styled-icons/evaicons-outline/CheckmarkCircle2Outline';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { ToastNotify } from '../../../components/Toast/toast';
import {
  Card,
  DeleteModal,
  DropdownIcons,
  Input,
  SelectComponent,
} from '../../../components';
import { ActivityProps, activityStatus, formProps } from '../types';
import { ApiActivitie } from '../api';
import { useFetchActivitie } from '../hooks';
import RegisterActivitie from '../components/register';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { useFetchUsersCompanie } from '../../users/hooks';
import { dictionaryError } from '../../../helpers/utils.helper';
import maskHelper from '../../../helpers/mask.helper';
import { useFetchOpportunityDropdown } from '../../opportunity/hooks';
import { useFetchActivitieType } from '../../activitieType/hooks';
import { useFetchContact } from '../../contacts/hooks';
import { reloadNotification } from '../../../hooks/globalAtom';
import MainPagePagination from '../../main/components/mainPage/mainPageWithPagination';
import IconDictionary from '../../../components/Icons/icons';
import SearchActivity from '../components/filterModal';

const Activitie: React.FC = () => {
  const [modalFilter, setModalFilter] = useState(false);
  const { activityId } = useParams();
  const accountProps = getTokenAccountInformation();
  const [notificationState, reloadNotificationContainer] =
    useAtom(reloadNotification);
  const userProps = getTokenAccountInformation();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalRegisterActivitie, setModalRegisterActivitie] = useState(false);
  const [startData, setStartData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [input, setInput] = useState('');
  const [activity, setActivitieTypeData] = useState<ActivityProps | null>(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [key, setKey] = useState('key');
  const [filterDate, setFilterDate] = useState(
    activityId ? 'all' : `this_month`
  );

  const [filterModal, setFilterModal] = useState<formProps>({
    opportunityId: [],
    activitieId: [],
    companieUserIdOwner: [],
    personId: [],
    status: 'Todos',
    skip: startData,
    take: 10,
    id: activityId ? [Number(activityId)] : [],
  });

  const { ActivitieList, isLoading, setActivityList } = useFetchActivitie(
    key,
    filterDate,
    filterModal,
    input,
    notificationState
  );

  const [inputOpportunity, setInputOpportunity] = useState('');
  const { OpportunityList, isLoadingOpportunityList } =
    useFetchOpportunityDropdown(inputOpportunity);

  const opportunityOptions = OpportunityList?.data.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const { ActivitieTypeList } = useFetchActivitieType(
    'key',
    userProps.companie_id
  );
  const activityOptions = ActivitieTypeList.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const [inputContact, setInputContact] = useState('');

  const { ContactList, isLoadingContacts } = useFetchContact(
    'key',
    0,
    100000000,
    inputContact
  );
  const optionsContacts = ContactList.data.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const { UsersList } = useFetchUsersCompanie('key', userProps.companie_id);
  const optionsUsers = UsersList.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const { notify } = ToastNotify();

  const updateStatus = async (status: activityStatus, id: number) => {
    setIsLoadingStatus(true);
    const response = await ApiActivitie.updateActivitieStatus(status, id);
    if (response.id) {
      reloadNotificationContainer(!notificationState);
      notify({ message: 'Status Atualizado com sucesso!', type: 'Success' });
      setKey(`${Math.random()} status`);
    } else {
      notify({ message: dictionaryError(response), type: 'Error' });
    }
    setIsLoadingStatus(false);
  };

  const columns = [
    {
      name: 'Tarefa',
      key: 'activitie_type.title',
      selector: (row: ActivityProps) => row.activitie_type.title,
    },
    {
      name: 'Organização',
      key: 'companie_user_id_owner',
      selector: (row: ActivityProps) => row.companie_user_id_owner,
      cell: (row: ActivityProps) =>
        UsersList.find((item) => item.id === row.companie_user_id_owner)?.name,
      hide: 1330,
    },
    {
      name: 'Pessoa',
      key: 'person.name',
      selector: (row: ActivityProps) =>
        row.companie_user_activities_companie_user_id_ownerTocompanie_user.name,
      hide: 1432,
    },
    {
      name: 'Oportunidade',
      key: 'opportunity.title',
      selector: (row: ActivityProps) =>
        row.opportunity?.title ?? 'Sem vínculo com oportunidade',
    },
    {
      name: 'Funil',
      key: 'opportunity.funnel_step.funnel.title',
      selector: (row: ActivityProps) =>
        row.opportunity?.funnel_step.funnel.title,
      hide: 1227,
    },
    {
      name: 'Criado em',
      key: 'created_at',
      selector: (row: ActivityProps) =>
        maskHelper.formatDateDMY(row.created_at),
    },
    {
      name: 'Realizar em',
      key: 'date',
      selector: (row: ActivityProps) => maskHelper.formatDateDMY(row.date),
    },
    {
      name: 'Status',
      key: 'status',
      selector: (row: ActivityProps) => (
        <p
          className={
            row.status === 'Realizado'
              ? 'text-[11px] text-green font-medium'
              : 'text-[11px]'
          }
        >
          {row.status}
        </p>
      ),
    },
    {
      name: 'Ações',
      width: '90px',
      cell: (row: ActivityProps) => {
        const dropdownItems = [
          ...(row.status === 'Em andamento'
            ? [
                {
                  label: 'Marcar como Realizado',
                  value: (
                    <CheckmarkCircle2Outline
                      size={20}
                      className="cursor-pointer text-green hover:scale-110"
                    />
                  ),
                  handleClick: () => {
                    updateStatus({ status: 'Realizado' }, row.id);
                  },
                },
              ]
            : []),
          {
            label: 'Excluir',
            value: (
              <CloseOutline
                size={20}
                className="cursor-pointer hover:scale-110"
                color="#DA3C3C"
              />
            ),
            handleClick: () => {
              setActivitieTypeData(row);
              setShowModalDelete(true);
            },
          },
        ];

        return (
          <div className="ml-1.5">
            <DropdownIcons
              components={dropdownItems}
              modalSide="bottom"
              modalAlign="end"
            />
          </div>
        );
      },
    },
  ];

  const [datePicker, setDatePicker] = useState<{
    open: boolean;
    start?: Date;
    end?: Date;
  }>({
    open: false,
  });

  const dateFilterOptions = [
    { value: 'today', label: 'Hoje' },
    { value: 'yesterday', label: 'Ontem' },
    { value: 'this_week', label: 'Esta semana' },
    { value: 'last_week', label: 'Semana passada' },
    { value: 'this_month', label: 'Este mês' },
    { value: 'last_30_days', label: 'Últimos 30 dias' },
    { value: 'other_period', label: 'Outro intervalo' },
  ];

  const generateCsv = async () => {
    const res = await ApiActivitie.getActivityFilters(filterDate, {
      ...filterModal,
      personId: filterModal.personId.map((item) => item.value),
      opportunityId: filterModal.opportunityId.map((item) => item.value),
      take: 100000000,
    });
    if (typeof res !== 'string' && res.data) {
      const csvData = [
        [
          'Tipo da Atividade',
          'Criado em',
          'Realizar em',
          'Horário',
          'Status',
          'Organização',
          'Descrição',
          'Oportunidade',
          'Contato',
        ],
        ...res.data.map((item) => [
          item.activitie_type.title,
          maskHelper.formatDateDMY(item.created_at ?? ''),
          maskHelper.formatDateDMY(item.date ?? ''),
          item.hour,
          item.status,
          item.companie_user_activities_companie_user_id_ownerTocompanie_user
            .name,
          item.description,
          item.opportunity?.title,
          item.person?.name,
        ]),
      ];
      return csvData;
    }
    return [[]];
  };

  useEffect(() => {
    if (filterDate !== 'other_period') {
      setFilterDate(filterDate);
      if (!filterDate.includes('other_period')) {
        setDatePicker({ open: false });
      }
    } else {
      setDatePicker({ ...datePicker, open: true });
    }
  }, [filterDate]);

  useEffect(() => {
    setFilterModal({ ...filterModal, skip: startData });
  }, [startData]);

  const searchActivity = async (value: string) => {
    setCurrentPage(1);
    setFilterModal({ ...filterModal, skip: 0, take: 10 });
    setInput(value);
  };

  return (
    <>
      <SearchActivity
        setInputActivity={setInput}
        isLoadingDropdown={isLoadingOpportunityList}
        setInputOpportunity={setInputOpportunity}
        isLoadingPerson={isLoadingContacts}
        personInput={inputContact}
        setInputPerson={setInputContact}
        profileId={accountProps.profile_id}
        activityOptions={activityOptions}
        setFilter={setFilterModal}
        userOptions={optionsUsers}
        setActivityList={setActivityList}
        filters={filterModal}
        opportunityList={opportunityOptions}
        personOptions={optionsContacts}
        setShow={setModalFilter}
        show={modalFilter}
        period={filterDate}
        restartPagination={() => {
          setStartData(0);
          setCurrentPage(1);
        }}
      />
      <MainPagePagination
        filename="atividades"
        reportAndCreate
        currentPageTable={currentPage}
        setCurrentPageTable={setCurrentPage}
        startDate={setStartData}
        totalRegisters={ActivitieList.total}
        isLoading={isLoading || isLoadingStatus}
        column={columns}
        title="Tarefas"
        filterTable={() => ActivitieList.data}
        FilterComponent={
          <Card className="flex-col col-span-2 w-full pt-5 px-4 pb-3.5 gap-4">
            <strong>Pesquisar</strong>
            <div className="flex flex-col xl:flex-row gap-2">
              <div className="flex w-full flex-col sm:flex-row gap-2">
                <Input
                  value={input}
                  onChange={(e) => searchActivity(e.target.value)}
                  icon={
                    <IconDictionary
                      name="Lupa"
                      size={18}
                      color="#8C8C8C"
                      className="mr-4"
                    />
                  }
                  placeholder="Pesquisar Atividades"
                  className="w-full"
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-1 sm:gap-3 w-full mt-2 md:mt-0 lg:w-fit">
                <SelectComponent
                  variant="outline-primary"
                  icon="Calendario"
                  value={dateFilterOptions.find(
                    (item) => item.value === filterDate
                  )}
                  className="w-full sm:w-48 lg:w-48 2xl:w-60"
                  options={dateFilterOptions}
                  closeMenuOnSelect
                  onChange={(e: any) => {
                    setFilterDate(e.value);
                    if (e.value !== 'other_period') {
                      setDatePicker({ open: false });
                      setFilterDate(e.value);
                      setStartData(0);
                      setCurrentPage(1);
                    } else {
                      setDatePicker({ open: true });
                    }
                  }}
                  maxOptionsHeight="300px"
                />
                {datePicker.open && (
                  <div className="flex mb-1.5">
                    <DatePicker
                      openCalendarOnFocus
                      format="dd/MM/yyyy"
                      className="start_date_input"
                      value={datePicker.start}
                      onChange={(e: Date) => {
                        setDatePicker({ ...datePicker, start: e });
                        if (typeof datePicker.end !== 'undefined') {
                          setFilterDate(
                            `other_period/${maskHelper.formatDateYMD(
                              e.toLocaleDateString('pt-br')
                            )}/${maskHelper.formatDateYMD(
                              datePicker.end?.toLocaleDateString('pt-br') ?? ''
                            )}`
                          );
                        }
                      }}
                    />
                    <p className="bg-primary w-11 h-11 flex items-center justify-center text-xs text-white">
                      Até
                    </p>
                    <DatePicker
                      openCalendarOnFocus
                      format="dd/MM/yyyy"
                      className="end_date_input"
                      onChange={(e: Date) => {
                        setDatePicker({ ...datePicker, end: e });
                        if (typeof datePicker.start !== 'undefined') {
                          setFilterDate(
                            `other_period/${maskHelper.formatDateYMD(
                              datePicker.start?.toLocaleDateString('pt-br') ??
                                ''
                            )}/${maskHelper.formatDateYMD(
                              e.toLocaleDateString('pt-br')
                            )}`
                          );
                        }
                      }}
                      minDate={datePicker.start}
                      value={datePicker.end}
                    />
                  </div>
                )}
                <button
                  type="button"
                  className="xl:hidden w-12 h-11 rounded-lg md:place-self-start items-center justify-center cursor-pointer text-primary border-primary border-solid border"
                  onClick={() => setModalFilter(true)}
                >
                  <IconDictionary name="Filtro" size={16} />
                </button>
              </div>
              <button
                type="button"
                className="hidden xl:flex min-w-[44px] h-11 rounded-lg justify-center items-center cursor-pointer text-primary border-primary border-solid border"
                onClick={() => setModalFilter(true)}
              >
                <IconDictionary name="Filtro" size={16} />
              </button>
            </div>
          </Card>
        }
        setModalCreateUpdate={setModalRegisterActivitie}
        setRegisterPage={() => {
          setActivitieTypeData(null);
        }}
        onRowClick={(e: ActivityProps) => {
          setActivitieTypeData(e);
          setModalRegisterActivitie(true);
        }}
        selectedRowId={Number(activityId)}
        cardsDirection="row"
        objectReport={generateCsv}
      />
      {modalRegisterActivitie && (
        <RegisterActivitie
          isLoadingDropdown={isLoadingOpportunityList}
          setInputOpportunity={setInputOpportunity}
          isLoadingContactList={isLoadingContacts}
          setInputContact={setInputContact}
          activityOptions={activityOptions}
          opportunityOptions={opportunityOptions}
          optionsContacts={optionsContacts}
          optionsUsers={optionsUsers}
          companieUserId={userProps.id}
          activitie={activity}
          reloadTable={(e) => {
            setKey(e);
            reloadNotificationContainer(!notificationState);
          }}
          setShow={setModalRegisterActivitie}
          show={modalRegisterActivitie}
        />
      )}

      {activity && (
        <DeleteModal
          apiFunction={ApiActivitie.deleteActivitie}
          notify={notify}
          type="Tarefa"
          article="A"
          updateTable={() => setKey(`${Math.random()} 'delete'`)}
          close={() => setShowModalDelete(false)}
          id={activity.id}
          name="essa atividade"
          open={showModalDelete}
        />
      )}
    </>
  );
};

export default Activitie;
