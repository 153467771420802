import { lighten } from 'polished';
import moment from 'moment';
import { baseApi, baseApiPrivate } from '../api/baseApi';
import { ToastNotify } from '../components/Toast/toast';
import maskHelper from './mask.helper';
import { setItem } from './storage.helper';

export const translateStatusPay = (status: string): string => {
  if (status === 'PENDING') return 'Pendente';
  if (
    status === 'RECEIVED' ||
    status === 'CONFIRMED' ||
    status === 'RECEIVED_IN_CASH'
  )
    return 'Confirmado';
  if (status === 'OVERDUE') return 'Vencida';
  if (status === 'DELETED') return 'Excluída';
  if (status === 'REFUNDED') return 'Estornada';
  return status;
};

export const translateTypePay = (status: string): string => {
  if (status === 'CREDIT_CARD') return 'Cartão de Crédito';

  return 'PIX';
};

export const translateStatusSubscription = (status: string): string => {
  if (status === 'ACTIVE') return 'Ativa';

  return 'Inativa';
};

export const calculePrice = (
  priceByKg: string | number,
  weightEnd: string | number
) =>
  maskHelper.formatMoeda(
    (Number(priceByKg) * Number(String(weightEnd).replace('.', ''))) / 1000
  );

export const calculePriceDiscount = (
  priceByKg: string | number,
  weightEnd: string | number,
  discount: string | number
) =>
  maskHelper.formatMoeda(
    (Number(priceByKg) * Number(String(weightEnd).replace('.', ''))) / 1000 -
      Number(discount)
  );

export const normalizeNFD = (value: string) =>
  value.normalize('NFD').replace(/[^a-zA-Z\s]/g, '');

export function dictionaryError(err: string) {
  if (err.includes('instance does not exist')) {
    return 'Instância inválida. Por favor, remova-a e crie uma nova.';
  }
  if (err.includes('Galax:')) {
    return err.replace('Galax: ', '');
  }
  if (err.includes('Dados inválidos')) {
    return err;
  }
  if (err.includes('validation')) {
    return err.replace('validation', '').replaceAll('\\', '').slice(1, -1);
  }
  if (err.includes('Erros de validação.')) {
    return 'Conta no banco utilizada não foi aprovada. Para utilizar essa funcionalidade é necessário ter uma conta no banco ativa e aprovada';
  }
  if (err.includes('excede o valor da cobrança.')) {
    return 'As configurações do financeiro da empresa estão incorretas. Por favor, entre em contato para atualizá-las.';
  }
  switch (err) {
    case 'error_exist_client_contract':
      return 'Não foi possível criar o contrato, pois já existe um contrato associado ao cliente com um status diferente de "Cancelado".';
    case 'Error: error_exists_campaign_using_message':
      return 'Não é possivel remover. Existe uma ou mais campanhas vinculadas a essa mensagem de Whatsapp';
    case 'error_exist_opportunity_person_in_person':
      return 'Não é possível remover, o contato esta vinculado a uma oportunidade.';
    case 'error_exist_client_contract_in_person':
      return 'Não é possível remover, o contato esta vinculado a um cliente com contrato.';
    case 'error_exist_client_financial_in_person':
      return 'Não é possível remover, o contato esta vinculado a um cliente com receitas e/ou despesas.';
    case 'error_opportunity_using_sales_channel':
      return 'Não é possível remover, o canal de vendas esta vinculado a uma oportunidade.';
    case 'error_companie_whatsapp_instances_using_sales_channel':
      return 'Não é possível remover, o canal de vendas esta vinculado a uma instância.';
    case 'error_companie_settings_automations_using_sales_channel':
      return 'Não é possível remover, o canal de vendas esta vinculado a uma configuração de automação.';
    case 'error_opportunity_using_segment':
      return 'Não é possível remover, o segmento esta vinculado a uma oportunidade.';
    case 'error_companie_whatsapp_instances_using_segment':
      return 'Não é possível remover, o segmento esta vinculado a uma instância.';
    case 'error_companie_settings_automations_using_segment':
      return 'Não é possível remover, o segmento esta vinculado a uma configuração de automação.';
    case 'Não é possível inserir novo histórico pois essa conta já tem um pendente de análise.':
      return err;
    case 'Error: {"message":"Instabilidade no servidor, tente mais tarde."}':
      return 'Instabilidade no servidor, tente mais tarde.';
    case 'O cnae informado é inválido.':
      return err;
    case 'A empresa autenticada não pode dividir o valor para si mesma.':
      return 'O método de pagamento atual possui split financeiro configurado. A empresa atual não pode dividir o valor para si mesma.';
    case 'Error: error_value_split_invalid_in_credicard':
    case 'Error: error_value_split_invalid_in_pix':
    case 'Error: error_value_split_invalid_in_boleto':
      return 'As configurações do financeiro da empresa estão incorretas. Por favor, entre em contato para atualizá-las.';
    case 'Error: {"message":"Não é possível inserir novo histórico pois essa conta já tem um pendente de análise."}':
      return 'Não é possível inserir novo histórico pois essa conta já tem um pendente de análise.';
    case '"error_companie_exist"':
      return 'Conta de banco já existente';
    case '"error_attempting_payment"':
      return 'Ocorreu um erro ao tentar alterar a forma de pagamento. Por favor tente novamente.';
    case 'error_companie_document_exist':
      return 'CPF/CNPJ já em uso';
    case 'Error: {"statusCode":400,"error":"Bad Request","message":"Validation failed","validation":{"body":{"source":"body","keys":["client_whatsapp"],"message":""client_whatsapp" não deve ser vazio"}}}':
      return 'WhatsApp do contato deve ser preenchido para ganhar a oportunidade';
    case 'Error: {"message":"Transação não é válida para alterar o vencimento."}':
      return 'Transação não é válida para alterar o vencimento.';
    case 'Error: [{"code":"invalid_object","description":"O email de login analaurarufino@hotmail.com já está em uso."}]':
      return 'Endereço de e-mail já em uso';
    case 'Error: [{"code":"invalid_object","description":"O número de celular informado já foi utilizado. Informe outro e tente novamente."}]':
      return 'O número de celular informado já foi utilizado';
    case 'Error: [{"code":"invalid_object","description":"O CPF/CNPJ informado é inválido, verifique os números e tente novamente."}]':
      return 'O CPF/CNPJ informado é inválido';
    case 'Error: {"status":"error","message":"A registered user is required for the import to be possible"}':
      return 'É necessário o cadastro de pelo menos um usário para importar.';
    case 'Error: account_not_exist':
      return 'E-mail e senha inválidos';
    case 'Error: error_credential_invalid':
      return 'Endereço de e-mail ou senha inválidos';
    case 'Error: "email" deve ser um e-mail válido':
      return 'Endereço de e-mail inválido';
    case `SyntaxError: Unexpected token 'e', "error_not_valid_code" is not valid JSON`:
      return 'Código inválido';
    case 'Error: error_account_email_exist':
      return 'Endereço de e-mail já em uso';
    case 'Error: error_client_exist':
      return 'Cliente já existente';
    case 'Error: error_exist_this_account_asaas':
      return 'Conta já existente';
    case 'Error: error_opportunity_person_exist':
      return 'Contato ja vinculado com a oportunidade';
    case 'Error: error_exist_offer_status_not_permission':
      return 'Não é permitido mudar o status';
    case '"error_person_not_exist"':
      return 'Contato não existe';
    case 'Error: Internet ou API fora do ar':
      return 'Sistema indisponível no momento. Por favor, tente mais tarde';
    case 'account_email_not_exist':
      return 'Email fornecido não existe em nossa base de dados';
    case '"error_person_exist"':
      return 'Contato já existente';
    case '"error_person_duplicate_document"':
      return 'CPF já em uso';
    case '"error_person_duplicate_email"':
      return 'Email já em uso';
    case '"error_person_duplicate_whatsapp"':
      return 'WhatsApp já em uso';
    case '"error_person_duplicate_phone"':
      return 'Telefone já em uso';
    case 'Error: error_not_exist_params_pla_account_product':
      return 'Não existe uma Conta de lançamento vinculada ao produto. Por favor vincular para faturar o contrato';
    case 'Error: error_provider_exist':
      return 'Fornecedor já existente';
    case 'error_whatsapp_plans_not_exist ':
      return 'Este Plano não existe em nossa base de dados';
    case 'error_exist_plans_associate ':
      return 'Não é permitido deletar este Plano';
    case 'Error: error_edit_or_delete_not_permission':
      return 'Formulário já possui respostas. Não é possível alterar nem exclui-lo.';
    case 'contact_has_activity':
      return 'Não foi possível remover. Contato possui atividade vinculada';
    case 'error_person_not_exist':
      return 'Não foi possível remover. O contato está em uma condição que impede a exclusão';
    case 'Error: error_campaign_not_permission_edit':
      return 'O status da campanha não permite edição';
    case '"error_due_date_less_now"':
      return 'A data de vencimento deve ser inferior a data de hoje';
    case 'Error: error_funnel_exist_opportunity':
      return 'Não é possível excluir. Existe uma ou mais oportunidade vinculada ao funil.';
    case 'Error: error_funnel_exist_companie_settings_automations':
      return 'Não é possível excluir. Existe uma ou mais configuração de automação vinculada ao funil.';
    case 'Error: error_funnel_exist_companie_whatsapp_instances':
      return 'Não é possível excluir. Existe uma ou mais instância WhatsApp vinculada ao funil.';
    case 'Error: error_instance_exist':
      return 'Instância já em uso, escolha outro número!';
    case 'Error: "Request failed with status code 503"':
      return 'Desculpe, o serviço esta temporariamente indisponível.';
    case 'Error: error_exist_opportunity_person_open':
      return 'Não é possível criar a oportunidade. Já existe uma oportunidade aberta com o contato escolhido no funil atual.';
    case 'Error: "SessionError: No sessions"':
      return 'Nenhuma sessão encontrada. Por favor, reconecte utilizando o QR code na página de cadastro de instâncias';
    case 'Error: error_companie_limit_users_exceeded':
      return 'O limite de usuários ativos para a empresa excedeu';
    case 'Error: error_status_not_permission_cancel':
      return 'O status do plano atual não permite o cancelamento';
    case 'Error: error_exist_opportunity_in_step':
      return 'Exclusão da etapa atual não é permitida devido à existência de uma ou mais oportunidades vinculadas.';
    case 'Serviço temporariamente indisponível':
      return 'Serviço temporariamente indisponível, tente novamente mais tarde!';
    case 'Falha na conexão':
      return 'Falha na conexão, tente novamente!';
    case 'Tempo limite de requisição atingido':
      return 'Tempo limite de requisição atingido';
    case 'error_companie_exist':
      return 'Empresa já existe';
    case 'error_attempting_payment':
      return 'Ocorreu um erro ao tentar alterar a forma de pagamento. Por favor tente novamente.';
    default:
      return 'Desculpe, algo deu errado';
  }
}
interface verifyEmailResponse {
  identity: string;
  sendEmail: boolean;
}

export function verifyEmail(email: string): Promise<verifyEmailResponse> {
  return baseApi({ method: 'GET', url: `session/user/recovery-pass/${email}` });
}

export function verifyCompanyId(companyId: number): Promise<any> {
  return baseApi({ method: 'GET', url: `session/companie/valid/${companyId}` });
}

export function clearNullValuesObject(inputObject: any) {
  if (inputObject === null) {
    return {};
  }
  Object.keys(inputObject).forEach((key) => {
    if (inputObject[key] === null) {
      delete inputObject[key];
    }
  });
  return inputObject;
}

export function generateHexColor(quantity: number) {
  const arrayColors = [];
  for (let i = 0; i < quantity; i++) {
    arrayColors.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
  }
  return arrayColors;
}

export function isValidHexColor(color: string): boolean {
  if (color.length < 7) return false;
  const hexColorRegex = /^#?([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/;
  return hexColorRegex.test(color);
}

export function getUrlPlatform() {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      return 'http://localhost:3000/';
    case 'qa':
      return 'https://plus.qa.leadsim.com.br/';
    case 'production':
      return 'https://plus.leadsim.com.br/';
    default:
      return 'https://plus.leadsim.com.br/';
  }
}

export const CopyLink = (link: string) => {
  const { notify } = ToastNotify();
  try {
    const textarea = document.createElement('textarea');
    textarea.value = link;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    notify({
      message: 'Link copiado para a área de tranferência',
      type: 'Info',
    });
  } catch (error) {
    notify({
      message: 'Não foi possível copiar o link',
      type: 'Info',
    });
  }
};

export function getContrastColorWithOpacity(
  hexColor: string,
  opacityThreshold = 0.4
) {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const a =
    hexColor.length === 9 ? parseInt(hexColor.slice(7, 9), 16) / 255 : 1;

  if (a < opacityThreshold) {
    return `rgba(${r}, ${g}, ${b}, 1)`;
  }
  return '#ffffff';
}

export function getTextColorByOpacity(
  hexColor: string,
  opacityThreshold = 0.4
) {
  const a =
    hexColor.length === 9 ? parseInt(hexColor.slice(7, 9), 16) / 255 : 1;

  if (a < opacityThreshold) {
    return `#000000`;
  }
  return '#ffffff';
}

export const setLayoutColor = async (companyId: number, setState: any) => {
  setState(true);
  const res = await verifyCompanyId(companyId);
  if (res.color_hex) {
    setItem('color-hex', res.color_hex);
    const root = document.documentElement;
    root.style.setProperty('--primary', res.color_hex);
    root.style.setProperty('--secondary', lighten(0.1, res.color_hex));
  } else if (res === 'Error: error_settings_type_form_not_exist') {
    window.location.href = '/missing-configuration';
  }
  setState(false);
};

export const downloadFile = async (fileName: string, path: string) => {
  try {
    const response = await baseApiPrivate({
      method: 'GET',
      url: path,
      responseFile: true,
    });
    const url = URL.createObjectURL(response);

    const download = document.createElement('a');
    download.href = url;
    download.download = fileName;
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
  } catch (error) {
    console.error('Erro ao baixar o arquivo:', error);
  }
};

export function hexToRgba(hex: string, opacity: number) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
}

export async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string); // Converter o resultado para string
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export async function imageUrlToBase64(url: string): Promise<string> {
  const response = await fetch(url);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = (reader.result as string).split(',')[1]; // Remover o prefixo "data:...;base64,"
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
}

export function formatTimeStampTodateHour(timestamp: string) {
  const formattedDate = moment(timestamp).format('DD/MM/YYYY HH:mm');

  return formattedDate;
}

export function formatTimeStampToDateHourDay(timestamp: string) {
  const formattedDate = moment(timestamp).format('ddd DD/MM/YYYY HH:mm');

  return formattedDate;
}

export const PublicRoutes = [
  '/logout',
  '/checkout',
  '/session/login',
  '/password-recovery',
  '/invalid-link',
  '/missing-configuration',
  '/offer/pdf',
  '/change-pass',
  '/form-lead',
  '/contract/accept',
  '/form/opportunity',
];

export const PagesDone = [
  'menu-admin',
  'companie-admin',
  'user-admin',
  'digital-signature',
  'parameters',
  'user',
  'client',
  'provider',
  'Vendas antigas',
  'profile-admin',
  'category-admin',
  'group-admin',
  'product-admin',
  'purchase-olds',
  'funnel',
  'segment',
  'sales-channel',
  'responses-win',
  'responses-lose',
  'activities-type',
  'contacts',
  'opportunity',
  'activities',
  'transfer-opportunity',
  'menus-profile',
  'access-companie-group',
  'input-automations',
  'imports-opportunities',
  'reports-opportunity',
  'module-admin',
  'plan-accounts',
  'type-pet',
  'cors',
  'races',
  'contracts-settings',
  'contract-services',
  'contracts',
  'accounts-pay-receive',
  'reports-accounts-pay-receive',
  'reports-cash-flow',
  'reports-balancete',
  'products',
  'companie-custom',
  'offer-custom',
  'offer',
  'whatsapp-plans',
  'search-form',
  'tags',
  'instances',
  'message-default',
  'bulk-shipping',
  'partners',
  'default-message',
  'multiple-send',
  'digital-signature-credit',
  'payment-pix',
  'subaccounts-galax-pay',
  'bank-statement',
  'automations',
  'digital-signature-plans',
  'bank-account',
  'invoice-credit',
  'invoice-plans',
  'invoice-config',
  'reports-service-invoice',
];

export function debounce(func: any, delay: number) {
  let timerId: any;
  // eslint-disable-next-line func-names
  return function (this: any, ...args: any[]) {
    clearTimeout(timerId); // Clear existing timeout
    timerId = setTimeout(() => {
      func.apply(this, args); // Execute the original function
    }, delay);
  };
}
