import { useCallback, useEffect, useState } from 'react';
import { BsFiletypePdf, BsFiletypeXml } from 'react-icons/bs';
import { MdOutlineCancel } from 'react-icons/md';
import MainPagePagination from '../../../main/components/mainPage/mainPageWithPagination';
import { APiFinancialServiceInvoiceReports } from '../api';
import maskHelper from '../../../../helpers/mask.helper';
import { ToastNotify } from '../../../../components/Toast/toast';
import CancelDialog from '../components/canceDialog';

const StatusNames = {
  CancelFailed: 'Falha no cancelamento',
  IssueFailed: 'Falhou na emissão',
  Issued: 'Emitido',
  Cancelled: 'Cancelado',
  PullFromCityHall: 'Retirado da Prefeitura',
  WaitingCalculateTaxes: 'Aguardando cálculo de impostos',
  WaitingDefineRpsNumber: 'Aguardando definição do número do RPS',
  WaitingSend: 'Aguardando envio',
  WaitingSendCancel: 'Aguardando cancelamento do envio',
  WaitingReturn: 'Aguardando retorno',
  WaitingDownload: 'Baixando Nota fiscal na prefeitura',
};
const ReportsServiceInvoice = () => {
  const [invoiceToCancel, setInvoiceToCancel] = useState<null | Record<
    string,
    any
  >>(null);
  const [loadingInvoiceAction, setLoadingInvoiceAction] = useState(false);

  const [, setStartData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [serviceInvoices, setServiceInvoices] = useState([]);
  const [loadingServiceInvoices, setLoadingServiceInvoices] = useState(false);

  const { notify } = ToastNotify();
  const onClickXmLButton = async (serviceInvoice: any) => {
    if (serviceInvoice.flowStatus !== 'Issued') {
      notify({
        message: `Apenas notas com status "Emitida" podem ser baixadas. O status atual é "${
          StatusNames[serviceInvoice.flowStatus as keyof typeof StatusNames]
        }"`,
        type: 'Warning',
      });
      return;
    }
    try {
      setLoadingInvoiceAction(true);
      const response =
        await APiFinancialServiceInvoiceReports.getServiceInvoiceXMl(
          serviceInvoice.id
        );
      if (typeof response === 'string') {
        notify({
          message: 'Não foi possível baixar o xml da nota fiscal',
          type: 'Error',
        });
        return;
      }
      const blob = new Blob([response], { type: 'application/xml' });
      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'file.xml'; // Set the file name for the download

      // Trigger the download by simulating a click
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);
    } catch (error) {
      notify({
        message: 'Não foi possível baixar o xml da nota fiscal',
        type: 'Error',
      });
    } finally {
      setLoadingInvoiceAction(false);
    }
  };
  const onClickPDFButton = async (serviceInvoice: any) => {
    if (serviceInvoice.flowStatus !== 'Issued') {
      notify({
        message: `Apenas notas com status "Emitida" podem ser baixadas. O status atual é "${
          StatusNames[serviceInvoice.flowStatus as keyof typeof StatusNames]
        }"`,
        type: 'Warning',
      });
      return;
    }
    try {
      const response =
        await APiFinancialServiceInvoiceReports.getServiceInvoicePDF(
          serviceInvoice.id
        );
      if (typeof response === 'string') {
        notify({
          message: 'Não foi possível baixar o pdf da nota fiscal',
          type: 'Error',
        });
        return;
      }
      const blob = new Blob([response], { type: 'application/pdf' });
      // Create a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'file.pdf'; // Set the file name for the download

      // Trigger the download by simulating a click
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);
    } catch (error) {
      notify({
        message: 'Não foi possível baixar o xml da nota fiscal',
        type: 'Error',
      });
    }
  };

  const loadServiceInvoices = useCallback(() => {
    if (loadingServiceInvoices) return;
    setLoadingServiceInvoices(true);
    APiFinancialServiceInvoiceReports.getCompanyServiceInvoices()
      .then((response) => {
        setServiceInvoices(response.data.serviceInvoices);
      })
      .finally(() => setLoadingServiceInvoices(false));
  }, [loadingServiceInvoices]);

  const onClickCancelButton = async (serviceInvoice: Record<string, any>) => {
    try {
      setLoadingInvoiceAction(true);
      const response =
        await APiFinancialServiceInvoiceReports.cancelServiceInvoice(
          serviceInvoice.id
        );
      if (typeof response === 'string') {
        notify({
          message: 'Não foi possível cancelar a nota fiscal',
          type: 'Error',
        });
        return;
      }
      notify({
        message: 'Nota fiscal cancelada com sucesso',
        type: 'Success',
      });
      setInvoiceToCancel(null);
      loadServiceInvoices();
    } catch (error) {
      notify({
        message: 'Não foi possível cancelar a nota fiscal',
        type: 'Error',
      });
    } finally {
      setLoadingInvoiceAction(false);
    }
  };
  const columns = [
    {
      name: 'ID NFS',
      key: 'cpfCnpj',
      selector: (row: any) => row.id,
      cell: (row: any) => row.id,
    },
    {
      name: 'Nome do cliente',
      key: 'cpfCnpj',
      selector: (row: any) => row.borrower.borrower,
      cell: (row: any) => row.borrower.name,
    },
    {
      name: 'Valor da nota',
      key: 'cpfCnpj',
      selector: (row: any) => row.servicesAmount,
      cell: (row: any) => maskHelper.formatMoeda(row.servicesAmount),
    },
    {
      name: 'Data da emissão',
      key: 'cpfCnpj',
      selector: (row: any) => row.issuedOn,
      cell: (row: any) => new Date(row.issuedOn).toLocaleDateString(),
    },
    {
      name: 'Status',
      key: 'cpfCnpj',
      selector: (row: any) =>
        StatusNames[row.flowStatus as keyof typeof StatusNames] ??
        row.flowStatus,
      cell: (row: any) =>
        StatusNames[row.flowStatus as keyof typeof StatusNames] ??
        row.flowStatus,
    },
    {
      name: 'Ações',
      key: 'cpfCnpj',
      // selector: (row: any) => row.issuedOn,
      cell: (row: any) => (
        <div className="flex gap-2 items-center">
          <button
            className="appearance-none bg-transparent border-none p-0 m-0"
            type="button"
            onClick={() => onClickXmLButton(row)}
            disabled={loadingInvoiceAction}
          >
            <BsFiletypeXml size={16} />
          </button>
          <button
            className="appearance-none bg-transparent border-none p-0 m-0"
            type="button"
            onClick={() => onClickPDFButton(row)}
          >
            <BsFiletypePdf size={16} />
          </button>
          <button
            className="appearance-none bg-transparent border-none p-0 m-0"
            type="button"
            title="Cancelar nota"
            disabled={loadingInvoiceAction}
            onClick={() => {
              if (row.flowStatus !== 'Issued') {
                notify({
                  message:
                    'Essa nota fiscal não pode ser cancelada pois o status dela não foi definido como "Emitida"',
                  type: 'Warning',
                });
                return;
              }
              setInvoiceToCancel(row);
            }}
          >
            <MdOutlineCancel size={16} />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadServiceInvoices();
  }, []);

  return (
    <>
      <MainPagePagination
        reportAndCreate={false}
        currentPageTable={currentPage}
        setCurrentPageTable={setCurrentPage}
        startDate={setStartData}
        totalRegisters={0}
        isLoading={loadingServiceInvoices}
        title="Relatório de nota Fiscal Serviço"
        column={columns}
        filterTable={() => serviceInvoices}
        setRegisterPage={() => {}}
        FilterComponent={false}
        showRegisterAction={false}
        // objectReport={generateCsv}
        // filename="contas"
      />
      <CancelDialog
        onConfirm={() => onClickCancelButton(invoiceToCancel!)}
        setIsOpen={() => {
          if (loadingInvoiceAction) return;
          setInvoiceToCancel(null);
        }}
        open={invoiceToCancel !== null}
        loading={loadingInvoiceAction}
      />
    </>
  );
};

export default ReportsServiceInvoice;
