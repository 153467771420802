/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SearchFormProps } from '../types';
import IconDictionary from '../../../components/Icons/icons';
import maskHelper from '../../../helpers/mask.helper';
import { Switch } from '../../../components';
import { ApiSearchForm } from '../api';

interface props {
  form: SearchFormProps;
  openForm: () => void;
  updateStatus: (status: boolean) => void;
  handleDelete: () => void;
}

const CardForm: React.FC<props> = ({
  form,
  openForm,
  handleDelete,
  updateStatus,
}) => {
  const [active, setIsActive] = useState(form.is_active);

  const exportToExcel = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      const response = await ApiSearchForm.getResponsesByFormId(form.id);

      if (!Array.isArray(response) || response.length === 0) {
        return;
      }

      const allQuestionsSet = new Set<string>();

      response.forEach((resposta) => {
        resposta.response_search_form_questions.forEach((rq: any) => {
          allQuestionsSet.add(rq.title_question);
        });
      });

      const allQuestionsArray = Array.from(allQuestionsSet);

      const header = ['ID da Resposta', 'Nome', 'Email', ...allQuestionsArray];

      const excelData = response.map((resposta) => {
        const row: any = {
          'ID da Resposta': resposta.id,
          Nome: resposta.opportunity.opportunity_person[0].person.name,
          Email: resposta.opportunity.opportunity_person[0].person.email,
        };

        const questionAnswerMap: Record<string, string> = {};

        resposta.response_search_form_questions.forEach((rq: any) => {
          const answerOptions = rq.response_search_form_questions_options;

          const answerTexts = answerOptions
            .map((option: any) => option.text_response)
            .join(', ');

          questionAnswerMap[rq.title_question] = answerTexts;
        });

        allQuestionsArray.forEach((questionTitle) => {
          row[questionTitle] = questionAnswerMap[questionTitle] || '';
        });

        return row;
      });

      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, `respostas_formulario_${form.id}${fileExtension}`);
    } catch (error) {
      console.error('Erro ao exportar para Excel:', error);
    }
  };

  return (
    <button
      type="button"
      onClick={openForm}
      className="h-fit transition ease-in-out delay-75 duration-150 shadow-sm w-72 text-xs flex flex-col border-solid border-gray-400 hover:border-primary cursor-pointer rounded-md border pb-4"
    >
      <div className="py-6 px-3 w-full flex items-center justify-between">
        <strong className="max-w-sm truncate">{form.title}</strong>

        <Switch
          state={active}
          onChange={() => {
            updateStatus(!active);
            setIsActive(!active);
          }}
        />
      </div>
      <hr className="w-full bg-gray-400 mb-4 h-px" />
      <div className="max-w-full text-xs px-3 flex flex-col items-start h-16">
        {form.search_form_questions.slice(0, 3).map((items) => (
          <p key={items.id} className="max-w-full truncate">
            {items.title}
          </p>
        ))}
        {form.search_form_questions.length > 3 && '...'}
      </div>
      <hr className="w-full bg-gray-400 mb-4 mt-5 h-px" />

      <div className="flex gap-2 items-center justify-between w-full text-xs px-3">
        <IconDictionary name="Cartao lista" className="text-primary h-4" />
        <p>Criação: {maskHelper.formatDateDMY(form.created_at)}</p>
        <button
          type="button"
          onClick={exportToExcel}
          className="hover:scale-110 delay-150 w-5 h-5 flex items-center justify-center rounded-full text-green cursor-pointer"
        >
          <IconDictionary name="Excel" size={16} />
        </button>

        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
          className="hover:scale-110 delay-150 bg-primary w-5 h-5 flex items-center justify-center rounded-full text-white cursor-pointer"
        >
          <IconDictionary name="Excluir" size={16} />
        </button>
      </div>
    </button>
  );
};

export default CardForm;
