import { useState } from 'react';

import IconDictionary from '../../../components/Icons/icons';

import MainPagePagination from '../../main/components/mainPage/mainPageWithPagination';

import { useWppContext } from '../context';
import { InstanceCard } from '../components';
import { CompanieWhatsappInstancesProps } from '../types';
import { normalizeColors } from '../utils';
import { ApiWhatsInstances } from '../api';
import { notify } from '../../../components/Toast/toast';
import { dictionaryError } from '../../../helpers/utils.helper';
import { DropdownIcons, Switch } from '../../../components';

const Whatsapp = () => {
  const {
    instances,
    setInstances,
    isLoading,
    setModal,
    whatsappPlans,
    setReload,
  } = useWppContext();

  const [currentPage, setCurrentPage] = useState(1);

  const handleSwitchChange = async (id: number, payload: any) => {
    try {
      await ApiWhatsInstances.updateCompanieWhatsappInstances(payload, id);
      notify({
        message: 'Configuração atualizada com sucesso!',
        type: 'Success',
      });
      setReload(Date.now().toString());
    } catch (error) {
      notify({
        message: 'Erro ao atualizar configuração.',
        type: 'Error',
      });
    }
  };

  const onHandleAction = async (instance: any) => {
    if (instance.status !== 'Conectada') {
      setModal({ show: 'connect', data: { id: instance.id } });
      return;
    }

    const res = await ApiWhatsInstances.updateCompanieWhatsappInstancesStatus(
      {
        action: instance.status === 'Conectada' ? 'logout' : 'connect',
      },
      instance.id
    );
    if (typeof res === 'string') {
      notify({ message: dictionaryError(res), type: 'Error' });
      return;
    }

    notify({
      message: 'Status da instância atualizado com sucesso!',
      type: 'Success',
    });
    const update = instances.map((obj: any) =>
      obj.name === instance.name ? { ...obj, status: 'Desconectada' } : obj
    );
    setInstances(update);
  };

  const onHandleModal = () => {
    if (whatsappPlans.qty === instances.length) {
      return setModal({ show: 'limit' });
    }
    return setModal({ show: 'register', data: whatsappPlans });
  };

  const columns = [
    {
      key: 'namePlans',
      name: 'Proprietário',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <p>
          {
            row
              .companie_user_companie_userTocompanie_whatsapp_instances_companie_user_id_owner
              .name
          }
        </p>
      ),
    },
    {
      key: 'statusPlans',
      name: 'Funil',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <div className="flex items-center justify-center">
          <p>{row.funnel_step?.funnel.title}</p>
        </div>
      ),
    },
    {
      key: 'statusPlans',
      name: 'Etapa',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <div className="flex items-center justify-center">
          <p>{row.funnel_step.title}</p>
        </div>
      ),
    },
    {
      key: 'instanceName',
      name: 'Instância',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <p>{row.json.instance?.instance?.instanceName}</p>
      ),
    },
    {
      key: 'status',
      name: 'Status da Instância',
      maxWidth: '240px',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <p className={row.status === 'Conectada' ? 'text-green' : ''}>
          {row.status}
        </p>
      ),
    },
    {
      key: 'is_create_opportunity',
      name: 'Criar oportunidade ao receber mensagem.',
      maxWidth: '240px',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <div className="flex items-center justify-center">
          <Switch
            state={row.is_create_opportunity}
            onChange={() =>
              handleSwitchChange(Number(row.id), {
                is_create_opportunity: !row.is_create_opportunity,
                companie_user_id_owner: row.companie_user_id_owner,
                is_bulk_shipping: row.is_bulk_shipping,
                funnel_step_id: row.funnel_step_id,
              })
            }
          />
        </div>
      ),
      center: true,
    },
    {
      key: 'is_bulk_shipping',
      name: 'Permitir envio em massa',
      maxWidth: '240px',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <div className="flex items-center justify-center">
          <Switch
            state={row.is_bulk_shipping}
            onChange={() =>
              handleSwitchChange(Number(row.id), {
                is_bulk_shipping: !row.is_bulk_shipping,
                funnel_step_id: row.funnel_step_id,
                companie_user_id_owner: row.companie_user_id_owner,
                is_create_opportunity: row.is_create_opportunity,
              })
            }
          />
        </div>
      ),
      center: true,
    },
    {
      key: 'is_sending_message_automatic',
      name: 'Utilizar no envio de mensagem automática',
      maxWidth: '240px',
      selector: (row: CompanieWhatsappInstancesProps) => (
        <div className="flex items-center justify-center">
          <Switch
            state={row.is_sending_message_automatic}
            onChange={() =>
              handleSwitchChange(Number(row.id), {
                is_sending_message_automatic: !row.is_sending_message_automatic,
                companie_user_id_owner: row.companie_user_id_owner,
                is_create_opportunity: row.is_create_opportunity,
                is_bulk_shipping: row.is_bulk_shipping,
                funnel_step_id: row.funnel_step_id,
              })
            }
          />
        </div>
      ),
      center: true,
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '90px',
      selector: (row: CompanieWhatsappInstancesProps) => {
        const dropdownItems = [
          {
            label: 'Editar as configurações da instância',
            value: (
              <button
                type="button"
                onClick={() => setModal({ show: 'update', data: row })}
              >
                <IconDictionary
                  title="Editar as configurações da instância"
                  size={20}
                  name="Editar"
                  color="#252525"
                />
              </button>
            ),
            handleClick: () => {
              setModal({ show: 'update', data: row });
            },
          },
          {
            label:
              row.status === 'Conectada'
                ? 'Desconectar a instância'
                : 'Conectar a instância',
            value: (
              <button
                type="button"
                disabled={row.status === 'Inativa'}
                onClick={() => onHandleAction(row)}
              >
                <IconDictionary
                  title={
                    row.status === 'Conectada'
                      ? 'Desconectar a instância'
                      : 'Conectar a instância'
                  }
                  size={20}
                  name={row.status === 'Conectada' ? 'StopCircle' : 'QrCode'}
                  color={normalizeColors(row.status, 'update')}
                />
              </button>
            ),
            handleClick: () => {
              onHandleAction(row);
            },
            disabled: row.status === 'Inativa',
          },
          ...(row.is_sending_message_automatic === false
            ? [
                {
                  label: 'Excluir a instância',
                  value: (
                    <button
                      title="Excluir a instância"
                      type="button"
                      disabled={row.status === 'Inativa'}
                      onClick={() =>
                        setModal({
                          show: 'cancel',
                          data: { id: row.id, instanceName: row.name },
                        })
                      }
                    >
                      <IconDictionary
                        name="TrashSolid"
                        size={20}
                        color={normalizeColors(row.status, 'delete')}
                      />
                    </button>
                  ),
                  handleClick: () => {
                    setModal({
                      show: 'cancel',
                      data: { id: row.id, instanceName: row.name },
                    });
                  },
                  disabled: row.status === 'Inativa',
                },
              ]
            : []),
        ];

        return (
          <div className="ml-1.5">
            <DropdownIcons
              components={dropdownItems}
              modalSide="bottom"
              modalAlign="end"
            />
          </div>
        );
      },
    },
  ];

  return (
    <MainPagePagination
      isLoading={isLoading}
      currentPageTable={currentPage}
      setCurrentPageTable={setCurrentPage}
      startDate={() => {}}
      setModalCreateUpdate={onHandleModal}
      onRowClick={(e) => {
        if (e.status === 'Inativa' || e.status === 'Excluida') return null;
        return setModal({ show: 'update', data: { ...e } });
      }}
      totalRegisters={instances.length}
      title="Instâncias WhatsApp"
      column={columns}
      filterTable={() => instances}
      setRegisterPage={() => {}}
      PlansCustomCard={
        <InstanceCard setModalPurchaseCancel={(e) => setModal({ show: e })} />
      }
    />
  );
};

export default Whatsapp;
