/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Spinner } from '../../../../../components';
import { ApiDashboard } from '../../api';
import { salesChannelProps } from '../../types';
import maskHelper from '../../../../../helpers/mask.helper';

interface props {
  companyId: number;
  period: string;
  funnelId: any;
  seller: any;
}
const SalesChannelGraph: React.FC<props> = ({
  companyId,
  period,
  funnelId,
  seller,
}) => {
  const [graph, setGraph] = useState<salesChannelProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchGraph = async () => {
      setIsLoading(true);
      const salesChannel = await ApiDashboard.getSalesChannel(
        companyId,
        funnelId,
        period,
        seller
      );
      if (typeof salesChannel !== 'string') {
        setGraph(salesChannel);
      }
      setIsLoading(false);
    };
    fetchGraph();
  }, [period, funnelId, seller]);
  return !isLoading ? (
    <>
      <p className="font-medium text-xs uppercase">Top 5 canais de vendas</p>
      {graph.reduce((accumu, current) => accumu + Number(current._count), 0) ===
      0 ? (
        <div className="w-full h-64 lg:h-full flex items-center justify-center">
          <p className="text-xs">Nenhum dado encontrado</p>
        </div>
      ) : (
        <div className="w-full min-h-fit h-80 flex items-center">
          <Plot
            className="w-full"
            data={[
              {
                labels: graph.slice(0, 5).map((item) => item.title),
                // fake for testing values: [20, 20, 30, 10, 20, 10],
                values: graph.slice(0, 5).map((item) => item._count),
                text: Object.values(graph).map((item: any) =>
                  maskHelper.formatMoeda(item._sum)
                ),
                marker: {
                  colors: [
                    '#264653',
                    '#E76F51',
                    '#2A9D8F',
                    '#E9C46A',
                    '#541554',
                  ],
                },
                type: 'pie',
                hoverinfo: 'label',
              },
            ]}
            config={{ displayModeBar: false, responsive: true }}
            layout={{
              height: 250,
              autosize: true,
              margin: { l: 20, r: 20, b: 50, t: 28 },
            }}
          />
        </div>
      )}
    </>
  ) : (
    <div className="flex items-center justify-center h-52 min-h-full">
      <Spinner />
    </div>
  );
};

export default SalesChannelGraph;
